import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import Address from "common/user/address";
import { SensitiveValue } from "common/details/identity";
import { DescriptionListItem } from "common/core/description_list";

type Props = {
  address: ComponentProps<typeof Address>["address"];
  hideSensitiveData?: boolean;
};

function SignerAddress({ address, hideSensitiveData }: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  // HACK: the BE doesn't always send a null address object if it's blank, so we use this hack to test for presence
  if (!address?.line1) {
    return null;
  }
  const addressComponent = <Address address={address} />;
  const addressLabel = (
    <FormattedMessage id="6352b4f7-aefa-4feb-a07d-db7ce4ca4a9e" defaultMessage="Address" />
  );
  const addressContent = hideSensitiveData ? (
    <SensitiveValue value={addressComponent} obfuscatedValue={"****"} />
  ) : (
    addressComponent
  );

  return isTxnDetailsRedesign ? (
    <DescriptionListItem term={addressLabel} definition={addressContent} />
  ) : (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow title={addressLabel}>
        <div className="DetailGrid-data--subheading">{addressContent}</div>
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}

export default SignerAddress;
