import type { ReactNode } from "react";

import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { Heading } from "common/core/typography";

import Styles from "./index.module.scss";

type Props = {
  children: ReactNode;
};

export function SignerDetailsHeader({ children }: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  return isTxnDetailsRedesign ? (
    <Heading level="h3" textStyle="subtitleSmall" className={Styles.heading}>
      {children}
    </Heading>
  ) : (
    <h3 className={Styles.deprecatedHeader}>{children}</h3>
  );
}
