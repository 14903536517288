import { memo, type ComponentProps } from "react";

import {
  AnnotationDesignationType,
  AnnotationSubtype,
  CoordinateSystem,
  AnnotationGraphicTypes,
  PageTypes,
} from "graphql_globals";
import {
  getSplitTimeZoneDateParts,
  getTimeZoneDate,
} from "common/meeting/pdf/annotation/date_interaction";
import {
  annotationDefaultPdfPointSize,
  annotationDesignationTextDefaults,
  designationDefaultPdfPointSize,
  annotationPdfPointSizeFromText,
} from "common/pdf/util";
import { textParamsFromSubType } from "common/meeting/notary/document/pdf/annotation/text";
import { getCachedSizeForAnnotation } from "common/meeting/notary/document/v3/annotation";
import { usePDFContext } from "common/pdf/pspdfkit";
import { useToolbar } from "common/meeting/context/toolbar";
import { Annotation } from "common/pdf/pspdfkit/annotation";
import { AnnotationDesignation } from "common/pdf/pspdfkit/designation";
import { NotaryPointer } from "common/meeting/notary_pointer";
import { QuickstampPreview } from "common/meeting/notary/quickstamp_preview";

type TextParamsArgs = Parameters<typeof textParamsFromSubType>[0];
type Props = {
  notaryUser: TextParamsArgs["notaryUser"];
  activeSignerParticipant: TextParamsArgs["activeParticipant"] & { colorHex?: string | null };
  cache: Parameters<typeof getCachedSizeForAnnotation>[0]["cache"];
};

function getSealPreviewAnnotation(options: {
  notaryUser: Props["notaryUser"];
  cache: Props["cache"];
  location: ComponentProps<typeof Annotation>["annotation"]["location"];
  currentToolArgs: ReturnType<typeof useToolbar>["currentToolArgs"];
}) {
  const { currentToolArgs, notaryUser, cache, location } = options;

  if (currentToolArgs?.type === "nonPrePrintedQuickstamp") {
    return <QuickstampPreview location={location} />;
  }

  const { assetUrl } = notaryUser.notaryProfile!.sealInfo!;
  const size = getCachedSizeForAnnotation({
    cache,
    notaryUser,
    type: AnnotationGraphicTypes.SEAL,
  });
  const sealAnnotation = {
    id: "annotation-preview-seal",
    __typename: "ImageAnnotation",
    subtype: AnnotationSubtype.SEAL,
    asset: { __typename: "SecureUrl", url: assetUrl!.url! },
    location,
    size: { __typename: "Size", ...size },
    canEdit: true,
  } as const;
  return <Annotation annotation={sealAnnotation} isPreview />;
}

function ToolPreview({ notaryUser, activeSignerParticipant, cache }: Props) {
  const { currentTool, currentToolArgs } = useToolbar();
  const { usePreviewLocation } = usePDFContext();
  const previewLocation = usePreviewLocation(Boolean(currentTool));

  if (!previewLocation) {
    return null;
  }

  const location = {
    __typename: "AnnotationLocation",
    point: { __typename: "Point", ...previewLocation.point },
    coordinateSystem: CoordinateSystem.ABSOLUTE,
    page: previewLocation.pageIndex,
    pageType: previewLocation.pageType,
  } as const;

  switch (currentTool) {
    case "NOTARY_POINTER":
      return <NotaryPointer location={location} />;
    case AnnotationSubtype.CHECKMARK: {
      const size = annotationDefaultPdfPointSize({ type: AnnotationSubtype.CHECKMARK });
      const annotation = {
        id: "annotation-preview-checkmark",
        __typename: "CheckmarkAnnotation",
        subtype: AnnotationSubtype.CHECKMARK,
        location,
        size: { __typename: "Size", ...size },
        canEdit: true,
      } as const;
      return <Annotation annotation={annotation} isPreview />;
    }
    case AnnotationSubtype.DATE_SIGNED: {
      const text = getTimeZoneDate(notaryUser.notaryProfile!.usState.name)!;
      const size = annotationPdfPointSizeFromText(text);
      const annotation = {
        id: "annotation-preview-datesigned",
        __typename: "TextAnnotation",
        subtype: AnnotationSubtype.DATE,
        location,
        text,
        size: { __typename: "Size", ...size },
        annotationDesignationId: null,
        canEdit: true,
      } as const;
      return <Annotation annotation={annotation} isPreview />;
    }
    case "split-date": {
      const [firstSplitDate] = getSplitTimeZoneDateParts(notaryUser.notaryProfile!.usState.name);
      const size = annotationPdfPointSizeFromText(firstSplitDate!);
      const annotation = {
        id: "annotation-preview-splitdate",
        __typename: "TextAnnotation",
        subtype: AnnotationSubtype.FREE_TEXT,
        location,
        text: firstSplitDate!,
        size: { __typename: "Size", ...size },
        annotationDesignationId: null,
        canEdit: true,
      } as const;
      return <Annotation annotation={annotation} isPreview />;
    }
    case "proofing":
    case AnnotationSubtype.NOTARY_SIGNATURE: {
      const { assetUrl } = notaryUser.notaryProfile!.signatureInfo!;
      const size = getCachedSizeForAnnotation({
        cache,
        notaryUser,
        type: AnnotationGraphicTypes.SIGNATURE,
      });
      const annotation = {
        id: "annotation-preview-vectorsignature",
        __typename: "VectorGraphicAnnotation",
        subtype: AnnotationSubtype.NOTARY_SIGNATURE,
        pngAsset: { __typename: "SecureUrl", url: assetUrl!.url! },
        asset: null,
        location,
        size: { __typename: "Size", ...size },
        canEdit: true,
      } as const;
      return <Annotation annotation={annotation} isPreview />;
    }
    case AnnotationSubtype.INITIALS: {
      const { assetUrl } = notaryUser.notaryProfile!.initialsInfo!;
      const size = getCachedSizeForAnnotation({
        cache,
        notaryUser,
        type: AnnotationGraphicTypes.INITIALS,
      });
      const annotation = {
        id: "annotation-preview-vectorinitials",
        __typename: "VectorGraphicAnnotation",
        subtype: AnnotationSubtype.INITIALS,
        pngAsset: { __typename: "SecureUrl", url: assetUrl!.url! },
        asset: null,
        location,
        size: { __typename: "Size", ...size },
        canEdit: true,
      } as const;
      return <Annotation annotation={annotation} isPreview />;
    }
    case AnnotationSubtype.SEAL:
      return getSealPreviewAnnotation({ notaryUser, cache, location, currentToolArgs });
    case AnnotationSubtype.NOTARY_CITY:
    case AnnotationSubtype.COMMISSION_EXPIRY:
    case AnnotationSubtype.COMMISSION_COUNTY:
    case AnnotationSubtype.COUNTY:
    case AnnotationSubtype.DESIGNATED_AGENT:
    case AnnotationSubtype.DISCLAIMER:
    case AnnotationSubtype.DISCLOSURE:
    case AnnotationSubtype.NOTARY_ID:
    case AnnotationSubtype.NOTARY_NAME:
    case AnnotationSubtype.STATE:
    case AnnotationSubtype.N_A:
    case AnnotationSubtype.SIGNER_NAME:
    case AnnotationSubtype.PRINCIPAL_ID_TYPE:
    case AnnotationSubtype.FREE_TEXT:
    case "text": {
      const { text, subtype } = textParamsFromSubType({
        subtype: currentTool,
        notaryUser,
        activeParticipant: activeSignerParticipant,
      });

      const annotations = text?.map((text, index) => {
        const size = annotationPdfPointSizeFromText(text);

        return {
          id: `annotation-preview-text-${subtype}`,
          __typename: "TextAnnotation" as const,
          subtype,
          location: {
            ...location,
            point: { ...location.point, y: location.point.y - size.height * index },
          },
          text: text || "",
          size: { __typename: "Size" as const, ...size },
          annotationDesignationId: null,
          canEdit: true,
        };
      });

      return (
        <>
          {annotations?.map((annotation, index) => (
            <Annotation key={index} annotation={annotation} isPreview />
          ))}
        </>
      );
    }
    case "signer-designation-signature":
    case "signer-designation-initials":
    case "signer-designation-date":
    case "signer-designation-free-text": {
      if (location.pageType !== PageTypes.DOCUMENT) {
        return null;
      }
      const type =
        currentTool === "signer-designation-signature"
          ? AnnotationDesignationType.SIGNATURE
          : currentTool === "signer-designation-initials"
            ? AnnotationDesignationType.INITIALS
            : currentTool === "signer-designation-date"
              ? AnnotationDesignationType.DATE_SIGNED
              : AnnotationDesignationType.FREE_TEXT;
      const designation = {
        id: `signer-annotation-desgination-preview-${type}`,
        __typename: "AnnotationDesignation",
        fulfilled: false,
        hint: annotationDesignationTextDefaults(type),
        type,
        location,
        size: {
          ...designationDefaultPdfPointSize(type),
          __typename: "Size",
        },
        optional: false,
        active: true,
        inProgress: false,
      } as const;
      return (
        <AnnotationDesignation
          designation={designation}
          isPreview
          color={activeSignerParticipant.colorHex}
        />
      );
    }
    default:
      return null;
  }
}

const MemoizedToolPreview = memo(ToolPreview);
export { MemoizedToolPreview as ToolPreview };
