import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";

import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { DescriptionListItem } from "common/core/description_list";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";

import Styles from "./liveness_check.module.scss";

const MESSAGES = defineMessages({
  selfieImageAlt: {
    id: "24a5c752-9f1f-4edd-8df5-1973e64e307d",
    defaultMessage: "Liveness check selfie",
  },
});

type Props = {
  selfiePicture: string;
};

export function LivenessCheck({ selfiePicture }: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const intl = useIntl();

  const livenessCheckLabel = (
    <FormattedMessage id="24a5c752-9f1f-4edd-8df5-1973e64e307d" defaultMessage="Liveness Check" />
  );
  const livenessCheckContent = (
    <img
      className={classnames(Styles.selfie, !isTxnDetailsRedesign && Styles.deprecatedSelfie)}
      src={selfiePicture}
      alt={intl.formatMessage(MESSAGES.selfieImageAlt)}
    />
  );
  return isTxnDetailsRedesign ? (
    <DescriptionListItem term={livenessCheckLabel} definition={livenessCheckContent} />
  ) : (
    <DeprecatedDetailGridRow className={Styles.row} title={livenessCheckLabel}>
      {livenessCheckContent}
    </DeprecatedDetailGridRow>
  );
}
