import { memo, useEffect, useState, type ReactNode } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import type { DocumentNode } from "graphql";

import type { SigningAssets } from "common/signer/utils";
import type { SignatureMethod } from "graphql_globals";
import type {
  BeholderHeader as Meeting,
  BeholderHeader_documentBundle_documents_edges_node as Document,
  BeholderHeader_meetingParticipants as Participant,
  BeholderHeader_meetingParticipants_SignerParticipant as SignerParticipant,
  BeholderHeader_meetingParticipants_WitnessParticipant as WitnessParticipant,
} from "common/meeting/beholder/header/index_fragment.graphql";
import {
  getCurrentDocumentNode,
  getMeetingParticipantParty,
  type getPrimaryParticipant,
} from "common/meeting/util";
import { getConfig } from "common/meeting/beholder/header";
import { hexToRGBA } from "util/color";
import { Substyle } from "common/core/typography";
import { IconButton } from "common/core/button/icon_button";
import type { GraphicCache, useGraphicCache } from "common/meeting/context/graphic_cache";
import type { VectorGraphicSubtype } from "common/pdf/interaction";
import { BATCH_DESIGNATION_TYPES } from "common/document/batch_sign";
import { useRecipientColors } from "common/pdf/recipient_colors/context";
import type { SignatureOptionsOrganization } from "util/signature_options";

import MobileMeetingAnnotationsBar from "./mobile_meeting_annotations_bar";
import RemainingActionsBanner from "./remaining_actions_banner";
import Styles from "./signing_controls.module.scss";

type Props = {
  meeting: Meeting;
  meetingQuery: DocumentNode;
  currentPenHolderParticipant: SignerParticipant | WitnessParticipant | null;
  signerCanAnnotate: boolean;
  handleBatchSignClick?: () => Promise<unknown>;
  onPdfInteraction?: <V>(promise: Promise<V>) => Promise<V>;
  user: Parameters<typeof getPrimaryParticipant>[1];
  lockSignerName: boolean;
  getAsset: (
    vectorGraphicSubtype: VectorGraphicSubtype,
    participant: {
      id: string;
      userId: string | null;
      signingAssets: SigningAssets;
    } & (
      | {
          __typename: "WitnessParticipant";
          witnessProfileId: string;
        }
      | {
          __typename: "SignerParticipant";
          signerIdentityId: string;
        }
    ),
    cache: GraphicCache,
    createNew?: boolean,
  ) => Promise<{
    font: string | null;
    method: SignatureMethod | null;
    key: string;
    url: string;
    size: { height: number; width: number };
  } | null>;
  assetCache: ReturnType<typeof useGraphicCache>;
  organization?: SignatureOptionsOrganization;
  children?: (
    onPdfActionFulfill: () => void,
    signButtonText: ReactNode,
    buttonClassName: string,
  ) => ReactNode;
};

const MESSAGES = defineMessages({
  addAnnotationsLabel: {
    id: "7418cc82-bb52-4a9c-ab22-bf634370b366",
    defaultMessage: "Add annotations",
  },
});

function batchSigningAvailable(meeting: Meeting, currentPenHolderParticipant: Participant | null) {
  const { isEnote, annotationDesignations } = getCurrentDocumentNode<Document>(meeting);

  if (
    isEnote ||
    meeting.unfulfilledRequirements.missingParticipantRoles.length ||
    currentPenHolderParticipant?.__typename !== "SignerParticipant"
  ) {
    return false;
  }

  if (!meeting.documentBundle?.batchSigning) {
    return false;
  }

  const currentSignerIndex = currentPenHolderParticipant.signerRole.index;
  const conditionalDesignationIds = annotationDesignations.edges.flatMap(
    ({ node }) => node.dependentDesignationIds,
  );
  const unfulfilledDesignationsForCurrentPenholder = annotationDesignations.edges.filter(
    ({ node }) => {
      return (
        !node.fulfilled &&
        node.signerRole.index === currentSignerIndex &&
        node.required &&
        node.active
      );
    },
  );

  if (
    !unfulfilledDesignationsForCurrentPenholder.length ||
    unfulfilledDesignationsForCurrentPenholder.some(({ node }) =>
      conditionalDesignationIds.includes(node.id),
    ) ||
    !unfulfilledDesignationsForCurrentPenholder.every((edge) =>
      BATCH_DESIGNATION_TYPES.has(edge.node.type),
    )
  ) {
    return false;
  }

  return true;
}

function MobileMeetingSigningControls({
  meeting,
  meetingQuery,
  user,
  lockSignerName,
  currentPenHolderParticipant,
  signerCanAnnotate,
  getAsset,
  assetCache,
  organization,
  children,
}: Props) {
  const intl = useIntl();
  const recipientColors = useRecipientColors();
  const witnessColor = recipientColors.witness.text;
  const { countAllActions, countCompletedActions, activeParticipant } = getConfig(meeting);
  const userParty = getMeetingParticipantParty(meeting, user);
  const [annotationsToolbarOpen, setAnnotationsToolbarOpen] = useState(false);
  const showAnnotationsButton =
    signerCanAnnotate && currentPenHolderParticipant && !annotationsToolbarOpen;

  const { meetingParticipants } = meeting;
  const signerParticipants = meetingParticipants.filter(
    (participant) => participant.__typename === "SignerParticipant",
  );

  const remainingActions = Math.max(countAllActions - countCompletedActions, 0);

  let activeSignerLabel;
  let activeSignerColor;
  let signButtonText;
  if (currentPenHolderParticipant && userParty.length === 1) {
    activeSignerColor =
      currentPenHolderParticipant.__typename === "WitnessParticipant"
        ? witnessColor
        : currentPenHolderParticipant.colorHex;
    activeSignerLabel = (
      <FormattedMessage
        id="22014fb1-bbc0-4ca5-9556-a58cf6c58d48"
        description="signingAs"
        defaultMessage="You are the active signer"
      />
    );
    signButtonText = (
      <FormattedMessage id="0690992b-a142-4b7e-9e2f-ae36abe2f1c6" defaultMessage={"Sign"} />
    );
  } else {
    const sharedFirstName = signerParticipants.find(
      (p) => p.id !== activeParticipant.id && p.firstName === activeParticipant.firstName,
    );

    activeSignerColor =
      activeParticipant.__typename === "WitnessParticipant"
        ? witnessColor
        : activeParticipant.colorHex;
    activeSignerLabel = (
      <FormattedMessage
        id="22014fb1-bbc0-4ca5-9556-a58cf6c58d48"
        description="signingAs"
        defaultMessage="{name} is the active signer"
        values={{
          name: sharedFirstName ? activeParticipant.fullName : activeParticipant.firstName,
        }}
      />
    );
    signButtonText = (
      <FormattedMessage
        id="48103836-8bc5-4996-88b1-f1a51ba99236"
        defaultMessage={"Sign as {name}"}
        values={{
          name: sharedFirstName ? activeParticipant.fullName : activeParticipant.firstName,
        }}
      />
    );
  }

  const [previousRemainingActions, setPreviousRemainingActions] = useState(0);
  const [batchCompletedActions, setBatchCompletedActions] = useState(0);

  useEffect(() => {
    if (previousRemainingActions === 0) {
      return;
    }

    setBatchCompletedActions(previousRemainingActions - remainingActions);
    const timeoutId = setTimeout(() => {
      setBatchCompletedActions(0);
      setPreviousRemainingActions(0);
    }, 3_000);
    return () => {
      clearTimeout(timeoutId);
      setBatchCompletedActions(0);
      setPreviousRemainingActions(0);
    };
    // depend on remainingActions so that if this changes while the completed banner is shown
    // it would clear out the banner previous/completed actions state and show the normal banner
  }, [previousRemainingActions, remainingActions]);

  const showBatchSignButton =
    children &&
    !annotationsToolbarOpen &&
    batchSigningAvailable(meeting, currentPenHolderParticipant);

  const batchSignButton = children?.(
    () => {
      setPreviousRemainingActions(remainingActions);
    },
    signButtonText,
    Styles.batchSignButton,
  );

  const showSigningAsLabel =
    signerParticipants.length > 1 && !showBatchSignButton && !annotationsToolbarOpen;
  const currentDocument = getCurrentDocumentNode(meeting);

  return (
    <div className={Styles.container}>
      {showSigningAsLabel && (
        <div className={classNames(Styles.floatingContainer, Styles.center)}>
          <Substyle
            data-automation-id="signing-as"
            size="defaultSize"
            textStyle="subtitleSmall"
            className={Styles.activeSignerBanner}
          >
            <span
              style={{ background: hexToRGBA(activeSignerColor, 0.25), color: activeSignerColor }}
            >
              {activeSignerLabel}
            </span>
          </Substyle>
        </div>
      )}
      {showAnnotationsButton && (
        <div className={classNames(Styles.floatingContainer, Styles.left)}>
          <IconButton
            className={Styles.annotationsBtn}
            name="add-annotation"
            aria-describedby="Add Annotations"
            buttonColor="dark"
            variant="tertiary"
            onClick={() => setAnnotationsToolbarOpen(true)}
            label={intl.formatMessage(MESSAGES.addAnnotationsLabel)}
          />
        </div>
      )}
      {annotationsToolbarOpen && currentPenHolderParticipant && (
        <MobileMeetingAnnotationsBar
          document={currentDocument}
          meetingQuery={meetingQuery}
          meetingId={meeting.id}
          currentPenHolderParticipant={currentPenHolderParticipant}
          onDone={() => setAnnotationsToolbarOpen(false)}
          getAsset={getAsset}
          assetCache={assetCache}
          lockSignerName={lockSignerName}
          organization={organization}
        />
      )}
      {showBatchSignButton && (
        <div className={classNames(Styles.floatingContainer, Styles.center)}>{batchSignButton}</div>
      )}
      <RemainingActionsBanner meeting={meeting} batchCompletedActions={batchCompletedActions} />
    </div>
  );
}

export default memo(MobileMeetingSigningControls);
