import type { ComponentProps } from "react";

import SignerValidation from "common/signer/items/signer_validation";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { DetailsDescriptionList } from "common/transactions/details/common";

import { SignerDetailsHeader } from "./signer_details_header";

type SignerValidationProps = ComponentProps<typeof SignerValidation>;
type Props = {
  signerIdentity: SignerValidationProps["signerIdentity"];
  organizationFeatures: SignerValidationProps["organizationFeatures"];
  headerText: ComponentProps<typeof SignerDetailsHeader>["children"];
};

function EsignAuthSignerDetails({ signerIdentity, organizationFeatures, headerText }: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  return (
    <div>
      <SignerDetailsHeader>{headerText}</SignerDetailsHeader>
      <DetailsDescriptionList isTxnDetailsRedesign={isTxnDetailsRedesign}>
        <SignerValidation
          signerIdentity={signerIdentity}
          organizationFeatures={organizationFeatures}
        />
      </DetailsDescriptionList>
    </div>
  );
}

export default EsignAuthSignerDetails;
