import { FormattedMessage } from "react-intl";
import { type ReactNode } from "react";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import AlertMessage from "common/core/alert_message";
import { DocumentRequirementEnum } from "graphql_globals";

import { type DocumentsOrganizationTransaction as Transaction } from "./organization_transaction_fragment.graphql";
import { scrollToSection, type Validation } from "../util";
import { DOCUMENT_SECTION_ID } from "../../sections/documents";
import { isPlaceOrder } from "../../form";

export const validateDocuments: Validation<Transaction> = ({
  transaction,
  config,
  setSubmitErrorModal,
}) => {
  return new Promise((resolve) => {
    const { document_bundle: documentBundle, requiresNsaMeeting, isCollaborative } = transaction;
    const documents = documentBundle!.documents.edges
      .map((edge) => edge.node)
      .filter((doc) => !doc.isConsentForm); // we don't include consent forms in the validation since they are automatically added

    const hasNotarizationRequiredDocuments = documents.some((doc) => doc.notarizationRequired);
    const hasEsignRequiredDocuments = documents.some((doc) => doc.esign);
    const hasAttestationRequiredDocuments = documents.some((doc) => doc.proofingRequired);

    // hybrid documents currently don't always use requirement: "esign, they sometimes use requirement: nil due to UI being outdated.
    // if created through API, hybrid docs might have "esign" requirement
    const hasHybridRequiredDocuments = documents.some(
      (doc) => !doc.signingRequiresMeeting || doc.esign,
    );
    const hasCurrentOrgSubmittedDoc = documents.some((doc) => doc.canUpdate);

    // business esign transactions currently return true for requiresNsaMeeting
    const isHybrid = !requiresNsaMeeting;
    const placeOrder = isPlaceOrder({
      placeOrder: transaction.placeOrder,
      transaction,
      config,
    });

    let errorMessage;
    let title = (
      <FormattedMessage
        id="5ccdd71d-0c45-4730-a7cf-1dd11399366a"
        defaultMessage="Adjust document requirements"
      />
    );
    if (documents.length === 0) {
      title = (
        <FormattedMessage
          id="127ff36d-3b8d-4799-b25d-99749665a58a"
          defaultMessage="Upload a document"
        />
      );
      errorMessage = (
        <FormattedMessage
          id="794980b6-01cb-4237-9a7b-51277001737f"
          defaultMessage="At least one document must be uploaded."
        />
      );
    } else if (
      !placeOrder &&
      config.defaultDocRequirement === DocumentRequirementEnum.ESIGN &&
      ((!isHybrid && !hasEsignRequiredDocuments) || !hasHybridRequiredDocuments)
    ) {
      errorMessage = (
        <FormattedMessage
          id="47ac5552-d12e-4415-9e3c-7cafb07b4c2f"
          defaultMessage="You must attach at least one document requiring esign to continue."
        />
      );
    } else if (
      !placeOrder &&
      config.defaultDocRequirement === DocumentRequirementEnum.NOTARIZATION
    ) {
      if (config.canRequireAttestation.value) {
        if (!(hasAttestationRequiredDocuments || hasNotarizationRequiredDocuments)) {
          errorMessage = (
            <FormattedMessage
              id="d64764cd-f803-4cb9-8573-31c814d0b8e5"
              defaultMessage="You must attach at least one document requiring notarization or notarization without SSN to continue."
            />
          );
        }
      } else if (!hasNotarizationRequiredDocuments) {
        errorMessage = (
          <FormattedMessage
            id="bf722719-92c8-4389-b093-499ab54f7b37"
            defaultMessage="You must attach at least one document requiring notarization to continue."
          />
        );
      }
    }

    if (errorMessage) {
      setSubmitErrorModal(
        <DocumentsErrorModal
          onCancel={() => {
            setSubmitErrorModal(null);
            resolve({ status: "failed" });
          }}
          onAcknowledgeError={() => {
            scrollToSection(DOCUMENT_SECTION_ID);
            setSubmitErrorModal(null);
            resolve({ status: "failed" });
          }}
          title={title}
          errorMessage={errorMessage}
        />,
      );
    } else if (isCollaborative && placeOrder && !hasCurrentOrgSubmittedDoc) {
      setSubmitErrorModal(
        <DocumentsErrorModal
          onCancel={() => {
            setSubmitErrorModal(null);
            resolve({ status: "failed" });
          }}
          onOverrideError={() => {
            setSubmitErrorModal(null);
            resolve({ status: "passed" });
          }}
          onAcknowledgeError={() => {
            scrollToSection(DOCUMENT_SECTION_ID);
            setSubmitErrorModal(null);
            resolve({ status: "failed" });
          }}
          title={
            <FormattedMessage
              id="18a0379d-1043-442c-aa12-0e891c18df8d"
              defaultMessage="You haven't added any documents yet"
            />
          }
          errorMessage={
            <FormattedMessage
              id="0496ede1-0ad5-4a8d-b716-b0ce8298ade7"
              defaultMessage="Add your title documents to the closing package before placing the order with Proof."
            />
          }
          kind="warning"
        />,
      );
    } else {
      resolve({ status: "passed" });
    }
  });
};

function DocumentsErrorModal({
  onCancel,
  onAcknowledgeError,
  errorMessage,
  title,
  onOverrideError,
  kind = "danger",
}: {
  onCancel: () => void;
  onAcknowledgeError: () => void;
  errorMessage: ReactNode;
  title: ReactNode;
  onOverrideError?: () => void;
  kind?: "danger" | "warning";
}) {
  const buttons = [
    <Button key="documents" variant="primary" buttonColor="action" onClick={onAcknowledgeError}>
      <FormattedMessage
        id="241fd859-5f27-4a91-83ed-d238b1494274"
        defaultMessage="Go to documents"
      />
    </Button>,
  ];
  if (onOverrideError) {
    buttons.unshift(
      <Button key="override" variant="secondary" buttonColor="action" onClick={onOverrideError}>
        <FormattedMessage
          id="41040c74-29a6-42ff-bf1c-6ec5c71fa391"
          defaultMessage="Place order anyway"
        />
      </Button>,
    );
  }
  return (
    <WorkflowModal
      title={title}
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      buttons={buttons}
      footerSeparator={false}
    >
      <AlertMessage kind={kind}>{errorMessage}</AlertMessage>
    </WorkflowModal>
  );
}
