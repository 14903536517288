import { memo, useState, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import { captureException } from "util/exception";
import { useMutation } from "util/graphql";
import CollapsibleList from "common/core/collapsible_list";
import Button from "common/core/button";
import AddPointOfContactModal from "common/mortgage/transactions/form/v2/points_of_contact/add_point_of_contact_modal";
import modalScrollContent from "common/core/modal_scroll_content";
import { userCanEditContact } from "util/points_of_contact";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import { useActiveOrganization } from "common/account/active_organization";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";

import ContactDetails from "./contact_details";
import type { OrganizationTransactionForTransactionDetailsPointOfContact } from "./index_fragment.graphql";
import CreateOrganizationTransactionContactMutation from "./create_organization_transaction_contact_mutation.graphql";
import DeleteOrganizationTransactionContactMutation from "./delete_organization_transaction_contact_mutation.graphql";
import SendNotificationToOrganizationTransactionContactMutation from "./send_notification_to_organization_transaction_contact_mutation.graphql";
import Styles from "./index.module.scss";

type PointsOfContactProps = {
  organization: Parameters<typeof userCanEditContact>[0]["organization"];
  transaction: OrganizationTransactionForTransactionDetailsPointOfContact;
  canEdit?: boolean;
  refetch: () => Promise<unknown>;
};

function PointsOfContactWrapper({
  children,
  isTxnDetailsRedesign,
}: {
  children: ReactNode;
  isTxnDetailsRedesign: boolean;
}) {
  return isTxnDetailsRedesign ? <>{children}</> : <CollapsibleList>{children}</CollapsibleList>;
}

function PointsOfContactDetails({
  transaction,
  refetch,
  organization,
  canEdit = true,
}: PointsOfContactProps) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const [addModalState, setAddModalState] = useState<"closed" | "adding" | "open">("closed");
  const [activeOrganizationId] = useActiveOrganization();
  const createContactMutateFn = useMutation(CreateOrganizationTransactionContactMutation);
  const deleteContactMutateFn = useMutation(DeleteOrganizationTransactionContactMutation);
  const sendNotificationContactMutateFn = useMutation(
    SendNotificationToOrganizationTransactionContactMutation,
  );

  return (
    <>
      <div
        className={classnames(
          isTxnDetailsRedesign ? Styles.contactDetails : Styles.deprecatedContactDetails,
        )}
        data-automation-id="points-of-contact-details"
      >
        <PointsOfContactWrapper isTxnDetailsRedesign={isTxnDetailsRedesign}>
          {transaction.contacts.map((contact, index) => (
            <ContactDetails
              key={contact.id}
              contact={contact}
              transaction={transaction}
              automationId={`points-of-contact-${index}`}
              onRemove={
                canEdit && userCanEditContact({ organization, contact })
                  ? async () => {
                      await deleteContactMutateFn({
                        variables: { input: { id: contact.id } },
                      });
                      return refetch();
                    }
                  : undefined
              }
              onSendNotification={() =>
                sendNotificationContactMutateFn({ variables: { input: { id: contact.id } } })
              }
            />
          ))}
        </PointsOfContactWrapper>
        {canEdit && (
          <Button
            variant="secondary"
            buttonColor="action"
            withIcon={{ name: "add-1", placement: "left" }}
            onClick={() => setAddModalState("open")}
          >
            <FormattedMessage
              id="46c8c127-e384-4b79-a87a-732ef0270989"
              defaultMessage="Add new contact"
            />
          </Button>
        )}
      </div>
      {addModalState !== "closed" && (
        <AddPointOfContactModal
          onAddContact={(contact) => {
            setAddModalState("adding");
            createContactMutateFn({
              variables: {
                input: {
                  organizationTransactionId: transaction.id,
                  organizationId: activeOrganizationId,
                  contact: {
                    firstName: contact.firstName,
                    lastName: contact.lastName,
                    role: contact.role,
                    title: contact.title,
                    email: contact.email,
                    phoneNumber: contact.phoneNumber,
                    shownToSigner: contact.shownToSigner,
                    accessToTransaction: contact.accessToTransaction,
                  },
                },
              },
            })
              .then(() => refetch())
              .catch(() => {
                captureException(new Error("Couldn't add organization transaction contact"), {
                  contact,
                  transaction,
                });
              })
              .finally(() => {
                setAddModalState("closed");
              });
          }}
          onClose={() => setAddModalState("closed")}
          loading={addModalState === "adding"}
          allowAccessToDocumentsOption={isHybridTransactionType(transaction.transactionType!)}
        />
      )}
    </>
  );
}

export default modalScrollContent(memo(PointsOfContactDetails));
