import { useCallback } from "react";
import { v4 } from "uuid";
import { FormattedMessage } from "react-intl";

import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { constrainSize } from "util/number";
import {
  addNewAnnotationToDocumentCache,
  addRadioAnnotationToDocumentCache,
} from "common/meeting/pdf/annotation/util";
import { addNewDesignationToDocumentCache } from "common/meeting/pdf/designation/util";
import { textParamsFromSubType } from "common/meeting/notary/document/pdf/annotation/text";
import { createOptimisticId } from "common/meeting/pdf/annotation";
import {
  getSplitTimeZoneDateParts,
  getTimeZoneDate,
} from "common/meeting/pdf/annotation/date_interaction";
import {
  CoordinateSystem,
  AnnotationGraphicTypes,
  AnnotationSubtype,
  AnnotationDesignationType,
  NotarialActs,
  PageTypes,
  type DocumentBundleMembershipRole,
} from "graphql_globals";
import NotaryMeetingQuery, {
  type NotaryMeeting_viewer_user as NotaryUser,
} from "common/meeting/notary/meeting_query.graphql";
import AddTextAnnotationMutation from "common/meeting/pdf/annotation/add_text_annotation_mutation.graphql";
import AddDateMutation from "common/meeting/pdf/annotation/add_date_annotation_mutation.graphql";
import AddCheckmarkAnnotationMutation from "common/meeting/pdf/annotation/add_checkmark_annotation_mutation.graphql";
import type { GraphicCache } from "common/meeting/context/graphic_cache";
import { annotationDefaultPdfPointSize, annotationPdfPointSizeFromText } from "common/pdf/util";
import { TEXT_ANNOTATION_PLACEHOLDER, TEXT_ANNOTATION_EM } from "constants/globals";

import { IDENT_PROOF_ANNOTATION_DESC } from "./composite";
import AddVectorGraphicAnnotationMutation from "./add_vector_graphic_annotation_mutation.graphql";
import CreateAnnotationDesignationMutation from "./create_annotation_designation_mutation.graphql";
import AddSealImageAnnotationMutation from "./add_seal_image_annotation_mutation.graphql";

export function useMutationCallbacks() {
  return {
    addCheckmarkMutateFn: useMutation(AddCheckmarkAnnotationMutation),
    addDateMutateFn: useMutation(AddDateMutation),
    addTextAnnotationMutateFn: useMutation(AddTextAnnotationMutation),
    addVectorGraphicAnnotationMutateFn: useMutation(AddVectorGraphicAnnotationMutation),
    createDesignationMutateFn: useMutation(CreateAnnotationDesignationMutation),
    addSealImageAnnotationMutateFn: useMutation(AddSealImageAnnotationMutation),
  };
}

export type Participant = {
  id: string;
  userId: string | null;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  photoId?: null | {
    documentClassification: string | null;
    id: string;
  };
  signerRole?: {
    index: string;
    role: DocumentBundleMembershipRole;
  };
};
export type InputArgs = {
  meetingId: string;
  documentId: string;
  location: {
    pageType: PageTypes;
    pageIndex: number;
    point: { x: number; y: number };
  };
  annotationDesignationId?: string;
};
type LocationDescription = {
  id: string;
  location: {
    point: {
      x: number;
      y: number;
    };
  };
  size: null | {
    height: number;
    width: number;
  };
};
type AnnotationDesignationLocationDescription = LocationDescription & {
  subtype?: undefined;
};
export type AnnotationLocationDescription = LocationDescription & {
  subtype: null | AnnotationSubtype;
};

export function getCachedSizeForAnnotation({
  cache,
  type,
  notaryUser,
}: {
  cache: GraphicCache;
  type: AnnotationGraphicTypes;
  notaryUser: NotaryUser;
}) {
  const cachedSize = cache.get({ userId: notaryUser.id, graphicType: type });
  if (cachedSize) {
    return cachedSize;
  }
  const isSeal = type === AnnotationGraphicTypes.SEAL;
  const infoKey = isSeal
    ? ("sealInfo" as const)
    : type === AnnotationGraphicTypes.SIGNATURE
      ? ("signatureInfo" as const)
      : ("initialsInfo" as const);
  const { width, height } = notaryUser.notaryProfile![infoKey]!;
  const max = isSeal ? { height: 200, width: 200 } : { height: 28, width: 500 };
  return width && height
    ? constrainSize({ width, height, maxHeight: max.height, maxWidth: max.width })
    : max;
}

export async function addAnnotation(
  currentTool: AnnotationSubtype | string,
  args: InputArgs,
  notaryUser: NotaryUser,
  activeSignerParticipant: Participant,
  mutateCallbacks: ReturnType<typeof useMutationCallbacks>,
  cache: GraphicCache,
  setFocused: undefined | ((notarizeId: string) => void),
  shiftKey: boolean,
  freeTextOverride?: string,
): Promise<AnnotationDesignationLocationDescription | AnnotationLocationDescription> {
  const {
    meetingId,
    documentId,
    location: { point, pageIndex, pageType },
  } = args;
  const annotationDesignationId = args.annotationDesignationId || null;

  const mixin = {
    meetingId,
    documentId,
    authorId: notaryUser.id,
    location: {
      pageType,
      page: pageIndex,
      point,
      coordinateSystem: CoordinateSystem.ABSOLUTE,
    },
  };

  const annotationDesignation = annotationDesignationId
    ? {
        fulfilled: true,
        required: false,
        id: annotationDesignationId,
        __typename: "AnnotationDesignation",
        inProgress: false,
        ...(currentTool === AnnotationSubtype.FREE_TEXT && { fulfilled: false, inProgress: true }),
      }
    : null;

  const optimisticAnnotation = {
    id: createOptimisticId(),
    authorId: notaryUser.id,
    annotationDesignationId,
    canEdit: true,
    editable: true,
    location: {
      coordinateSystem: CoordinateSystem.ABSOLUTE,
      page: pageIndex,
      pageType,
      point: { ...point, __typename: "Point" as const },
      __typename: "AnnotationLocation" as const,
    },
    meetingId,
  };

  const optimisticResponse = {
    annotationDesignation,
    errors: null,
    meeting: null, // Null because we do not want to update the lock button until mutation completes
    document: null,
  };

  switch (currentTool) {
    case AnnotationSubtype.CHECKMARK: {
      const size = annotationDefaultPdfPointSize({ type: AnnotationSubtype.CHECKMARK });
      return mutateCallbacks
        .addCheckmarkMutateFn({
          variables: {
            input: { ...mixin, size, annotationDesignationId },
          },
          optimisticResponse: {
            addCheckmarkAnnotation: {
              __typename: "AddCheckmarkAnnotationPayload",
              annotation: {
                ...optimisticAnnotation,
                size: { ...size, __typename: "Size" },
                subtype: AnnotationSubtype.CHECKMARK,
                __typename: "CheckmarkAnnotation",
              },
              ...optimisticResponse,
              updatedDesignations: [],
              designationGroup: null,
            },
          },
          update(cacheProxy, { data }) {
            const { addCheckmarkAnnotation } = data!;
            addNewAnnotationToDocumentCache(cacheProxy, {
              meetingId,
              documentId,
              newAnnotation: addCheckmarkAnnotation!.annotation!,
              annotationDesignationId,
              errors: addCheckmarkAnnotation!.errors,
            });
          },
        })
        .then(({ data }) => data!.addCheckmarkAnnotation!.annotation!)
        .catch((error) => {
          if (
            isGraphQLError(error) &&
            typeof error.message === "string" &&
            error.message.includes("designation_group_max_fulfilled")
          ) {
            return Promise.reject({
              interactionErrorMessage: (
                <FormattedMessage
                  id="c2870644-7bca-477b-9b16-1ab3492e761d"
                  defaultMessage="Oops! You’ve attempted to select too many checkboxes. Uncheck one before making a new selection."
                />
              ),
            });
          }
          return Promise.reject(error);
        });
    }

    case AnnotationSubtype.SEAL: {
      const annotationDesignationId = args.annotationDesignationId || null;
      const { documentId, meetingId, location } = args;
      const { sealInfo } = notaryUser.notaryProfile!;
      const size = getCachedSizeForAnnotation({
        cache,
        notaryUser,
        type: AnnotationGraphicTypes.SEAL,
      });
      const { data } = await mutateCallbacks.addSealImageAnnotationMutateFn({
        variables: {
          input: {
            meetingId,
            authorId: notaryUser.id,
            annotationDesignationId,
            documentId,
            key: sealInfo!.assetUrl!.key,
            location: {
              pageType: location.pageType,
              page: location.pageIndex,
              point: location.point,
              coordinateSystem: CoordinateSystem.ABSOLUTE,
            },
            size,
            notarialAct: NotarialActs.VERIFICATION_OF_FACT_PS1583,
            principals: [activeSignerParticipant.id],
          },
        },
        optimisticResponse: {
          addSealImageAnnotation: {
            annotation: {
              annotationDesignationId,
              asset: { __typename: "SecureUrl", url: sealInfo!.assetUrl!.url },
              authorId: notaryUser.id,
              id: createOptimisticId(),
              subtype: AnnotationSubtype.SEAL,
              notarialActEnum: NotarialActs.VERIFICATION_OF_FACT_PS1583,
              notarialActPrincipals: [
                {
                  __typename: "SignerParticipant",
                  fullName: "",
                  id: activeSignerParticipant.id,
                },
              ],
              location: {
                coordinateSystem: CoordinateSystem.ABSOLUTE,
                page: location.pageIndex,
                pageType: PageTypes.DOCUMENT,
                point: {
                  x: location.point.x,
                  y: location.point.y,
                  __typename: "Point",
                },
                __typename: "AnnotationLocation",
              },
              size: {
                ...size,
                __typename: "Size",
              },
              meetingId,
              __typename: "ImageAnnotation",
              canEdit: true,
            },
            annotationDesignation: annotationDesignationId
              ? {
                  fulfilled: true,
                  id: annotationDesignationId,
                  __typename: "AnnotationDesignation",
                }
              : null,
            meeting: null, // Null because we do not want to update the lock button until mutation completes
            errors: null,
            __typename: "AddSealImageAnnotationPayload",
          },
        },
        update(cacheProxy, { data }) {
          const { addSealImageAnnotation } = data!;
          addNewAnnotationToDocumentCache(cacheProxy, {
            meetingId,
            documentId,
            newAnnotation: addSealImageAnnotation!.annotation!,
            annotationDesignationId,
            errors: addSealImageAnnotation!.errors,
          });
        },
      });
      return data!.addSealImageAnnotation!.annotation!;
    }
    case AnnotationSubtype.RADIO_CHECKMARK: {
      const size = annotationDefaultPdfPointSize({ type: AnnotationSubtype.CHECKMARK });
      const { data } = await mutateCallbacks.addCheckmarkMutateFn({
        variables: {
          input: { ...mixin, size, annotationDesignationId },
        },
        optimisticResponse: {
          addCheckmarkAnnotation: {
            __typename: "AddCheckmarkAnnotationPayload",
            annotation: {
              ...optimisticAnnotation,
              size: { ...size, __typename: "Size" },
              subtype: AnnotationSubtype.RADIO_CHECKMARK,
              __typename: "CheckmarkAnnotation",
            },
            ...optimisticResponse,
            updatedDesignations: [],
            designationGroup: null,
          },
        },
        update(cacheProxy, { data }) {
          const { addCheckmarkAnnotation } = data!;
          addRadioAnnotationToDocumentCache(NotaryMeetingQuery, cacheProxy, {
            meetingId,
            documentId,
            newAnnotation: addCheckmarkAnnotation!.annotation!,
            annotationDesignationId,
            errors: addCheckmarkAnnotation!.errors,
          });
        },
      });
      return data!.addCheckmarkAnnotation!.annotation!;
    }
    case "split-date": {
      const splitParts = getSplitTimeZoneDateParts(notaryUser.notaryProfile!.usState.name, true);
      const responses = splitParts.map((text: string | null, i: number) => {
        if (!text) {
          throw new Error("Invalid timezone date part");
        }
        const adjustedPoint = { ...point, x: point.x + i * 50 };
        const size = annotationPdfPointSizeFromText(text);
        const input = {
          ...mixin,
          location: { ...mixin.location, point: adjustedPoint },
          text,
          newSubtype: AnnotationSubtype.FREE_TEXT,
          size,
          annotationDesignationId,
        };
        return mutateCallbacks.addTextAnnotationMutateFn({
          variables: { input },
          optimisticResponse: {
            addTextAnnotation: {
              __typename: "AddTextAnnotationPayload",
              annotation: {
                ...optimisticAnnotation,
                id: createOptimisticId(),
                location: {
                  ...optimisticAnnotation.location,
                  point: { ...adjustedPoint, __typename: "Point" },
                },
                size: { ...size, __typename: "Size" },
                static: false,
                text,
                subtype: AnnotationSubtype.FREE_TEXT,
                __typename: "TextAnnotation",
              },
              ...optimisticResponse,
            },
          },
          update(cacheProxy, { data }) {
            const { addTextAnnotation } = data!;
            addNewAnnotationToDocumentCache(cacheProxy, {
              meetingId,
              documentId,
              newAnnotation: addTextAnnotation!.annotation!,
              annotationDesignationId,
              errors: addTextAnnotation!.errors,
            });
          },
        });
      });
      return Promise.all(responses).then(([{ data }]) => data!.addTextAnnotation!.annotation!);
    }
    case AnnotationSubtype.DATE_SIGNED: {
      const text = getTimeZoneDate(notaryUser.notaryProfile!.usState.name)!;
      const size = annotationPdfPointSizeFromText(text);
      const { data } = await mutateCallbacks.addDateMutateFn({
        variables: {
          input: { ...mixin, size, annotationDesignationId },
        },
        optimisticResponse: {
          addDateAnnotation: {
            __typename: "AddDateAnnotationPayload",
            annotation: {
              ...optimisticAnnotation,
              size: { ...size, __typename: "Size" },
              static: false,
              text,
              subtype: AnnotationSubtype.DATE,
              __typename: "TextAnnotation",
            },
            ...optimisticResponse,
          },
        },
        update(cacheProxy, { data }) {
          const { addDateAnnotation } = data!;
          addNewAnnotationToDocumentCache(cacheProxy, {
            meetingId,
            documentId,
            newAnnotation: addDateAnnotation!.annotation!,
            annotationDesignationId,
            errors: addDateAnnotation!.errors,
          });
        },
      });
      return data!.addDateAnnotation!.annotation!;
    }
    case "proofing":
    case AnnotationSubtype.NOTARY_SIGNATURE: {
      const { assetUrl } = notaryUser.notaryProfile!.signatureInfo!;
      const size = getCachedSizeForAnnotation({
        cache,
        notaryUser,
        type: AnnotationGraphicTypes.SIGNATURE,
      });
      const { data } = await mutateCallbacks.addVectorGraphicAnnotationMutateFn({
        variables: {
          input: {
            ...mixin,
            size,
            key: assetUrl!.key!,
            subtype: AnnotationSubtype.NOTARY_SIGNATURE,
            annotationDesignationId,
          },
        },
        optimisticResponse: {
          addVectorGraphicAnnotation: {
            annotation: {
              ...optimisticAnnotation,
              asset: null,
              pngAsset: { __typename: "SecureUrl", url: assetUrl!.url! },
              kind: AnnotationGraphicTypes.SIGNATURE,
              subtype: AnnotationSubtype.NOTARY_SIGNATURE,
              size: {
                ...size,
                __typename: "Size",
              },
              __typename: "VectorGraphicAnnotation",
            },
            ...optimisticResponse,
            __typename: "AddVectorGraphicAnnotationPayload",
          },
        },
        update(cacheProxy, { data }) {
          const { addVectorGraphicAnnotation } = data!;
          addNewAnnotationToDocumentCache(cacheProxy, {
            meetingId,
            documentId,
            newAnnotation: addVectorGraphicAnnotation!.annotation!,
            annotationDesignationId,
            errors: addVectorGraphicAnnotation!.errors,
          });
        },
      });
      let prevAnnotation: AnnotationLocationDescription =
        data!.addVectorGraphicAnnotation!.annotation!;
      if (currentTool === "proofing") {
        for (const { direction, subtype } of IDENT_PROOF_ANNOTATION_DESC) {
          const {
            location: { point },
            size,
          } = prevAnnotation;
          const x = direction === "right" ? point.x + size!.width + 15 : point.x;
          const y = direction === "below" ? point.y - size!.height - 10 : point.y;
          const input = { ...args };
          input.location.point = { x, y };
          // eslint-disable-next-line no-await-in-loop
          prevAnnotation = (await addAnnotation(
            subtype,
            input,
            notaryUser,
            activeSignerParticipant,
            mutateCallbacks,
            cache,
            setFocused,
            false,
          )) as AnnotationLocationDescription;
        }
      }
      return prevAnnotation;
    }
    case AnnotationSubtype.INITIALS: {
      const { assetUrl } = notaryUser.notaryProfile!.initialsInfo!;
      const size = getCachedSizeForAnnotation({
        cache,
        notaryUser,
        type: AnnotationGraphicTypes.INITIALS,
      });
      const { data } = await mutateCallbacks.addVectorGraphicAnnotationMutateFn({
        variables: {
          input: {
            ...mixin,
            key: assetUrl!.key!,
            subtype: AnnotationSubtype.INITIALS,
            size,
            annotationDesignationId,
          },
        },
        optimisticResponse: {
          addVectorGraphicAnnotation: {
            annotation: {
              ...optimisticAnnotation,
              asset: null,
              pngAsset: { __typename: "SecureUrl", url: assetUrl!.url },
              kind: AnnotationGraphicTypes.INITIALS,
              subtype: AnnotationSubtype.INITIALS,
              size: {
                ...size,
                __typename: "Size",
              },
              __typename: "VectorGraphicAnnotation",
            },
            ...optimisticResponse,
            __typename: "AddVectorGraphicAnnotationPayload",
          },
        },
        update(cacheProxy, { data }) {
          const { addVectorGraphicAnnotation } = data!;
          addNewAnnotationToDocumentCache(cacheProxy, {
            meetingId,
            documentId,
            newAnnotation: addVectorGraphicAnnotation!.annotation!,
            annotationDesignationId,
            errors: addVectorGraphicAnnotation!.errors,
          });
        },
      });
      return data!.addVectorGraphicAnnotation!.annotation!;
    }
    case "signer-designation-signature":
    case "signer-designation-initials":
    case "signer-designation-date":
    case "signer-designation-free-text": {
      const type =
        currentTool === "signer-designation-signature"
          ? AnnotationDesignationType.SIGNATURE
          : currentTool === "signer-designation-initials"
            ? AnnotationDesignationType.INITIALS
            : currentTool === "signer-designation-date"
              ? AnnotationDesignationType.DATE_SIGNED
              : AnnotationDesignationType.FREE_TEXT;
      const x = Math.round(point.x);
      const y = Math.round(point.y);
      const { data } = await mutateCallbacks.createDesignationMutateFn({
        variables: {
          input: {
            meetingId,
            documentId,
            page: pageIndex,
            point: [x, y],
            height: 23,
            width: 116,
            coordinateSystem: CoordinateSystem.ABSOLUTE,
            signerRole: {
              role: activeSignerParticipant.signerRole!.role,
              index: activeSignerParticipant.signerRole!.index,
            },
            type,
          },
        },
        optimisticResponse: {
          createAnnotationDesignation: {
            annotationDesignation: {
              id: `ad-signer-designation-preview-${v4()}`,
              type,
              active: true,
              editable: true,
              fulfilled: false,
              inProgress: false,
              optional: false,
              required: true,
              designationGroupId: null,
              dependentDesignationIds: null,
              hint: "",
              signerRole: {
                role: activeSignerParticipant.signerRole!.role,
                index: activeSignerParticipant.signerRole!.index,
                __typename: "SignerRole",
              },
              size: {
                height: 23,
                width: 116,
                __typename: "Size",
              },
              location: {
                coordinateSystem: CoordinateSystem.ABSOLUTE,
                page: pageIndex,
                pageType: PageTypes.DOCUMENT,
                point: { x, y, __typename: "Point" },
                __typename: "AnnotationLocation",
              },
              __typename: "AnnotationDesignation",
            },
            errors: null,
            __typename: "CreateAnnotationDesignationPayload",
          },
        },
        update(cacheProxy, { data }) {
          const { annotationDesignation, errors } = data!.createAnnotationDesignation!;
          addNewDesignationToDocumentCache(cacheProxy, {
            meetingId,
            documentId,
            newDesignation: annotationDesignation!,
            errors,
          });
        },
      });
      return data!.createAnnotationDesignation!.annotationDesignation!;
    }
    case AnnotationSubtype.NOTARY_CITY:
    case AnnotationSubtype.COMMISSION_EXPIRY:
    case AnnotationSubtype.COMMISSION_COUNTY:
    case AnnotationSubtype.COUNTY:
    case AnnotationSubtype.DESIGNATED_AGENT:
    case AnnotationSubtype.DISCLAIMER:
    case AnnotationSubtype.DISCLOSURE:
    case AnnotationSubtype.NOTARY_ID:
    case AnnotationSubtype.NOTARY_NAME:
    case AnnotationSubtype.STATE:
    case AnnotationSubtype.N_A:
    case AnnotationSubtype.SIGNER_NAME:
    case AnnotationSubtype.PRINCIPAL_ID_TYPE:
    case AnnotationSubtype.FREE_TEXT:
    case "text":
    default: {
      const { text, subtype } = freeTextOverride
        ? { text: [freeTextOverride], subtype: currentTool as AnnotationSubtype }
        : textParamsFromSubType({
            subtype: currentTool,
            notaryUser,
            activeParticipant: activeSignerParticipant,
          });
      const defaultText = annotationDesignationId
        ? TEXT_ANNOTATION_EM
        : TEXT_ANNOTATION_PLACEHOLDER;

      if (text) {
        const annotationPromises = text.map((text, index) => {
          const size = annotationPdfPointSizeFromText(text || defaultText);
          const input = {
            ...mixin,
            location: {
              ...mixin.location,
              point: { ...point, y: point.y - index * size.height },
            },
            text,
            editable: true,
            newSubtype: subtype,
            size,
            // If a single designation is resolved by placing multiple text annotations, only the first annotation can be tied to the original designation
            // This specifically happens with pre-placed `disclosure` designations for states like Ohio and Maryland
            annotationDesignationId: index === 0 ? annotationDesignationId : null,
          };

          return mutateCallbacks
            .addTextAnnotationMutateFn({
              variables: { input },
              optimisticResponse: {
                addTextAnnotation: {
                  __typename: "AddTextAnnotationPayload",
                  annotation: {
                    ...optimisticAnnotation,
                    size: { ...size, __typename: "Size" },
                    static: false,
                    text: text || "",
                    subtype,
                    __typename: "TextAnnotation",
                  },
                  ...optimisticResponse,
                },
              },
              update(cacheProxy, { data }) {
                const { addTextAnnotation } = data!;
                addNewAnnotationToDocumentCache(cacheProxy, {
                  meetingId,
                  documentId,
                  newAnnotation: addTextAnnotation!.annotation!,
                  annotationDesignationId,
                  errors: addTextAnnotation!.errors,
                });
              },
            })
            .then(({ data }) => {
              return data!.addTextAnnotation!.annotation!;
            });
        });

        return Promise.all(annotationPromises).then((annotations) => {
          const annotation = annotations[annotations.length - 1];
          // We dont want to focus if the shift key is held because we will then blur this when the notary adds the
          // next annotation, causing pspdfkit's auto delete behavior.
          if (
            !freeTextOverride &&
            !shiftKey &&
            annotation.subtype === AnnotationSubtype.FREE_TEXT
          ) {
            setFocused?.(annotation.id);
          }
          return annotation;
        });
      }
      const size = annotationPdfPointSizeFromText(defaultText);
      const annotation = await mutateCallbacks
        .addTextAnnotationMutateFn({
          variables: {
            input: {
              ...mixin,
              location: { ...mixin.location, point },
              text: "",
              editable: true,
              newSubtype: subtype,
              size,
              annotationDesignationId,
            },
          },
          optimisticResponse: {
            addTextAnnotation: {
              __typename: "AddTextAnnotationPayload",
              annotation: {
                ...optimisticAnnotation,
                size: { ...size, __typename: "Size" },
                static: false,
                text: "",
                subtype,
                __typename: "TextAnnotation",
              },
              ...optimisticResponse,
            },
          },
          update(cacheProxy, { data }) {
            const { addTextAnnotation } = data!;
            addNewAnnotationToDocumentCache(cacheProxy, {
              meetingId,
              documentId,
              newAnnotation: addTextAnnotation!.annotation!,
              annotationDesignationId,
              errors: addTextAnnotation!.errors,
            });
          },
        })
        .then(({ data }) => {
          const annotation = data!.addTextAnnotation!.annotation;
          // We dont want to focus if the shift key is held because we will then blur this when the notary adds the
          // next annotation, causing pspdfkit's auto delete behavior.
          if (
            !freeTextOverride &&
            !shiftKey &&
            annotation!.subtype === AnnotationSubtype.FREE_TEXT
          ) {
            setFocused?.(annotation!.id);
          }
          return annotation;
        });
      return annotation!;
    }
  }
}

export function useSealMutationCallback() {
  const addSealImageAnnotationMutateFn = useMutation(AddSealImageAnnotationMutation);
  return useCallback(
    (
      inputArgs: InputArgs,
      notarialAct: NotarialActs,
      principals: string[],
      notaryUser: NotaryUser,
      cache: GraphicCache,
    ) => {
      const annotationDesignationId = inputArgs.annotationDesignationId || null;
      const { documentId, meetingId, location } = inputArgs;
      const { sealInfo } = notaryUser.notaryProfile!;
      const size = getCachedSizeForAnnotation({
        cache,
        notaryUser,
        type: AnnotationGraphicTypes.SEAL,
      });
      const input = {
        meetingId,
        authorId: notaryUser.id,
        annotationDesignationId,
        documentId,
        key: sealInfo!.assetUrl!.key,
        location: {
          pageType: location.pageType,
          page: location.pageIndex,
          point: location.point,
          coordinateSystem: CoordinateSystem.ABSOLUTE,
        },
        size,
        notarialAct,
        principals,
      };
      return addSealImageAnnotationMutateFn({
        variables: { input },
        optimisticResponse: {
          addSealImageAnnotation: {
            annotation: {
              annotationDesignationId,
              asset: { __typename: "SecureUrl", url: sealInfo!.assetUrl!.url },
              authorId: notaryUser.id,
              id: createOptimisticId(),
              subtype: AnnotationSubtype.SEAL,
              notarialActEnum: notarialAct,
              notarialActPrincipals: principals.map((id) => ({
                id,
                __typename: "SignerParticipant",
                fullName: "",
              })),
              location: {
                coordinateSystem: CoordinateSystem.ABSOLUTE,
                page: location.pageIndex,
                pageType: PageTypes.DOCUMENT,
                point: {
                  x: location.point.x,
                  y: location.point.y,
                  __typename: "Point",
                },
                __typename: "AnnotationLocation",
              },
              size: {
                ...size,
                __typename: "Size",
              },
              meetingId,
              __typename: "ImageAnnotation",
              canEdit: true,
            },
            annotationDesignation: annotationDesignationId
              ? {
                  fulfilled: true,
                  id: annotationDesignationId,
                  __typename: "AnnotationDesignation",
                }
              : null,
            meeting: null, // Null because we do not want to update the lock button until mutation completes
            errors: null,
            __typename: "AddSealImageAnnotationPayload",
          },
        },
        update(cacheProxy, { data }) {
          const { addSealImageAnnotation } = data!;
          addNewAnnotationToDocumentCache(cacheProxy, {
            meetingId,
            documentId,
            newAnnotation: addSealImageAnnotation!.annotation!,
            annotationDesignationId,
            errors: addSealImageAnnotation!.errors,
          });
        },
      }).then(({ data }) => data!.addSealImageAnnotation!.annotation!);
    },
    [addSealImageAnnotationMutateFn],
  );
}
