import type { ComponentProps } from "react";
import { FormattedMessage } from "react-intl";

import { DeprecatedDetailGridRow } from "common/details/grid/row";
import CustomerId from "common/core/customer_id";
import { DescriptionListItem } from "common/core/description_list";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";

type Props = {
  signerIdentity: ComponentProps<typeof CustomerId>["signerIdentity"];
};

function PhotoId({ signerIdentity }: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  const photoIdLabel = (
    <FormattedMessage id="2d573e27-297c-49ae-88d9-1860027d0f90" defaultMessage="Signer Photo ID" />
  );

  const photoIdContent = <CustomerId signerIdentity={signerIdentity} />;
  return isTxnDetailsRedesign ? (
    <DescriptionListItem term={photoIdLabel} definition={photoIdContent} />
  ) : (
    <div>
      <DeprecatedDetailGridRow
        title={photoIdLabel}
        className="NotarizationDetails-user-license-row"
      >
        <div className="NotarizationDetails-user-license">{photoIdContent}</div>
      </DeprecatedDetailGridRow>
    </div>
  );
}

export default PhotoId;
