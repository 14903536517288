import { v4 } from "uuid";

import { PageTypes, CoordinateSystem, AnnotationDesignationType } from "graphql_globals";

import type { OverrideLookup } from ".";

let DESIGNATION_DB: Record<string, unknown>[];

function makeId() {
  return `ad-${v4()}`;
}

export function setupDesignations() {
  DESIGNATION_DB = [];
}

export function getDesignations(documentId?: string) {
  return documentId
    ? DESIGNATION_DB.filter((des) => des.documentId === documentId)
    : DESIGNATION_DB;
}

export const DESIGNATION_TYPE_RESOLVERS: OverrideLookup = Object.freeze({
  UpdateAnnotationDesignationLocationPayload: (root, args) => {
    const { id, point } = args.input;
    const [x, y] = point;
    const oldDes = DESIGNATION_DB.find((des) => des.id === id) as {
      location: { point: Record<string, unknown> };
    };
    const newDesignation = {
      ...oldDes,
      location: {
        ...oldDes.location,
        point: { ...oldDes.location.point, x, y },
      },
    };
    DESIGNATION_DB = DESIGNATION_DB.map((des) => {
      return des.id === id ? newDesignation : des;
    });
    return {
      annotationDesignation: newDesignation,
      errors: null,
    };
  },
  DeleteAnnotationDesignationPayload: (root, args) => {
    const { id } = args.input;
    DESIGNATION_DB = DESIGNATION_DB.filter((des) => des.id !== id);
    return {
      errors: null,
    };
  },
  CreateAnnotationDesignationPayload: (root, args) => {
    const { type, height, width, page, point, signerRole, documentId } = args.input;
    const newDesignation = {
      id: makeId(),
      type,
      active: true,
      dependentDesignationIds: null,
      editable: true,
      assignable: false,
      fulfilled: false,
      required: false,
      optional: false,
      designationGroupId: null,
      annotation: null,
      inProgress: false,
      documentId,
      size: { height, width },
      signerRole,
      hint:
        type === AnnotationDesignationType.SIGNATURE
          ? "Signature"
          : type === AnnotationDesignationType.INITIALS
            ? "Initials"
            : type === AnnotationDesignationType.DATE_SIGNED
              ? "Today's Date"
              : type === AnnotationDesignationType.NAME
                ? "Full Name"
                : "Fill in here",
      location: {
        page,
        point: {
          x: point[0],
          y: point[1],
        },
        coordinateSystem: CoordinateSystem.ABSOLUTE,
        pageType: PageTypes.DOCUMENT,
      },
    };
    DESIGNATION_DB.push(newDesignation);
    return {
      annotationDesignation: newDesignation,
      designationGroup: null,
      updatedDesignations: [],
      errors: null,
    };
  },
});
