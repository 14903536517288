import type { ReactNode, ComponentProps } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import PopoutMenu from "common/core/popout_menu";
import Tooltip from "common/core/tooltip";
import Icon from "common/core/icon";
import SROnly from "common/core/screen_reader";
import type { NetworkQuality } from "common/video_conference";

import { TrackButtonSpan, TrackButton } from "./track_button";
import { MuteToggleIconButton, MuteToggleButton } from "./mute_toggle";
import { NetworkQualityBars, NetworkQualityBarsTooltip } from "./network_quality_bars";
import VideoPausedOverlay from "./video_paused_overlay";
import Styles from "./track.module.scss";

type TrackProps = {
  networkQuality?: NetworkQuality;
  track: ReactNode;
  children?: ReactNode;
  isCompleted?: boolean;
  muted?: boolean;
  onToggleMute?: () => void;
  videoStatus?: ComponentProps<typeof VideoPausedOverlay>["videoStatus"] | null;
  popoutMenuProps?: Omit<ComponentProps<typeof PopoutMenu>, "target" | "aria-label" | "placement">;
  trackButtonAriaLabel?: string;
};
type NotaryControlTrackProps = Omit<TrackProps, "children" | "videoStatus"> & {
  onCheckId?: () => void;
  checkIdWarning: boolean;
  videoStatus: TrackProps["videoStatus"];
};

type WitnessControlTrackProps = Omit<TrackProps, "children"> & {
  onCheckId?: () => void;
  checkIdWarning: boolean;
};

type CondensedTrackProps = {
  isCompleted: boolean;
  videoStatus?: TrackProps["videoStatus"];
  networkQuality?: TrackProps["networkQuality"];
  muted?: boolean;
  onToggleMute?: () => void;
  track: ReactNode;
};

const MEETING_OVER = (
  <span className={Styles.meetingEnded}>
    <FormattedMessage
      id="c8ec3fe1-3d2d-4855-b4ab-97dc29a9bb28"
      defaultMessage="Meeting is over..."
    />
  </span>
);

export function Track(props: TrackProps) {
  if (props.isCompleted) {
    return MEETING_OVER;
  }
  const { videoStatus, popoutMenuProps, onToggleMute, networkQuality, trackButtonAriaLabel } =
    props;
  return (
    <>
      {videoStatus && <VideoPausedOverlay videoStatus={videoStatus} />}
      {props.track}
      <div className={Styles.trackControls}>
        {networkQuality !== undefined && (
          <div className={Styles.networkQualityContainer}>
            <NetworkQualityBarsTooltip
              networkQuality={networkQuality}
              tooltipPlacement="right"
              tooltipTarget={
                <TrackButtonSpan>
                  <NetworkQualityBars networkQuality={networkQuality} />
                </TrackButtonSpan>
              }
            />
          </div>
        )}
        {props.children}
        {onToggleMute && <MuteToggleButton muted={props.muted} onToggle={onToggleMute} />}
        {popoutMenuProps && (
          <PopoutMenu
            target={
              <TrackButton aria-label={trackButtonAriaLabel}>
                <Icon name="kebab-menu" size="extraLarge" />
              </TrackButton>
            }
            placement="leftTop"
            {...popoutMenuProps}
          />
        )}
      </div>
    </>
  );
}

export const StandardTrack: (
  props: Omit<TrackProps, "videoStatus" | "children">,
) => ReturnType<typeof Track> = Track;

/** Standard track with extra functionality for a viewer who is a notary */
export function StandardTrackWithNotaryControls(props: NotaryControlTrackProps) {
  const { onCheckId } = props;
  return (
    <Track {...props}>
      {onCheckId && (
        <Tooltip
          size="mini"
          onClick={onCheckId}
          target={
            <TrackButtonSpan
              data-automation-id="check-photo-id"
              className={classnames(
                Styles.checkIdButton,
                Styles.cursorButton,
                props.checkIdWarning && Styles.warningValidateId,
              )}
            >
              <SROnly>
                <FormattedMessage
                  id="c8126d32-df8f-407a-ad32-bbbc394e38ea"
                  defaultMessage="Check and validate identification"
                />
              </SROnly>
              <svg viewBox="0 0 24 18" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M21 5V4H18H15V5H18H21ZM22 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H22C23.1 18 23.99 17.1 23.99 16L24 2C24 0.9 23.1 0 22 0ZM8 3C9.66 3 11 4.34 11 6C11 7.66 9.66 9 8 9C6.34 9 5 7.66 5 6C5 4.34 6.34 3 8 3ZM14 15H2V14C2 12 6 10.9 8 10.9C10 10.9 14 12 14 14V15ZM22 9H14V3H22V9Z" />
                <path d="M21 5.5V6.5H18H15V5.5H18H21Z" />
              </svg>
            </TrackButtonSpan>
          }
        >
          <FormattedMessage
            id="6a70f3a0-cf39-43a8-80e0-58d125cbff18"
            defaultMessage="Validate ID"
          />
        </Tooltip>
      )}
    </Track>
  );
}

/** Standard track with extra functionality for a viewer who is a remote witness */
export function StandardTrackWithWitnessControls(props: WitnessControlTrackProps) {
  const { onCheckId } = props;
  return (
    <Track {...props}>
      {onCheckId && (
        <Tooltip
          size="mini"
          onClick={onCheckId}
          target={
            <TrackButtonSpan
              data-automation-id="check-photo-id"
              className={classnames(
                Styles.checkIdButton,
                Styles.cursorButton,
                props.checkIdWarning && Styles.warningValidateId,
              )}
            >
              <SROnly>
                <FormattedMessage
                  id="292307bc-8fd8-4a11-ace3-386dab7a72b2"
                  defaultMessage="Check identification"
                />
              </SROnly>
              <svg viewBox="0 0 24 18" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path d="M21 5V4H18H15V5H18H21ZM22 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H22C23.1 18 23.99 17.1 23.99 16L24 2C24 0.9 23.1 0 22 0ZM8 3C9.66 3 11 4.34 11 6C11 7.66 9.66 9 8 9C6.34 9 5 7.66 5 6C5 4.34 6.34 3 8 3ZM14 15H2V14C2 12 6 10.9 8 10.9C10 10.9 14 12 14 14V15ZM22 9H14V3H22V9Z" />
                <path d="M21 5.5V6.5H18H15V5.5H18H21Z" />
              </svg>
            </TrackButtonSpan>
          }
        >
          <FormattedMessage id="2bc2dcac-e2f6-4687-bbd3-baee5e2f27e9" defaultMessage="Check ID" />
        </Tooltip>
      )}
    </Track>
  );
}

export function CondensedStandardTrack(props: CondensedTrackProps) {
  if (props.isCompleted) {
    return MEETING_OVER;
  }
  const { videoStatus, onToggleMute, networkQuality } = props;
  return (
    <>
      {videoStatus && <VideoPausedOverlay videoStatus={videoStatus} />}
      <div className={Styles.condensedTrackControls}>
        {networkQuality !== undefined && (
          <NetworkQualityBarsTooltip networkQuality={networkQuality} />
        )}
        {onToggleMute && <MuteToggleIconButton muted={props.muted} onToggle={onToggleMute} />}
      </div>
      {props.track}
    </>
  );
}

export function SimulatedIndicator(props: { children: ReactNode }) {
  return <div className={Styles.simulatedIndicator}>{props.children}</div>;
}
