import {
  AnnotationSubtype,
  type AnnotationGraphicTypes,
  type NotarialActs,
  type CoordinateSystem,
} from "graphql_globals";
import type {
  MeetingAnnotation,
  MeetingAnnotation_TextAnnotation as TextMeetingAnnotation,
} from "common/meeting/pdf/annotation/index_fragment.graphql";
import type { DocumentWithCollections } from "common/meeting/pdf/document_with_collections_fragment.graphql";

import { SOCKET_EVENT_PAGE_TYPES } from "./meeting_events";

function annotationGraphTypeFromSocket(attributes: AddData["attributes"]) {
  switch (attributes.type) {
    case "whitebox":
      return {
        __typename: "WhiteboxAnnotation" as const,
        subtype: AnnotationSubtype.WHITEBOX,
      };
    case "checkmark":
      return {
        __typename: "CheckmarkAnnotation" as const,
        subtype:
          attributes.subtype === "radio_checkmark"
            ? AnnotationSubtype.RADIO_CHECKMARK
            : AnnotationSubtype.CHECKMARK,
      };
    case "text":
      return {
        __typename: "TextAnnotation" as const,
        subtype: attributes.subtype.toUpperCase() as AnnotationSubtype, // Graph is capitalizing enums on transmit
        text: attributes.data.text || "",
        static: false,
      };
    case "image":
      return {
        __typename: "ImageAnnotation" as const,
        subtype: AnnotationSubtype.SEAL, // This is the only image subtype
        notarialActEnum: attributes.data.notarial_act.toUpperCase() as NotarialActs,
        notarialActPrincipals: [],
        asset: {
          __typename: "SecureUrl" as const,
          url: attributes.data.url,
        },
      };
    case "vector_graphic": {
      const subtype =
        attributes.subtype === "signature"
          ? AnnotationSubtype.SIGNATURE
          : AnnotationSubtype.INITIALS;
      return {
        __typename: "VectorGraphicAnnotation" as const,
        kind: subtype as unknown as AnnotationGraphicTypes,
        subtype,
        pngAsset: {
          __typename: "SecureUrl" as const,
          url: attributes.data.png_url,
        },
        asset: {
          __typename: "SecureUrl" as const,
          url: attributes.data.url,
        },
      };
    }
    default:
      throw new Error(
        `Unknown socket event annotation type ${(attributes as { type: string }).type}.`,
      );
  }
}

function createNewAnnotationFromSocketData(socketData: AddData): MeetingAnnotation {
  const {
    attributes,
    relationships: { author, annotation_designation, meeting },
  } = socketData;
  return {
    id: attributes.gid,
    authorId: author.data.gid,
    annotationDesignationId: annotation_designation?.data.gid || null,
    location: {
      __typename: "AnnotationLocation",
      coordinateSystem: attributes.coordinate_system,
      page: attributes.page,
      pageType: SOCKET_EVENT_PAGE_TYPES[attributes.page_type],
      point: {
        __typename: "Point",
        x: attributes.point[0],
        y: attributes.point[1],
      },
    },
    size: {
      __typename: "Size",
      height: attributes.data.height,
      width: attributes.data.width,
    },
    meetingId: meeting.data.gid,
    canEdit: false,
    ...annotationGraphTypeFromSocket(attributes),
  };
}

type OtherAttrs = {
  type: "checkmark" | "whitebox";
};
type VectorGraphicAttrs = {
  type: "vector_graphic";
  data: { png_url: string; url: string };
};
type ImageAttrs = {
  type: "image";
  data: { notarial_act: string; url: string };
};
type TextAttrs = {
  type: "text";
  data: { text: null | string };
};
type AddData = {
  attributes: (OtherAttrs | VectorGraphicAttrs | ImageAttrs | TextAttrs) & {
    gid: string;
    data: { height: number; width: number };
    point: [x: number, y: number];
    page: number;
    coordinate_system: CoordinateSystem;
    page_type: keyof typeof SOCKET_EVENT_PAGE_TYPES;
    subtype: string;
  };
  relationships: {
    meeting: { data: { gid: string } };
    document: { data: { gid: string } };
    author: { data: { gid: string } };
    annotation_designation?: null | { data: { gid: string } };
  };
};
type AddEvent = { annotation: { data: AddData } };
export const addedEvent = [
  "annotation.added",
  (event: unknown) => (event as AddEvent).annotation.data.relationships.document.data.gid,
  (docNode: DocumentWithCollections, event: unknown) => {
    const newAnnotation = createNewAnnotationFromSocketData((event as AddEvent).annotation.data);
    const { annotationDesignationId, id: newAnnotationId } = newAnnotation;
    // We need to make sure we do not add an annotation twice to the graph cache.
    // A mutation might have already taken care of this.
    if (docNode.annotations.edges.some((edge) => edge.node.id === newAnnotationId)) {
      return;
    }
    return {
      ...docNode,
      annotationDesignations: annotationDesignationId
        ? {
            ...docNode.annotationDesignations,
            edges: docNode.annotationDesignations.edges.map((edge) => {
              return edge.node.id === annotationDesignationId
                ? { ...edge, node: { ...edge.node, fulfilled: true } }
                : edge;
            }),
          }
        : docNode.annotationDesignations,
      annotations: {
        ...docNode.annotations,
        edges: docNode.annotations.edges.concat({
          node: newAnnotation,
          __typename: "AnnotationEdge",
        }),
      },
    };
  },
] as const;

type TextUpdateEvent = { documentId: string; annotation: string; text: string; width: number };
export const textUpdatedEvent = [
  "annotation.text.updated",
  (event: unknown) => {
    const { annotation: annotationId, documentId } = event as TextUpdateEvent;
    return { documentId, annotationId };
  },
  (annotation: MeetingAnnotation, event: unknown): TextMeetingAnnotation => {
    const { text, width } = event as TextUpdateEvent;
    return { ...annotation, text, size: { ...annotation.size!, width } } as TextMeetingAnnotation;
  },
] as const;

type RemoveEvent = { documentId: string; annotation: string };
export const removedEvent = [
  "annotation.removed",
  (event: unknown) => (event as RemoveEvent).documentId,
  (docNode: DocumentWithCollections, event: unknown) => {
    const { annotation: annotationId } = event as RemoveEvent;
    const annotationEdge = docNode.annotations.edges.find(
      (annoEdge) => annoEdge.node.id === annotationId,
    );
    if (!annotationEdge) {
      return;
    }
    const { annotationDesignationId } = annotationEdge.node;
    return {
      ...docNode,
      annotationDesignations: annotationDesignationId
        ? {
            ...docNode.annotationDesignations,
            edges: docNode.annotationDesignations.edges.map((edge) => {
              return edge.node.id === annotationDesignationId
                ? { ...edge, node: { ...edge.node, fulfilled: false } }
                : edge;
            }),
          }
        : docNode.annotationDesignations,
      annotations: {
        ...docNode.annotations,
        edges: docNode.annotations.edges.filter((edge) => edge.node.id !== annotationId),
      },
    };
  },
] as const;

type MovedEvent = { documentId: string; annotation: string; point: [x: number, y: number] };
export const movedEvent = [
  "annotation.moved",
  (event: unknown) => {
    const { documentId, annotation: annotationId } = event as MovedEvent;
    return { documentId, annotationId };
  },
  (annotation: MeetingAnnotation, event: unknown): MeetingAnnotation => {
    const {
      point: [x, y],
    } = event as MovedEvent;
    return {
      ...annotation,
      location: {
        ...annotation.location,
        point: { ...annotation.location.point, x, y },
      },
    };
  },
] as const;

type ResizeEvent = { documentId: string; annotation: string; height: number; width: number };
export const resizedEvent = [
  "annotation.resized",
  (event: unknown) => {
    const { documentId, annotation: annotationId } = event as ResizeEvent;
    return { documentId, annotationId };
  },
  (annotation: MeetingAnnotation, event: unknown): MeetingAnnotation => {
    const { height, width } = event as ResizeEvent;
    return {
      ...annotation,
      size: { ...annotation.size!, height, width },
    };
  },
] as const;
