import { type ReactNode, useState } from "react";
import { useIntl, defineMessages } from "react-intl";
import classnames from "classnames";

import { type OrgTransactionStates, OrganizationTransactionContactRoleType } from "graphql_globals";
import { ROLE_LABELS } from "constants/points_of_contact";
import CollapsibleListItem from "common/core/collapsible_list/item";
import { DeprecatedDetailGrid } from "common/details/grid";
import { userFullName } from "util/user";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { Card } from "common/core/card";
import { Heading, Substyle } from "common/core/typography";
import { captureException } from "util/exception";
import Link from "common/core/link";
import { DescriptionList } from "common/core/description_list";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { IconButton } from "common/core/button/icon_button";

import RemoveContactModal from "./remove_contact_modal";
import Styles from "./index.module.scss";
import type { ContactForTransactionDetailsContactDetails } from "../index_fragment.graphql";

type Props = {
  contact: ContactForTransactionDetailsContactDetails;
  children: ReactNode;
  transaction: { state: OrgTransactionStates | null };
  onRemove?: () => Promise<unknown>;
  "data-automation-id"?: string;
};
const MESSAGES = defineMessages({
  remove: {
    id: "89d13bfc-a476-4de1-b6f3-36354e21f7dd",
    defaultMessage: "Remove",
  },
});

export function ContactDetailsWrapper({
  contact,
  children,
  onRemove,
  transaction,
  "data-automation-id": automationId,
}: Props) {
  const intl = useIntl();
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const [removeModalState, setRemoveModalState] = useState<"open" | "loading" | "closed">("closed");

  const header = userFullName(contact);
  const subheader =
    contact.role === OrganizationTransactionContactRoleType.OTHER
      ? contact.title
      : ROLE_LABELS[contact.role];

  const removeContactButton = isTxnDetailsRedesign ? (
    <IconButton
      className={Styles.removeContact}
      label={intl.formatMessage(MESSAGES.remove)}
      automationId="remove-contact"
      name="delete"
      onClick={() => setRemoveModalState("open")}
      buttonColor="danger"
      variant="secondary"
    />
  ) : (
    <Link
      className="ContactDetails--remove"
      automationId="remove-contact"
      onClick={(e) => {
        e.stopPropagation();
        setRemoveModalState("open");
      }}
      underlined={false}
    >
      {intl.formatMessage(MESSAGES.remove)}
    </Link>
  );

  const contactDetailsHeader = (
    <div className={Styles.contactDetailsCardHeader}>
      <Heading level="h2" textStyle="subtitle" className={Styles.contactDetailsCardHeading}>
        <span>{header}</span>
        <Substyle size="small" className={Styles.contactDetailsRole}>
          {subheader}
        </Substyle>
      </Heading>
      {onRemove && removeContactButton}
    </div>
  );

  return (
    <>
      {isTxnDetailsRedesign ? (
        <Card
          data-automation-id={automationId}
          header={contactDetailsHeader}
          collapsibleElement="icon"
          expanded
          noMargin
          fullWidth
          className={Styles.contactDetailsCard}
        >
          <DescriptionList itemGap="large">{children}</DescriptionList>
        </Card>
      ) : (
        <div className="ContactDetails" data-automation-id={automationId}>
          <CollapsibleListItem
            header={header}
            subheader={
              <div className="ContatDetails--subheader">
                <span
                  className={classnames(
                    "ContactDetails--role",
                    onRemove && "ContactDetails--role__can-edit",
                  )}
                >
                  {subheader}
                </span>
                {onRemove && removeContactButton}
              </div>
            }
            initialDisplay
          >
            <DeprecatedDetailGrid className={Styles.deprecatedNoPaddingGrid}>
              <DeprecatedDetailGridSection>{children}</DeprecatedDetailGridSection>
            </DeprecatedDetailGrid>
          </CollapsibleListItem>
        </div>
      )}
      {removeModalState !== "closed" && (
        <RemoveContactModal
          contact={contact}
          isLoading={removeModalState === "loading"}
          onClose={() => setRemoveModalState("closed")}
          onRemove={() => {
            setRemoveModalState("loading");
            onRemove!()
              .catch(() => {
                captureException(new Error("Couldn't delete organization transaction contact"), {
                  contact,
                  transaction,
                });
              })
              .finally(() => {
                setRemoveModalState("closed");
              });
          }}
        />
      )}
    </>
  );
}
