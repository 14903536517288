import { useCallback } from "react";

import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";

import RetireSignerIdentitiesMutation from "./retire_signer_identities_mutation.graphql";

export type Options = { ignoreNoActive?: boolean; userId?: string };

export function useRetireSignerIdentities(): (options?: Options) => Promise<unknown> {
  const retireSignerIdentitiesMutateFn = useMutation(RetireSignerIdentitiesMutation);
  return useCallback(
    (options?: Options) => {
      return retireSignerIdentitiesMutateFn({
        variables: { input: { userId: options?.userId } },
      }).catch((e) => {
        if (
          options?.ignoreNoActive &&
          isGraphQLError(e) &&
          e.message === "There are no active signer identities for the current session."
        ) {
          return;
        }
        return Promise.reject(e);
      });
    },
    [retireSignerIdentitiesMutateFn],
  );
}
