import type { ComponentProps } from "react";

import { DeprecatedDetailGridRow } from "common/details/grid/row";
import CompletionRequirementsText from "util/completion_requirements/completion_requirements_text";
import { DescriptionListItem } from "common/core/description_list";
import { PassFailStatus } from "common/transactions/details/common";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";

type Props = {
  completionRequirements: ComponentProps<
    typeof CompletionRequirementsText
  >["completionRequirements"];
  titleClassName?: string;
};

export function ValidationStatus({ completionRequirements, titleClassName }: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const validationStatusLabel = (
    <CompletionRequirementsText
      stringId="verificationMethod"
      completionRequirements={completionRequirements}
    />
  );
  const validationStatusContent = <PassFailStatus dataAutomationId="kba-status" status="pass" />;
  return isTxnDetailsRedesign ? (
    <DescriptionListItem term={validationStatusLabel} definition={validationStatusContent} />
  ) : (
    <DeprecatedDetailGridRow title={<div className={titleClassName}>{validationStatusLabel}</div>}>
      {validationStatusContent}
    </DeprecatedDetailGridRow>
  );
}
