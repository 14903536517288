import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { Heading } from "common/core/typography";

import Styles from "./index.module.scss";
import {
  filterSteps,
  ProfileReviewSteps,
  ProfileSteps,
  ProofCertSteps,
  RenderSteps,
} from "../../overview";
import type { Step } from "..";

type Props = {
  steps: readonly Step[];
};

function SidebarV2({ steps }: Props) {
  const profileSteps = filterSteps(steps, ProfileSteps);
  const proofCertSteps = filterSteps(steps, ProofCertSteps);
  const profileReviewSteps = filterSteps(steps, ProfileReviewSteps);

  const firstIncompleteStep = steps.find((step) => !step.completed);
  const firstIncompleteStepId = firstIncompleteStep?.id;

  return (
    <div className={Styles.main} data-automation-id="notary-onboarding-sidebar">
      <Heading level="h1" textStyle="subtitle" className={Styles.header}>
        <FormattedMessage
          id="0db304c6-1aa0-4268-afa4-5dad248ae0b1"
          defaultMessage="Your onboarding process"
        />
      </Heading>
      <div>
        <Heading level="h2" textStyle="subtitleSmall" className={Styles.subSection}>
          <FormattedMessage
            id="0ac1f501-4ff3-45bf-9e94-6824fc35bb26"
            defaultMessage="Notary Profile"
          />
        </Heading>
        <RenderSteps steps={profileSteps} stepCounter={1} />
      </div>
      {proofCertSteps.length > 0 && (
        <div>
          <Heading level="h2" textStyle="subtitleSmall" className={Styles.subSection}>
            <FormattedMessage
              id="6d518821-d176-4365-9c74-469e23381673"
              defaultMessage="Proof Digital Certificate"
            />
          </Heading>
          <RenderSteps steps={proofCertSteps} stepCounter={profileSteps.length + 1} />
        </div>
      )}
      <div>
        <Heading level="h2" textStyle="subtitleSmall" className={Styles.subSection}>
          <FormattedMessage
            id="6eaa008b-6f1c-48dd-bc3c-f34cfff5f1e7"
            defaultMessage="Profile Review"
          />
        </Heading>
        <RenderSteps
          steps={profileReviewSteps}
          firstIncompleteStepId={firstIncompleteStepId}
          stepCounter={profileSteps.length + proofCertSteps.length + 1}
        />
      </div>
    </div>
  );
}

export default memo(SidebarV2);
