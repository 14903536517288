import { useCallback, useEffect, useRef, useState, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";
import type { DocumentNode } from "graphql";

import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import {
  removeAnnotationFromCache,
  addNewAnnotationToDocumentCache,
  addRadioAnnotationToDocumentCache,
} from "common/meeting/pdf/annotation/util";
import {
  DATE_ANNOTATION_FORMAT,
  MAX_SIGNING_ASSET_WIDTH,
  type VECTOR_GRAPHIC_TYPES,
} from "constants/annotations";
import { simpleAssetUpload } from "util/uploader";
import { getImageSize } from "util/html";
import { createOptimisticId } from "common/meeting/pdf/annotation";
import type { Annotation as AnnotationComponent } from "common/pdf/pspdfkit/annotation";
import {
  CoordinateSystem,
  AnnotationGraphicTypes,
  AnnotationSubtype,
  RequiredFeature,
  type SignerAgreementTypes,
  type PageTypes,
  type SignatureMethod,
} from "graphql_globals";
import type { SigningAssets } from "common/signer/utils";
import { forceAssetRecreation, type SignatureOptionsOrganization } from "util/signature_options";
import MeetingQuery from "signer_portal/meeting/meeting_query.graphql";
import AddTextAnnotationMutation from "common/meeting/pdf/annotation/add_text_annotation_mutation.graphql";
import AddDateMutation from "common/meeting/pdf/annotation/add_date_annotation_mutation.graphql";
import AddCheckmarkAnnotationMutation from "common/meeting/pdf/annotation/add_checkmark_annotation_mutation.graphql";
import type {
  SignerMeetingDocument_meetingParticipants_WitnessParticipant as WitnessParticipant,
  SignerMeetingDocument_meetingParticipants_SignerParticipant as SignerParticipant,
  SignerMeetingDocument_documentBundle_documents_edges_node_annotations_edges as AnnotationEdge,
  SignerMeetingDocument_documentBundle_documents_edges_node_annotationDesignations_edges as DesignationEdge,
} from "signer_portal/meeting/v3/document/index_fragment.graphql";
import UpdateAnnotationTextMutation from "common/meeting/pdf/annotation/update_annotation_text_mutation.graphql";
import UpdateAnnotationSizeMutation from "common/meeting/pdf/annotation/selection/update_annotation_size_mutation.graphql";
import UpdateAnnotationLocationMutation from "common/meeting/pdf/annotation/selection/update_annotation_location_mutation.graphql";
import RemoveAnnotationMutation from "common/meeting/pdf/annotation/remove_annotation_mutation.graphql";
import { TEXT_ANNOTATION_PLACEHOLDER, TEXT_ANNOTATION_EM } from "constants/globals";
import type { GraphicCache } from "common/meeting/context/graphic_cache";
import {
  annotationDefaultPdfPointSize,
  annotationPdfPointSizeFromText,
  textAnnotationContent,
} from "common/pdf/util";
import { constrainSize } from "util/number";
import { updateMeetingParticipantSigningAssets } from "common/meeting/pdf/annotation/asset";
import { format } from "common/core/format/date";
import { browserTimeZone } from "util/date";
import type { AssetData } from "common/core/asset_generator";
import type { PspdkitAnnotation_TextAnnotation } from "common/pdf/pspdfkit/annotation/index_fragment.graphql";

import TrackSignerEsignatureAgreementMutation from "../track_signer_esignature_agreement_mutation.graphql";
import CreateSigningsMarkMutation from "../create_signings_mark_mutation.graphql";
import AddVectorGraphicAnnotationMutation from "./add_vector_graphic_annotation_mutation.graphql";

type AssetType = {
  font: string | null;
  method: SignatureMethod | null;
  key: string;
  url: string;
  size: { height: number; width: number };
};
type InputArgs = {
  meetingId: string;
  timezone: string | null;
  documentId: string;
  location: {
    pageType: PageTypes;
    pageIndex: number;
    point: { x: number; y: number };
  };
  annotationDesignationId?: string;
  requiredFeatures?: (RequiredFeature | null)[] | null;
};
type AnnotationLocationDescription = {
  id: string;
  subtype: AnnotationSubtype | null;
  location: {
    point: {
      x: number;
      y: number;
    };
  };
  size: null | {
    height: number;
    width: number;
  };
};
type AnnotationInput = {
  currentTool: AnnotationSubtype | string;
  args: InputArgs;
  participant: WitnessParticipant | SignerParticipant;
  mutateCallbacks: ReturnType<typeof useMutationCallbacks>;
  asset: null | AssetType;
  cache: GraphicCache;
  annotations: AnnotationEdge[];
  designations: DesignationEdge[];
  setFocused?: undefined | ((notarizeId: string) => void);
};
type AnnotationProps = ComponentProps<typeof AnnotationComponent>;
type AssetGeneratorState = {
  type: "signature" | "initials";
  onSaveAsset: (input: AssetData) => void;
  onCancelSaveAsset: () => void;
} | null;
type ReuseAssetState = {
  type: ObjectValues<typeof VECTOR_GRAPHIC_TYPES>;
  onReuseAsset: () => void;
  onRecreateAsset: () => void;
  onCancelReuseAsset: () => void;
} | null;
type VectorGraphicSubtype = "SIGNATURE" | "INITIALS";

export function useDeleteAnnotation({
  meetingId,
  documentId,
  userId,
  query,
  requiredFeatures,
}: {
  query: DocumentNode;
  userId: string | null | undefined;
  meetingId: string;
  documentId: string;
  requiredFeatures?: (RequiredFeature | null)[] | null;
}) {
  const removeAnnotationMutateFn = useMutation(RemoveAnnotationMutation);
  const shouldRefetch = Boolean(
    requiredFeatures?.find((req) => req === RequiredFeature.CONDITIONAL_FIELDS),
  );
  const refetchQueries = shouldRefetch ? { refetchQueries: [query] } : {};
  return useCallback(
    ({ id }: { id: string }) => {
      if (!userId) {
        return Promise.resolve();
      }
      const input = {
        id,
        meetingGid: meetingId,
        authorId: userId,
      };
      return removeAnnotationMutateFn({
        ...refetchQueries,
        variables: { input },
        optimisticResponse: {
          removeAnnotation: {
            errors: null,
            meeting: null, // Null because we do not want to update the lock button until mutation completes
            __typename: "RemoveAnnotationPayload",
          },
        },
        update(cacheProxy, { data }) {
          removeAnnotationFromCache(cacheProxy, {
            meetingId,
            documentId,
            annotationId: id,
            errors: data!.removeAnnotation!.errors,
          });
        },
      });
    },
    [removeAnnotationMutateFn, userId, query, documentId, meetingId],
  );
}

export function useMutationCallbacks() {
  return {
    addCheckmarkMutateFn: useMutation(AddCheckmarkAnnotationMutation),
    addDateMutateFn: useMutation(AddDateMutation),
    addTextAnnotationMutateFn: useMutation(AddTextAnnotationMutation),
    addVectorGraphicAnnotationMutateFn: useMutation(AddVectorGraphicAnnotationMutation),
  };
}

function cacheAnnotationSize({
  cache,
  subtype,
  userId,
  size,
}: {
  cache: GraphicCache;
  subtype: AnnotationSubtype;
  userId: string;
  size: { height: number; width: number };
}) {
  if (subtype === AnnotationSubtype.INITIALS || subtype === AnnotationSubtype.SIGNATURE) {
    const graphicType =
      subtype === AnnotationSubtype.INITIALS
        ? AnnotationGraphicTypes.INITIALS
        : AnnotationGraphicTypes.SIGNATURE;
    cache.set({
      userId,
      graphicType,
      value: size,
    });
  }
}

function constrainAsset({
  height,
  width,
  maxSize,
}: {
  width: number;
  height: number;
  maxSize?: { width: number; height: number };
}) {
  const max = maxSize || { height: 28, width: 500 };

  return width && height
    ? constrainSize({ width, height, maxHeight: max.height, maxWidth: max.width })
    : max;
}

export function getCachedSizeForAnnotation({
  cache,
  graphicType,
  userId,
  size,
  maxSize,
}: {
  cache: GraphicCache;
  graphicType: AnnotationGraphicTypes;
  userId: string;
  size: AssetType["size"];
  maxSize?: { height: number; width: number };
}) {
  const cachedSize = cache.get({ userId, graphicType });
  if (maxSize) {
    return cachedSize && maxSize.height >= cachedSize.height && maxSize.width >= cachedSize.width
      ? cachedSize
      : constrainAsset({ ...size, maxSize });
  }
  return cachedSize || constrainAsset({ ...size });
}

export function usePreloadAssets(options: {
  cache: GraphicCache;
  participant: { userId: string | null; signingAssets: SigningAssets } | null;
  organization: SignatureOptionsOrganization;
}) {
  const { cache, participant, organization } = options;
  const { userId, signingAssets } = participant || {};

  useEffect(() => {
    if (!participant) {
      return;
    }
    async function preload(graphicType: AnnotationGraphicTypes) {
      if (!userId) {
        throw new Error("Missing userId");
      }
      if (
        graphicType !== AnnotationGraphicTypes.SEAL &&
        forceAssetRecreation({
          organization,
          signingAssets,
          type: graphicType,
        })
      ) {
        return;
      }
      if (cache.get({ userId, graphicType })) {
        return;
      }
      const isSignature = graphicType === AnnotationGraphicTypes.SIGNATURE;
      const assetKey = isSignature ? ("signatureAsset" as const) : ("initialsAsset" as const);
      const existingPng = signingAssets?.[assetKey]?.png;
      if (!existingPng?.url) {
        return;
      }

      const size = await getImageSize(existingPng.url);
      cache.set({ userId, graphicType, value: constrainAsset(size) });
    }

    preload(AnnotationGraphicTypes.SIGNATURE);
    preload(AnnotationGraphicTypes.INITIALS);
  }, [userId, signingAssets, organization]);
}

function getExistingRadioGroupAnnotation(
  designations: DesignationEdge[],
  annotations: AnnotationEdge[],
  annotationDesignationId: string | null,
) {
  if (!annotationDesignationId) {
    return null;
  }

  const designation = designations.find((d) => d.node.id === annotationDesignationId);
  if (!designation) {
    return null;
  }

  const designationsInGroup = designations.filter(
    (d) => d.node.designationGroupId === designation.node.designationGroupId,
  );
  const fulfilledDesignationInGroup = designationsInGroup.find((d) => d.node.fulfilled);
  const existingAnnotation =
    fulfilledDesignationInGroup &&
    annotations.find((a) => a.node.annotationDesignationId === fulfilledDesignationInGroup.node.id);
  return existingAnnotation;
}

export async function addAnnotation({
  currentTool,
  args,
  participant,
  mutateCallbacks,
  asset,
  cache,
  setFocused,
  annotations,
  designations,
}: AnnotationInput): Promise<AnnotationLocationDescription> {
  const {
    meetingId,
    timezone,
    documentId,
    location: { point, pageIndex, pageType },
    requiredFeatures,
  } = args;
  const annotationDesignationId = args.annotationDesignationId || null;
  const mixin = {
    meetingId,
    documentId,
    authorId: participant.userId!,
    location: {
      pageType,
      page: pageIndex,
      point,
      coordinateSystem: CoordinateSystem.ABSOLUTE,
    },
  };
  const designation = designations.find(({ node }) => node.id === annotationDesignationId);

  const annotationDesignation = annotationDesignationId
    ? {
        fulfilled: true,
        required: false,
        id: annotationDesignationId,
        __typename: "AnnotationDesignation",
        inProgress: false,
        ...(currentTool === AnnotationSubtype.FREE_TEXT && {
          fulfilled: false,
          inProgress: true,
        }),
      }
    : null;

  const optimisticAnnotation = {
    id: createOptimisticId(),
    authorId: participant.userId!,
    annotationDesignationId,
    location: {
      coordinateSystem: CoordinateSystem.ABSOLUTE,
      page: pageIndex,
      pageType,
      point: { ...point, __typename: "Point" as const },
      __typename: "AnnotationLocation" as const,
    },
    meetingId,
    canEdit: true,
  };

  const optimisticResponse = {
    annotationDesignation,
    errors: null,
    meeting: null,
    document: null,
  };

  const shouldRefetch = Boolean(
    requiredFeatures?.find((req) => req === RequiredFeature.CONDITIONAL_FIELDS) &&
      annotationDesignationId,
  );
  const refetchQueries = shouldRefetch ? { refetchQueries: [MeetingQuery] } : {};

  switch (currentTool) {
    case AnnotationSubtype.CHECKMARK: {
      const size = annotationDefaultPdfPointSize({ type: AnnotationSubtype.CHECKMARK });
      return mutateCallbacks
        .addCheckmarkMutateFn({
          ...refetchQueries,
          variables: {
            input: { ...mixin, size, annotationDesignationId },
          },
          optimisticResponse: {
            addCheckmarkAnnotation: {
              __typename: "AddCheckmarkAnnotationPayload",
              annotation: {
                ...optimisticAnnotation,
                size: { ...size, __typename: "Size" },
                subtype: AnnotationSubtype.CHECKMARK,
                __typename: "CheckmarkAnnotation",
              },
              ...optimisticResponse,
              updatedDesignations: [],
              designationGroup: null,
            },
          },
          update(cacheProxy, { data }) {
            const { addCheckmarkAnnotation } = data!;
            addNewAnnotationToDocumentCache(cacheProxy, {
              meetingId,
              documentId,
              newAnnotation: addCheckmarkAnnotation!.annotation!,
              annotationDesignationId,
              errors: addCheckmarkAnnotation!.errors,
            });
          },
        })
        .then(({ data }) => data!.addCheckmarkAnnotation!.annotation!)
        .catch((error) => {
          if (
            isGraphQLError(error) &&
            typeof error.message === "string" &&
            error.message.includes("designation_group_max_fulfilled")
          ) {
            return Promise.reject({
              interactionErrorMessage: (
                <FormattedMessage
                  id="b09cd40e-5699-4ed4-8d97-0ab3cd0ac0a8"
                  defaultMessage="Oops! You’ve attempted to select too many checkboxes. Uncheck one before making a new selection."
                />
              ),
            });
          }
          return Promise.reject(error);
        });
    }
    case AnnotationSubtype.RADIO_CHECKMARK: {
      const size = annotationDefaultPdfPointSize({ type: AnnotationSubtype.CHECKMARK });
      const existingRadioGroupAnnotation = getExistingRadioGroupAnnotation(
        designations,
        annotations,
        annotationDesignationId,
      );
      const { data } = await mutateCallbacks.addCheckmarkMutateFn({
        ...refetchQueries,
        variables: {
          input: { ...mixin, size, annotationDesignationId },
        },
        optimisticResponse: {
          addCheckmarkAnnotation: {
            __typename: "AddCheckmarkAnnotationPayload",
            annotation: {
              ...optimisticAnnotation,
              size: { ...size, __typename: "Size" },
              subtype: AnnotationSubtype.RADIO_CHECKMARK,
              __typename: "CheckmarkAnnotation",
            },
            ...optimisticResponse,
            updatedDesignations: [],
            designationGroup: null,
          },
        },
        update(cacheProxy, { data }) {
          const { addCheckmarkAnnotation } = data!;
          if (existingRadioGroupAnnotation) {
            removeAnnotationFromCache(cacheProxy, {
              meetingId,
              documentId,
              annotationId: existingRadioGroupAnnotation.node.id,
              errors: addCheckmarkAnnotation!.errors,
            });
          }
          addRadioAnnotationToDocumentCache(MeetingQuery, cacheProxy, {
            meetingId,
            documentId,
            newAnnotation: addCheckmarkAnnotation!.annotation!,
            annotationDesignationId,
            errors: addCheckmarkAnnotation!.errors,
          });
        },
      });
      return data!.addCheckmarkAnnotation!.annotation!;
    }
    case AnnotationSubtype.DATE_SIGNED: {
      const text = format({
        value: new Date(),
        asTimeZone: timezone || browserTimeZone(),
        formatStyle: DATE_ANNOTATION_FORMAT,
      })!;
      const size = annotationPdfPointSizeFromText(text);
      const { data } = await mutateCallbacks.addDateMutateFn({
        ...refetchQueries,
        variables: {
          input: { ...mixin, size, annotationDesignationId },
        },
        optimisticResponse: {
          addDateAnnotation: {
            __typename: "AddDateAnnotationPayload",
            annotation: {
              ...optimisticAnnotation,
              size: { ...size, __typename: "Size" },
              static: false,
              text,
              subtype: AnnotationSubtype.DATE,
              __typename: "TextAnnotation",
            },
            ...optimisticResponse,
          },
        },
        update(cacheProxy, { data }) {
          const { addDateAnnotation } = data!;
          addNewAnnotationToDocumentCache(cacheProxy, {
            meetingId,
            documentId,
            newAnnotation: addDateAnnotation!.annotation!,
            annotationDesignationId,
            errors: addDateAnnotation!.errors,
          });
        },
      });
      return data!.addDateAnnotation!.annotation!;
    }
    case AnnotationSubtype.DAY_SIGNED:
    case AnnotationSubtype.YEAR_SIGNED:
    case AnnotationSubtype.MONTH_SIGNED:
    case AnnotationSubtype.N_A: {
      const text =
        currentTool === AnnotationSubtype.N_A
          ? "N/A"
          : textAnnotationContent(currentTool, { contact: null });
      const size = annotationPdfPointSizeFromText(text);
      const annotation = await mutateCallbacks
        .addTextAnnotationMutateFn({
          ...refetchQueries,
          variables: {
            input: {
              ...mixin,
              location: { ...mixin.location, point },
              text,
              editable: true,
              newSubtype: currentTool,
              size,
              annotationDesignationId,
            },
          },
          optimisticResponse: {
            addTextAnnotation: {
              __typename: "AddTextAnnotationPayload",
              annotation: {
                ...optimisticAnnotation,
                size: { ...size, __typename: "Size" },
                static: false,
                text,
                subtype: currentTool,
                __typename: "TextAnnotation",
              },
              ...optimisticResponse,
            },
          },
          update(cacheProxy, { data }) {
            const { addTextAnnotation } = data!;
            addNewAnnotationToDocumentCache(cacheProxy, {
              meetingId,
              documentId,
              newAnnotation: addTextAnnotation!.annotation!,
              annotationDesignationId,
              errors: addTextAnnotation!.errors,
            });
          },
        })
        .then(({ data }) => {
          return data!.addTextAnnotation!.annotation;
        });
      return annotation!;
    }
    case AnnotationSubtype.SIGNATURE: {
      const size = getCachedSizeForAnnotation({
        cache,
        userId: participant.userId!,
        graphicType: AnnotationGraphicTypes.SIGNATURE,
        size: asset!.size,
        maxSize: designation
          ? { width: designation.node.size.width, height: designation.node.size.height }
          : undefined,
      });
      const { data } = await mutateCallbacks.addVectorGraphicAnnotationMutateFn({
        ...refetchQueries,
        variables: {
          input: {
            ...mixin,
            key: asset!.key,
            subtype: AnnotationSubtype.SIGNATURE,
            size,
            annotationDesignationId,
          },
        },
        optimisticResponse: {
          addVectorGraphicAnnotation: {
            annotation: {
              ...optimisticAnnotation,
              asset: { __typename: "SecureUrl", url: asset!.url },
              pngAsset: { __typename: "SecureUrl", url: asset!.url },
              kind: AnnotationGraphicTypes.SIGNATURE,
              subtype: AnnotationSubtype.SIGNATURE,
              size: {
                ...size,
                __typename: "Size",
              },
              __typename: "VectorGraphicAnnotation",
            },
            author: {
              id: participant.userId!,
              signingAssets: {
                font:
                  participant.signingAssets?.signatureAsset?.font ??
                  participant.signingAssets?.initialsAsset?.font ??
                  null,
                initialsAsset: {
                  __typename: "SigningAsset",
                  font: participant.signingAssets?.initialsAsset?.font ?? null,
                  method: participant.signingAssets?.initialsAsset?.method ?? null,
                  png: participant.signingAssets?.initialsAsset?.png
                    ? {
                        key: participant.signingAssets.initialsAsset.png.key,
                        url: participant.signingAssets.initialsAsset.png.url,
                        __typename: "SecureUrl",
                      }
                    : null,
                  svg: participant.signingAssets?.initialsAsset?.svg
                    ? {
                        key: participant.signingAssets.initialsAsset.svg.key,
                        url: participant.signingAssets.initialsAsset.svg.url,
                        __typename: "SecureUrl",
                      }
                    : null,
                },
                signatureAsset: {
                  __typename: "SigningAsset",
                  font: participant.signingAssets?.signatureAsset?.font ?? null,
                  method: participant.signingAssets?.signatureAsset?.method ?? null,
                  png: {
                    key: asset!.key,
                    url: asset!.url,
                    __typename: "SecureUrl",
                  },
                  svg: null,
                },
                __typename: "SigningAssets",
              },
              __typename: "User",
            },
            ...optimisticResponse,
            __typename: "AddVectorGraphicAnnotationPayload",
          },
        },
        update(cacheProxy, { data }) {
          const { annotation, errors, author } = data!.addVectorGraphicAnnotation!;
          addNewAnnotationToDocumentCache(cacheProxy, {
            meetingId,
            documentId,
            newAnnotation: annotation!,
            annotationDesignationId,
            errors,
          });
          author &&
            updateMeetingParticipantSigningAssets(MeetingQuery, cacheProxy, {
              meetingId,
              userId: participant.userId!,
              signingAssets: author.signingAssets,
            });
        },
      });
      const prevAnnotation: AnnotationLocationDescription =
        data!.addVectorGraphicAnnotation!.annotation!;
      return prevAnnotation;
    }
    case AnnotationSubtype.INITIALS: {
      const size = getCachedSizeForAnnotation({
        cache,
        userId: participant.userId!,
        graphicType: AnnotationGraphicTypes.INITIALS,
        size: asset!.size,
        maxSize: designation
          ? { width: designation.node.size.width, height: designation.node.size.height }
          : undefined,
      });
      const { data } = await mutateCallbacks.addVectorGraphicAnnotationMutateFn({
        ...refetchQueries,
        variables: {
          input: {
            ...mixin,
            key: asset!.key,
            subtype: AnnotationSubtype.INITIALS,
            size,
            annotationDesignationId,
          },
        },
        optimisticResponse: {
          addVectorGraphicAnnotation: {
            annotation: {
              ...optimisticAnnotation,
              asset: { __typename: "SecureUrl", url: asset!.url },
              pngAsset: { __typename: "SecureUrl", url: asset!.url },
              kind: AnnotationGraphicTypes.INITIALS,
              subtype: AnnotationSubtype.INITIALS,
              size: {
                ...size,
                __typename: "Size",
              },
              __typename: "VectorGraphicAnnotation",
            },
            author: {
              id: participant.userId!,
              signingAssets: {
                font:
                  participant.signingAssets?.signatureAsset?.font ??
                  participant.signingAssets?.initialsAsset?.font ??
                  null,
                signatureAsset: {
                  __typename: "SigningAsset",
                  font: participant.signingAssets?.signatureAsset?.font ?? null,
                  method: participant.signingAssets?.signatureAsset?.method ?? null,
                  png: participant.signingAssets?.signatureAsset?.png
                    ? {
                        key: participant.signingAssets.signatureAsset.png.key,
                        url: participant.signingAssets.signatureAsset.png.url,
                        __typename: "SecureUrl",
                      }
                    : null,
                  svg: participant.signingAssets?.signatureAsset?.svg
                    ? {
                        key: participant.signingAssets.signatureAsset.svg.key,
                        url: participant.signingAssets.signatureAsset.svg.url,
                        __typename: "SecureUrl",
                      }
                    : null,
                },
                initialsAsset: {
                  __typename: "SigningAsset",
                  font: participant.signingAssets?.initialsAsset?.font ?? null,
                  method: participant.signingAssets?.initialsAsset?.method ?? null,
                  png: {
                    key: asset!.key,
                    url: asset!.url,
                    __typename: "SecureUrl",
                  },
                  svg: null,
                },
                __typename: "SigningAssets",
              },
              __typename: "User",
            },
            ...optimisticResponse,
            __typename: "AddVectorGraphicAnnotationPayload",
          },
        },
        update(cacheProxy, { data }) {
          const { author, errors, annotation } = data!.addVectorGraphicAnnotation!;
          addNewAnnotationToDocumentCache(cacheProxy, {
            meetingId,
            documentId,
            newAnnotation: annotation!,
            annotationDesignationId,
            errors,
          });
          author &&
            updateMeetingParticipantSigningAssets(MeetingQuery, cacheProxy, {
              meetingId,
              userId: participant.userId!,
              signingAssets: author.signingAssets,
            });
        },
      });
      return data!.addVectorGraphicAnnotation!.annotation!;
    }
    case AnnotationSubtype.NAME: {
      const size = annotationPdfPointSizeFromText(participant.fullName);
      const { data } = await mutateCallbacks.addTextAnnotationMutateFn({
        ...refetchQueries,
        variables: {
          input: {
            ...mixin,
            location: { ...mixin.location, point },
            text: participant.fullName || "",
            editable: true,
            newSubtype: AnnotationSubtype.NAME,
            size,
            annotationDesignationId,
          },
        },
        optimisticResponse: {
          addTextAnnotation: {
            __typename: "AddTextAnnotationPayload",
            annotation: {
              ...optimisticAnnotation,
              size: { ...size, __typename: "Size" },
              static: false,
              text: participant.fullName || "",
              subtype: AnnotationSubtype.NAME,
              __typename: "TextAnnotation",
            },
            ...optimisticResponse,
          },
        },
        update(cacheProxy, { data }) {
          const { addTextAnnotation } = data!;
          addNewAnnotationToDocumentCache(cacheProxy, {
            meetingId,
            documentId,
            newAnnotation: addTextAnnotation!.annotation!,
            annotationDesignationId,
            errors: addTextAnnotation!.errors,
          });
        },
      });
      return data!.addTextAnnotation!.annotation!;
    }
    case AnnotationSubtype.FREE_TEXT:
    case "text":
    default: {
      const size = annotationPdfPointSizeFromText(
        annotationDesignationId ? TEXT_ANNOTATION_EM : TEXT_ANNOTATION_PLACEHOLDER,
      );
      const annotation = await mutateCallbacks
        .addTextAnnotationMutateFn({
          ...refetchQueries,
          variables: {
            input: {
              ...mixin,
              location: { ...mixin.location, point },
              text: "",
              editable: true,
              newSubtype: AnnotationSubtype.FREE_TEXT,
              size,
              annotationDesignationId,
            },
          },
          optimisticResponse: {
            addTextAnnotation: {
              __typename: "AddTextAnnotationPayload",
              annotation: {
                ...optimisticAnnotation,
                size: { ...size, __typename: "Size" },
                static: false,
                text: "",
                subtype: AnnotationSubtype.FREE_TEXT,
                __typename: "TextAnnotation",
              },
              ...optimisticResponse,
            },
          },
          update(cacheProxy, { data }) {
            const { addTextAnnotation } = data!;
            addNewAnnotationToDocumentCache(cacheProxy, {
              meetingId,
              documentId,
              newAnnotation: addTextAnnotation!.annotation!,
              annotationDesignationId,
              errors: addTextAnnotation!.errors,
            });
          },
        })
        .then(({ data }) => {
          const annotation = data!.addTextAnnotation!.annotation;
          setFocused?.(annotation!.id);
          return annotation;
        });
      return annotation!;
    }
  }
}

export function useAsset(lockSignerName: boolean = false) {
  const handledAssets = useRef<Map<string, Set<VectorGraphicSubtype>>>(new Map());
  const [reuseAssetState, setReuseAssetState] = useState<ReuseAssetState>(null);
  const [assetGeneratorState, setAssetGeneratorState] = useState<AssetGeneratorState>(null);
  const trackSignerEsignatureAgreementMutateFn = useMutation(
    TrackSignerEsignatureAgreementMutation,
  );
  const createSigningsMarkMutateFn = useMutation(CreateSigningsMarkMutation);
  return {
    reuseAssetState,
    assetGeneratorState,
    getAsset: async (
      vectorGraphicType: VectorGraphicSubtype,
      participant: {
        id: string;
        userId: string | null;
        signingAssets: SigningAssets;
      } & (
        | {
            __typename: "WitnessParticipant";
            witnessProfileId: string;
          }
        | {
            __typename: "SignerParticipant";
            signerIdentityId: string;
          }
      ),
      cache: GraphicCache,
      createNew?: boolean,
    ): Promise<{
      font: string | null;
      method: SignatureMethod | null;
      key: string;
      url: string;
      size: { height: number; width: number };
    } | null> => {
      const isSignature = vectorGraphicType === "SIGNATURE";
      const assetKey = isSignature ? ("signatureAsset" as const) : ("initialsAsset" as const);
      const existingPng = participant.signingAssets?.[assetKey]!.png;

      const currentId = participant.id;
      const trackEsignConsent = () =>
        trackSignerEsignatureAgreementMutateFn({
          variables: {
            input: {
              signerId: currentId,
              agreementType: vectorGraphicType as SignerAgreementTypes,
            },
          },
        });

      const handleAsset = () => {
        let handledParticipantAssets = handledAssets.current.get(currentId);
        if (!handledParticipantAssets) {
          handledParticipantAssets = new Set();
          handledAssets.current.set(currentId, handledParticipantAssets);
        }
        handledParticipantAssets.add(vectorGraphicType);
      };

      if (existingPng && !createNew) {
        const handledParticipantAssets = handledAssets.current.get(currentId);
        let reuse: boolean | "cancelled" = Boolean(
          handledParticipantAssets?.has(vectorGraphicType),
        );

        if (!reuse && !lockSignerName) {
          reuse = await new Promise<boolean | "cancelled">((resolve) => {
            setReuseAssetState({
              type: vectorGraphicType,
              onReuseAsset: async () => {
                handleAsset();
                await trackEsignConsent();
                setReuseAssetState(null);
                resolve(true);
              },
              onRecreateAsset: () => {
                setReuseAssetState(null);
                resolve(false);
              },
              onCancelReuseAsset: () => {
                setReuseAssetState(null);
                resolve("cancelled");
              },
            });
          });
        }
        if (reuse === "cancelled") {
          return null;
        } else if (reuse) {
          const size = await getImageSize(existingPng.url!);
          return {
            font: participant.signingAssets?.[assetKey]?.font ?? null,
            method: participant.signingAssets?.[assetKey]?.method ?? null,
            key: existingPng.key!,
            url: existingPng.url!,
            size,
          };
        }
      }

      const newAsset = await new Promise<AssetData>((resolve) => {
        setAssetGeneratorState({
          type: isSignature ? "signature" : "initials",
          onSaveAsset: async (data) => {
            await trackEsignConsent();
            setAssetGeneratorState(null);
            resolve(data);
          },
          onCancelSaveAsset: () => {
            setAssetGeneratorState(null);
            resolve(null);
          },
        });
      });

      if (!newAsset) {
        // create asset cancelled
        return null;
      }

      const { image, method, font } = newAsset;

      const size = constrainSize({
        ...(await getImageSize(image)),
        maxWidth: MAX_SIGNING_ASSET_WIDTH,
      });
      const asset = await fetch(image).then((res) => res.blob());
      const s3Key = await simpleAssetUpload({ asset });
      const input = {
        method,
        font,
        signatureKey: isSignature ? s3Key : undefined,
        initialsKey: isSignature ? undefined : s3Key,
        userId: participant.userId!,
      };
      const { data } = await createSigningsMarkMutateFn({ variables: { input } });
      const newPng = data!.createSigningsMark!.user.signingAssets![assetKey]!.png!;
      handleAsset();
      cacheAnnotationSize({
        cache,
        subtype: isSignature ? AnnotationSubtype.SIGNATURE : AnnotationSubtype.INITIALS,
        userId: participant.userId!,
        size: constrainAsset(size),
      });
      return { method, font, key: newPng.key!, url: newPng.url!, size };
    },
  };
}

export function useAnnotationUpdate({
  meetingId,
  userId,
  cache,
}: {
  meetingId: string;
  userId: string | null | undefined;
  cache: GraphicCache;
}): (...args: Parameters<Exclude<AnnotationProps["onUpdate"], undefined>>) => Promise<unknown> {
  const updateAnnotationTextMutateFn = useMutation(UpdateAnnotationTextMutation);
  const updateAnnotationLocationMutateFn = useMutation(UpdateAnnotationLocationMutation);
  const updateAnnotationSizeMutateFn = useMutation(UpdateAnnotationSizeMutation);
  return useCallback(
    (annotation, evt) => {
      if (!userId) {
        return Promise.resolve();
      }

      switch (evt.type) {
        case "move": {
          const point = { x: evt.newX, y: evt.newY };
          return updateAnnotationLocationMutateFn({
            variables: {
              input: {
                id: annotation.id,
                authorId: userId,
                meetingGid: meetingId,
                location: {
                  point,
                  pageType: annotation.location.pageType,
                  page: annotation.location.page,
                  coordinateSystem: annotation.location.coordinateSystem,
                },
              },
            },
            optimisticResponse: {
              updateAnnotationLocation: {
                errors: null,
                annotation: {
                  ...annotation,
                  location: {
                    ...annotation.location,
                    point: { ...annotation.location.point, ...point },
                    coordinateSystem: annotation.location.coordinateSystem,
                    __typename: "AnnotationLocation",
                  },
                },
                __typename: "UpdateAnnotationLocationPayload",
              },
            },
          });
        }
        case "resize": {
          const size = { width: evt.newWidth, height: evt.newHeight };
          cacheAnnotationSize({ cache, subtype: annotation.subtype!, userId, size });
          return updateAnnotationSizeMutateFn({
            variables: {
              input: {
                id: annotation.id,
                meetingGid: meetingId,
                authorId: userId,
                size,
              },
            },
            optimisticResponse: {
              updateAnnotationSize: {
                __typename: "UpdateAnnotationSizePayload",
                errors: null,
                annotation: {
                  ...annotation,
                  authorId: userId,
                  size: { ...size, __typename: "Size" },
                },
              },
            },
          });
        }
        case "edittext": {
          const width = evt.newWidth;
          const hasTextContent = /\S/.test(evt.newText);
          const textAnnotation = annotation as PspdkitAnnotation_TextAnnotation;
          return updateAnnotationTextMutateFn({
            variables: {
              input: {
                id: annotation.id,
                authorId: userId,
                text: evt.newText,
                width,
                meetingGid: meetingId,
              },
            },
            optimisticResponse: {
              updateAnnotationText: {
                errors: null,
                annotation: {
                  ...annotation,
                  text: evt.newText,
                  size: { ...annotation.size, width, __typename: "Size" },
                },
                __typename: "UpdateAnnotationTextPayload",
                annotationDesignation: textAnnotation.annotationDesignationId
                  ? {
                      __typename: "AnnotationDesignation",
                      id: textAnnotation.annotationDesignationId,
                      fulfilled: hasTextContent,
                      inProgress: !hasTextContent,
                    }
                  : null,
              },
            },
          });
        }
        default:
          return Promise.resolve();
      }
    },
    [
      updateAnnotationSizeMutateFn,
      updateAnnotationTextMutateFn,
      updateAnnotationLocationMutateFn,
      meetingId,
      userId,
    ],
  );
}
