import "./deprecated_index.scss";

import { useState, type ReactNode } from "react";
import classnames from "classnames";
import { defineMessages, useIntl } from "react-intl";

import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { useTxnDetailsRedesign } from "util/feature_detection";
import { OrgTransactionStates } from "graphql_globals";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import FormattedBoolean from "common/core/format/boolean";
import { captureException } from "util/exception";
import { maskPhone } from "common/form/inputs/masked_input";
import { DescriptionListItem } from "common/core/description_list";
import { LinkStyledButton } from "common/core/link/link_styled_button";

import type { ContactForTransactionDetailsContactDetails } from "./index_fragment.graphql";
import { ContactDetailsWrapper } from "./contact_details_wrapper";
import Styles from "./index.module.scss";

type Props = {
  contact: ContactForTransactionDetailsContactDetails;
  transaction: { state: OrgTransactionStates | null };
  automationId?: string;
  onSendNotification: () => Promise<unknown>;
  /** If falsy, will not show remove buttons */
  onRemove?: () => Promise<unknown>;
};

const MESSAGES = defineMessages({
  phoneNumber: {
    id: "49fb439a-a162-483d-a9e3-a30b14ccd568",
    defaultMessage: "Phone Number",
  },
  email: {
    id: "ef535ee6-5f3e-4c1d-af09-801e4608641b",
    defaultMessage: "Email Address",
  },
  shownToSigner: {
    id: "51dd2e8f-9163-46b3-80e1-1dd701fe1569",
    defaultMessage: "Shown to Signer",
  },
  accessToTransaction: {
    id: "be98436a-5040-4f50-a5ad-fe7bbbe55ec1",
    defaultMessage: "Access to Transaction",
  },
  resendAccessEmail: {
    id: "c048783b-d24d-4933-9c85-a22fcab247cc",
    defaultMessage: "Resend access email",
  },
  sendEmailAgain: {
    id: "4c4d5640-7a04-4c2b-8b00-920dde913eaa",
    defaultMessage: "Email sent. Send email again?",
  },
});

function ContactDetailWrapper({ term, definition }: { term: string; definition: ReactNode }) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  return isTxnDetailsRedesign ? (
    <DescriptionListItem term={term} definition={definition} />
  ) : (
    <DeprecatedDetailGridRow title={term}>
      <div className="DetailGrid-data--subheading">{definition}</div>
    </DeprecatedDetailGridRow>
  );
}

function ContactDetails(props: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const { contact, onRemove, transaction } = props;
  const intl = useIntl();
  const [resendEmailModalState, setResendEmailModalState] = useState<"error" | "sending" | "sent">(
    "error",
  );
  const transactionSent =
    transaction.state !== OrgTransactionStates.STARTED &&
    transaction.state !== OrgTransactionStates.SENT_TO_CLOSING_OPS;

  const resendCX = classnames("ContactDetails--contentContainer", {
    disabled: resendEmailModalState === "sending",
  });

  const handleResendAccessEmail = () => {
    if (resendEmailModalState === "sending") {
      return;
    }
    setResendEmailModalState("sending");
    props
      .onSendNotification()
      .then(() => {
        setResendEmailModalState("sent");
      })
      .catch(() => {
        setResendEmailModalState("error");
        captureException(
          new Error("Couldn't resend notification to organization transaction contact"),
          { contact },
        );
      });
  };

  return (
    <ContactDetailsWrapper
      data-automation-id={props.automationId}
      onRemove={onRemove}
      contact={contact}
      transaction={transaction}
    >
      {contact.phoneNumber && (
        <ContactDetailWrapper
          term={intl.formatMessage(MESSAGES.phoneNumber)}
          definition={maskPhone(contact.phoneNumber)}
        />
      )}
      {contact.email && (
        <ContactDetailWrapper
          term={intl.formatMessage(MESSAGES.email)}
          definition={contact.email}
        />
      )}
      <ContactDetailWrapper
        term={intl.formatMessage(MESSAGES.shownToSigner)}
        definition={<FormattedBoolean value={contact.shownToSigner} />}
      />
      <ContactDetailWrapper
        term={intl.formatMessage(MESSAGES.accessToTransaction)}
        definition={
          <div className={resendCX}>
            <FormattedBoolean value={contact.accessToTransaction} />
            {transactionSent && contact.accessToTransaction && (
              <LinkStyledButton
                className={
                  isTxnDetailsRedesign
                    ? Styles.resendEmail
                    : "ContactDetails--contentContainer--text"
                }
                data-automation-id="resend-access-email"
                onClick={handleResendAccessEmail}
              >
                {resendEmailModalState === "sent"
                  ? intl.formatMessage(MESSAGES.sendEmailAgain)
                  : intl.formatMessage(MESSAGES.resendAccessEmail)}
              </LinkStyledButton>
            )}
          </div>
        }
      />
    </ContactDetailsWrapper>
  );
}

export default ContactDetails;
