import { memo, useState, useEffect } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";

import { Language, type RoleScheduleInputType } from "graphql_globals";
import { useForm, type UseFormReturn } from "common/core/form";
import { Label, AutomaticFormRow, SpacedMultipartFormRow } from "common/core/form/layout";
import { useLanguageOptions } from "common/language";
import {
  isAriaInvalid,
  FieldErrorMessage,
  defaultRequiredMessage,
  emailPatternValidation,
  atLeastOneRequiredMessage,
} from "common/core/form/error";
import { TextInput, EmailTextInput } from "common/core/form/text";
import { Checkbox, CheckboxLabel, CheckboxGroup } from "common/core/form/option";
import BinaryToggle from "common/form/inputs/binary_toggle";
import { Select } from "common/core/form/select";
import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { userFullName } from "util/user";
import AlertMessage from "common/core/alert_message";
import { usePermissions } from "common/core/current_user_role";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";

import Styles from "./index.module.scss";
import { type MemberManagementOrganization_assignableRoles as AssignableRole } from "../organization_fragment.graphql";
import { OptionFormRow } from "../util";

export type Member = {
  id: string;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  roles?: null | AssignableRole[];
  roleSchedules?: RoleScheduleInputType[] | null;
  notaryProfile: null | {
    id: string;
    languages: Language[];
    usState: { id: string };
  };
  organizationId?: string;
  userId?: string;
};
type InitialMemberState = Readonly<{
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
}>;
export type AddEditModalFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  roleSchedules: string | null;
  isNotary: boolean;
  notaryState: string;
  notaryLangs: (Language | false)[];
  reason: string;
};
export type InnerProps = {
  /** if this exists, modal is in edit mode */
  editingMember?: Member;
  /** Non-empty list means notary selection is allowed */
  possibleNotaryStates: { label: string; value: string }[];
  canDisableOwner: boolean;
  requireReason?: boolean;
  initialMember?: InitialMemberState;
  assignableRoles: AssignableRole[];
};
type InnerPropsWithValidation = InnerProps & {
  validationError: string | null;
};
type InnerPropsWithForm = InnerPropsWithValidation & {
  form: UseFormReturn<AddEditModalFormValues>;
};
type AddEditModalProps = InnerPropsWithValidation & {
  onSave: (
    formValues: AddEditModalFormValues,
    editingMember?: Member,
    invitingDirectly?: boolean,
  ) => Promise<unknown>;
  onCancel: () => void;
};

const MESSAGES = defineMessages({
  firstName: {
    id: "60ce2107-4b00-4c79-8bda-15496a4d37ac",
    defaultMessage: "First Name",
  },
  lastName: {
    id: "18804184-faae-465a-be25-8ab743e4786e",
    defaultMessage: "Last Name",
  },
  reason: {
    id: "373cc650-6531-4aef-a0eb-caf3bdb583af",
    defaultMessage: "Reason",
  },
  reasonDescription: {
    id: "0bf0a645-39e8-4704-b5e4-ba68945ad0c5",
    defaultMessage:
      "Please enter the reason why you're inviting a new member to this organization.",
  },
});

function isValidLangs(langs: AddEditModalFormValues["notaryLangs"]): boolean {
  return langs.some(Boolean);
}

export function AddEditModalInner({
  editingMember,
  possibleNotaryStates,
  canDisableOwner,
  initialMember,
  requireReason = false,
  assignableRoles,
  validationError,
  form,
}: InnerPropsWithForm) {
  if (initialMember && editingMember) {
    throw new Error("Did not expect both initialMember and editingMember to not be null!");
  }
  const { hasPermissionFor } = usePermissions();
  const isAdminPortal = CURRENT_PORTAL === Apps.ADMIN;
  const isEditing = Boolean(editingMember);
  const isEditingPreviousNotary = Boolean(editingMember?.notaryProfile);
  const intl = useIntl();
  const originalLanguageOptions = useLanguageOptions();
  // Only show en and sp for now
  const languageOptions = originalLanguageOptions.filter(
    ({ value }) => value === Language.EN || value === Language.SP,
  );
  const [roleSchedulesFormValue, isNotaryFormValue, notaryLangsFormValue] = form.watch([
    "roleSchedules",
    "isNotary",
    "notaryLangs",
  ]);

  const notarySupported =
    Boolean(possibleNotaryStates.length) &&
    assignableRoles.find((role) => role.id === roleSchedulesFormValue)?.validNotaryRole;

  useEffect(() => {
    if (!notarySupported) {
      form.resetField("isNotary");
      form.resetField("notaryState");
      form.resetField("notaryLangs");
    }
  }, [notarySupported]);

  const getRbacRoleSubtext = () => {
    if (!canDisableOwner && editingMember?.roles?.some((role) => role.displayName === "Owner")) {
      return (
        <FormattedMessage
          id="6dd8f492-63b1-4499-aa67-d648f3e4f0bd"
          defaultMessage="You cannot change the role of an owner."
        />
      );
    }
    switch (roleSchedulesFormValue) {
      case assignableRoles.find((role) => role.displayName === "Team Member")?.id:
        return (
          <FormattedMessage
            id="590d65f2-a489-4e37-8658-c7cc428c1ba4"
            defaultMessage="Team members can send and manage all transactions within an organization."
          />
        );
      case assignableRoles.find((role) => role.displayName === "Admin")?.id:
        return (
          <FormattedMessage
            id="01d73952-3d6f-4236-b82c-a471f4505e28"
            defaultMessage="Admins can place orders, manage all transactions, invite new team members, and edit any company settings."
          />
        );
      case assignableRoles.find((role) => role.displayName === "Owner")?.id:
        return (
          <FormattedMessage
            id="c8f56290-2fee-4aa0-8899-27b46aa5bfca"
            defaultMessage="Owners can place orders, manage all transactions, invite new team members, and edit any company settings."
          />
        );
      default:
        return null;
    }
  };

  const getRbacRoleValues = () => {
    let filteredRoles = assignableRoles;

    // Only show roles that a notary can have
    if (isEditingPreviousNotary) {
      filteredRoles = filteredRoles.filter((role: AssignableRole) => role.validNotaryRole);
    }

    // Handle keystone role values
    if (isAdminPortal) {
      // Return all roles for create and edit if can edit owner
      if (hasPermissionFor("editOwner")) {
        return formatRoles(filteredRoles);
      }
      // else remove owner role

      return formatRoles(filteredRoles.filter((role: AssignableRole) => role.name !== "owner"));
    }
    // Handle other portals

    // Only keystone admins can create notarize staff
    filteredRoles = filteredRoles.filter((role: AssignableRole) => role.name !== "notarize_staff");
    // if you can't disable the owner and you are editing them, only show the owner role - prevents role reassignment
    if (!canDisableOwner && editingMember?.roles?.some((role) => role.name === "owner")) {
      filteredRoles = filteredRoles.filter((role: AssignableRole) => role.name === "owner");
    }
    // if not editing remove Owner role
    else if (!isEditing) {
      filteredRoles = filteredRoles.filter((role: AssignableRole) => role.name !== "owner");
    }

    return formatRoles(filteredRoles);
  };

  function formatRoles(roles: AssignableRole[]) {
    return roles.map((role: AssignableRole) => ({
      value: role.id,
      label: role.displayName,
    }));
  }

  useEffect(() => {
    if (validationError) {
      form.setError("email", { type: "custom", message: " " });
    }
  }, [validationError]);

  return (
    <>
      {isAdminPortal && !isEditing && (
        <FormattedMessage
          id="b5fa0713-3f14-4ec9-b27e-814d61647ce9"
          defaultMessage="The organization's owner and any admin will be prompted to approve."
        />
      )}
      {!isEditing && (
        <>
          <Label className={Styles.nameInputLabel}>
            <FormattedMessage id="a33ae7bf-95ea-4fd3-9d6e-ab5c809d6ce1" defaultMessage="Name" />
          </Label>
          <SpacedMultipartFormRow className={Styles.memberNameInput}>
            <AutomaticFormRow<AddEditModalFormValues>
              form={form}
              name="firstName"
              registerOptions={{ required: defaultRequiredMessage(intl) }}
              as={TextInput}
              placeholder="First Name"
              aria-label={intl.formatMessage(MESSAGES.firstName)}
            />
            <AutomaticFormRow<AddEditModalFormValues>
              form={form}
              name="lastName"
              registerOptions={{ required: defaultRequiredMessage(intl) }}
              as={TextInput}
              placeholder="Last Name"
              aria-label={intl.formatMessage(MESSAGES.lastName)}
            />
          </SpacedMultipartFormRow>

          <AutomaticFormRow<AddEditModalFormValues>
            form={form}
            name="email"
            label={
              <FormattedMessage id="63ab90af-d812-4173-a682-f98018a365df" defaultMessage="Email" />
            }
            registerOptions={{
              required: defaultRequiredMessage(intl),
              pattern: emailPatternValidation(intl),
            }}
            as={EmailTextInput}
            placeholder="Enter Email Address"
            fullWidth
          />
        </>
      )}

      <OptionFormRow
        styles={{ prefix: Styles.prefix, optionRowInput: Styles.optionRowInput }}
        htmlFor="roleSchedules"
        prefix={
          <>
            <FormattedMessage
              id="11c1e43d-e86c-4fd5-b3aa-c8749faa771f"
              defaultMessage="Choose which level of access this person should have."
            />
            <br />
            {getRbacRoleSubtext()}
          </>
        }
        label={<FormattedMessage id="e88b5afc-f306-460e-9706-04b320c6c764" defaultMessage="Role" />}
      >
        <Select
          id="roleSchedules"
          items={getRbacRoleValues()}
          aria-invalid={isAriaInvalid(form.formState.errors.roleSchedules)}
          {...form.register("roleSchedules", { required: defaultRequiredMessage(intl) })}
        />
      </OptionFormRow>

      {requireReason && (
        <AutomaticFormRow<AddEditModalFormValues>
          form={form}
          name="reason"
          registerOptions={{ required: defaultRequiredMessage(intl) }}
          as={TextInput}
          label={
            <FormattedMessage id="de37cc3b-df10-4d7e-9aeb-69cc1b54dc2c" defaultMessage="Reason" />
          }
          placeholder={intl.formatMessage(MESSAGES.reason)}
          fullWidth
          aria-label={intl.formatMessage(MESSAGES.reason)}
          helperText={{ text: intl.formatMessage(MESSAGES.reasonDescription), placement: "above" }}
        />
      )}

      {notarySupported && !isAdminPortal && !isEditingPreviousNotary && (
        <BinaryToggle
          value={isNotaryFormValue}
          automationId="isNotary"
          onChange={() => {
            form.setValue("isNotary", !isNotaryFormValue, { shouldDirty: true });
          }}
          label={
            <FormattedMessage
              id="f1a57525-eaed-4178-894e-07706262aff0"
              defaultMessage="Will this person notarize documents?"
            />
          }
          subLabel={
            <FormattedMessage
              id="c456f6a3-e20a-4617-94cf-9cf0df693e93"
              defaultMessage="Please note that all new notaries will need to go through a set-up process with Proof to prove that they are RON certified."
            />
          }
        />
      )}

      {notarySupported && !isAdminPortal && isNotaryFormValue && (
        <>
          {!isEditingPreviousNotary && (
            <AutomaticFormRow<AddEditModalFormValues>
              label={
                <FormattedMessage
                  id="4b52024b-93a7-42d4-ae23-4d216d288ce6"
                  defaultMessage="What state is this notary commissioned for?"
                />
              }
              form={form}
              name="notaryState"
              registerOptions={{ required: defaultRequiredMessage(intl) }}
              fullWidth
            >
              <Select
                items={possibleNotaryStates}
                aria-invalid={isAriaInvalid(form.formState.errors.notaryState)}
              />
            </AutomaticFormRow>
          )}

          <CheckboxGroup
            label={
              <FormattedMessage
                id="d761ba1e-9e2a-478e-8275-3f27e5a576da"
                defaultMessage="Which languages does this notary speak fluently?"
              />
            }
            groupError={
              <FieldErrorMessage
                inputName="notaryLangs"
                className={Styles.langError}
                message={
                  form.formState.isSubmitted && !isValidLangs(notaryLangsFormValue)
                    ? atLeastOneRequiredMessage(intl)
                    : null
                }
              />
            }
          >
            {languageOptions.map(({ value, label }, index) => (
              <CheckboxLabel
                key={value}
                checkbox={
                  <Checkbox
                    aria-invalid="false"
                    value={value}
                    {...form.register(`notaryLangs.${index}` as const)}
                  />
                }
                label={label}
              />
            ))}
          </CheckboxGroup>
        </>
      )}
      {isEditingPreviousNotary && (
        <FormattedMessage
          id="d754203c-8cda-4fd8-9af8-9e9bd83365a4"
          defaultMessage="You cannot remove notary accounts because notaries must maintain access to their notary journal. To disable this person from notarizing documents, go to the Notary page and turn off accepting calls."
        />
      )}
      {validationError && (
        <AlertMessage>
          <span>
            The invitation cannot be sent because the email address is already associated with a
            user in{" "}
            {validationError === "already-member-in-org-error"
              ? "this organization"
              : "a different organization"}
            .
          </span>
        </AlertMessage>
      )}
    </>
  );
}

function AddEditModal({
  editingMember,
  onSave,
  onCancel,
  possibleNotaryStates,
  canDisableOwner,
  initialMember,
  requireReason = false,
  assignableRoles,
  validationError,
}: AddEditModalProps) {
  if (initialMember && editingMember) {
    throw new Error("Did not expect both initialMember and editingMember to not be null!");
  }
  const { hasPermissionFor } = usePermissions();
  const canInviteDirectly = hasPermissionFor("createMemberDirectly");
  const isAdminPortal = CURRENT_PORTAL === Apps.ADMIN;
  const isEditing = Boolean(editingMember);
  const notarySupported = Boolean(possibleNotaryStates.length);
  const notaryProfile = notarySupported ? editingMember?.notaryProfile : null;
  const baseLangs = new Set(notaryProfile?.languages || [Language.EN]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDirectInvite, setIsLoadingDirectInvite] = useState(false);
  const originalLanguageOptions = useLanguageOptions();
  // Only show en and sp for now
  const languageOptions = originalLanguageOptions.filter(
    ({ value }) => value === Language.EN || value === Language.SP,
  );

  const form = useForm<AddEditModalFormValues>({
    defaultValues: {
      firstName: editingMember?.firstName || initialMember?.firstName || "",
      lastName: editingMember?.lastName || initialMember?.lastName || "",
      email: editingMember?.email || initialMember?.email || "",
      roleSchedules:
        (editingMember?.roles?.[0].id as string) ||
        assignableRoles.find((role) => role.displayName === "Team Member")?.id,
      reason: "",
      isNotary: Boolean(notaryProfile),
      notaryState: notaryProfile?.usState.id || "",
      notaryLangs: languageOptions.map(({ value }) => baseLangs.has(value) && value),
    },
  });

  const [notaryLangsFormValue] = form.watch(["notaryLangs"]);

  return (
    <WorkflowModal
      large
      className={Styles.addEditModal}
      title={
        isEditing ? (
          <FormattedMessage
            id="79e8afbd-f040-44ac-96ba-ece06939d237"
            defaultMessage="Edit Permissions for {userName}"
            values={{ userName: userFullName(editingMember) }}
          />
        ) : isAdminPortal ? (
          <FormattedMessage
            id="b5fa0713-3f14-4ec9-b27e-814d61647ce9"
            defaultMessage="Invite a Team Member"
          />
        ) : (
          <FormattedMessage
            id="f233edbb-a2e5-42bd-a69f-1334f19bb871"
            defaultMessage="Invite a New Member"
          />
        )
      }
      buttons={[
        <Button
          key="cancel"
          buttonColor="dark"
          variant="tertiary"
          disabled={isLoading}
          onClick={onCancel}
        >
          <FormattedMessage id="8ce6a854-52d9-49a8-bb01-5962150654b1" defaultMessage="Cancel" />
        </Button>,
        <Button
          key="confirm"
          buttonColor="action"
          variant="primary"
          isLoading={isLoading}
          automationId="add-edit-team-member-modal-primary-btn"
          onClick={form.handleSubmit((formValues: AddEditModalFormValues) => {
            if (formValues.isNotary && !isAdminPortal && !isValidLangs(notaryLangsFormValue)) {
              return;
            }
            setIsLoading(true);
            onSave(formValues, editingMember).catch(() => {
              setIsLoading(false);
            });
          })}
        >
          {isEditing ? (
            <FormattedMessage
              id="581f5001-27fe-45a2-ab55-bd4c5fa455c6"
              defaultMessage="Save Changes"
            />
          ) : isAdminPortal ? (
            <FormattedMessage
              id="9e185784-919b-404c-bb50-cdf0cbf751ef"
              defaultMessage="Request approval"
            />
          ) : (
            <FormattedMessage
              id="9e185784-919b-404c-bb50-cdf0cbf751ef"
              defaultMessage="Send Invite"
            />
          )}
        </Button>,
        canInviteDirectly && !editingMember && (
          <Button
            key="confirmDirect"
            buttonColor="action"
            variant="primary"
            isLoading={isLoadingDirectInvite}
            onClick={form.handleSubmit((formValues: AddEditModalFormValues) => {
              setIsLoadingDirectInvite(true);
              onSave(formValues, editingMember, true).catch(() => {
                setIsLoadingDirectInvite(false);
              });
            })}
          >
            <FormattedMessage
              id="359c9195-e045-4994-808b-d1a4c8989619"
              defaultMessage="Invite directly"
            />
          </Button>
        ),
      ]}
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      footerSeparator={false}
    >
      <AddEditModalInner
        editingMember={editingMember}
        possibleNotaryStates={possibleNotaryStates}
        canDisableOwner={canDisableOwner}
        initialMember={initialMember}
        requireReason={requireReason}
        assignableRoles={assignableRoles}
        validationError={validationError}
        form={form}
      />
    </WorkflowModal>
  );
}

export default memo(AddEditModal);
