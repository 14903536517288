import type { ComponentProps } from "react";

import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { useTxnDetailsRedesign } from "util/feature_detection";
import { Hr } from "common/core/horizontal_rule";

import PointsOfContact from "./points_of_contact";
import Signer from "./signer";

export function RecipientsContainer({
  bundle,
  meetingIds,
  refetch,
  organization,
  transaction,
  canEdit,
}: ComponentProps<typeof Signer> & ComponentProps<typeof PointsOfContact>) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const isBusinessPortal = CURRENT_PORTAL === "business";
  const hasContacts = transaction.contacts.length > 0;

  return isTxnDetailsRedesign ? (
    <>
      <Signer bundle={bundle} meetingIds={meetingIds} refetch={refetch} />
      {!isBusinessPortal && hasContacts && (
        <>
          <Hr />
          <PointsOfContact
            organization={organization}
            transaction={transaction}
            canEdit={canEdit}
            refetch={refetch}
          />
        </>
      )}
    </>
  ) : (
    <Signer bundle={bundle} meetingIds={meetingIds} refetch={refetch} />
  );
}
