import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import { useEffect, useState, type ReactNode } from "react";

import { Heading, Substyle } from "common/core/typography";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import { useMobileScreenClass } from "common/core/responsive";
import Link from "common/core/link";
import SmallLoadingIndicator from "common/core/loading_indicator/small";
import { useQuery } from "util/graphql";
import {
  SelectedFiltersLabel,
  useDateConstraint,
  type DateConstraint as DashboardDateConstraint,
} from "common/dashboard/filter_dropdown/common";
import { FilterDropdown, FilterOption } from "common/dashboard/filter_dropdown";
import { OrganizationTransactionDetailedStatus } from "graphql_globals";

import GetStartedHeaderQuery, { type GetStartedHeader } from "./index.query.graphql";
import Styles from "./index.module.scss";

const BUSINESS_DETAILED_STATUSES_IN_PROGRESS = [
  OrganizationTransactionDetailedStatus.SENT_TO_SIGNER,
  OrganizationTransactionDetailedStatus.ACTIVE,
  OrganizationTransactionDetailedStatus.VIEWED,
  OrganizationTransactionDetailedStatus.PARTIALLY_COMPLETE,
  OrganizationTransactionDetailedStatus.ATTEMPTED,
  OrganizationTransactionDetailedStatus.AWAITING_PAYMENT,
];

const TITLE_DETAILED_STATUSES_IN_PROGRESS = [
  ...BUSINESS_DETAILED_STATUSES_IN_PROGRESS,
  OrganizationTransactionDetailedStatus.CONVERTED_TO_WET_SIGN,
  OrganizationTransactionDetailedStatus.ON_HOLD,
  OrganizationTransactionDetailedStatus.ORDER_PLACED,
];

const BUSINESS_DETAILED_STATUSES_COMPLETE = [OrganizationTransactionDetailedStatus.COMPLETE];

const TITLE_DETAILED_STATUSES_COMPLETE = [
  ...BUSINESS_DETAILED_STATUSES_COMPLETE,
  OrganizationTransactionDetailedStatus.ESIGN_COMPLETE,
  OrganizationTransactionDetailedStatus.WET_SIGN_COMPLETE,
  OrganizationTransactionDetailedStatus.COMPLETE_WITH_REJECTIONS,
];

type DateConstraint = Extract<DashboardDateConstraint, "previous7days" | "thismonth"> | null;

const DateConstraintLabels = {
  previous7days: (
    <SelectedFiltersLabel
      label={
        <FormattedMessage
          id="eb08f47e-03a0-4597-a611-7ae0dfac3068"
          defaultMessage="Previous 7 days"
        />
      }
    />
  ),
  thismonth: (
    <SelectedFiltersLabel
      label={
        <FormattedMessage id="346aea73-7642-4cf0-a6b4-f8d1e211c25a" defaultMessage="This month" />
      }
    />
  ),
  alltime: (
    <SelectedFiltersLabel
      label={
        <FormattedMessage id="439bfd27-81d0-493a-939d-747191db6cca" defaultMessage="All time" />
      }
    />
  ),
};

function Count({ count, label, loading }: { count: number; label: ReactNode; loading: boolean }) {
  const isMobile = useMobileScreenClass();
  return (
    <div className={Styles.countWrapper}>
      <Substyle textStyle={isMobile ? "headingTwo" : "headingOne"}>
        {loading ? <SmallLoadingIndicator className={Styles.loading} color="gray" /> : count}
      </Substyle>
      <Substyle size={isMobile ? "small" : undefined}>{label}</Substyle>
    </div>
  );
}

function getDefinedOrg(organization: GetStartedHeader["organization"] | undefined) {
  if (organization?.__typename === "Organization") {
    return organization;
  }
}

function useHeaderQuery(
  organizationId: string,
  dateConstraints: {
    createdDateStart: string | null;
    createdDateEnd: string | null;
    sentDateStart: string | null;
    sentDateEnd: string | null;
    completedDateStart: string | null;
    completedDateEnd: string | null;
  },
  isTitle: boolean = false,
) {
  const [hasTransactions, setHasTransactions] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  let draftCount = 0;
  let inProgressCount = 0;
  let completedCount = 0;

  const { data, loading } = useQuery(GetStartedHeaderQuery, {
    variables: {
      organizationId,
      ...dateConstraints,
      detailedStatusesInProgress: isTitle
        ? TITLE_DETAILED_STATUSES_IN_PROGRESS
        : BUSINESS_DETAILED_STATUSES_IN_PROGRESS,
      detailedStatusesCompleted: isTitle
        ? TITLE_DETAILED_STATUSES_COMPLETE
        : BUSINESS_DETAILED_STATUSES_COMPLETE,
    },
  });

  const organization = getDefinedOrg(data?.organization);

  useEffect(() => {
    if (data && !initialLoadComplete) {
      setInitialLoadComplete(true);
    }

    if (organization && !hasTransactions) {
      // We only want to check if user has txns the first time they query. When filtering, this will prevent entire header from re-rendering, due to checks on hasTransactions.
      setHasTransactions(organization.allTransactionsCount.totalCount > 0);
    }
  }, [data]);

  if (loading || !organization) {
    return {
      draftCount,
      inProgressCount,
      completedCount,
      hasTransactions,
      loadingState: { initialLoadComplete, loading },
    };
  }

  draftCount = organization.draftTransactionsCount.totalCount;
  inProgressCount = organization.inProgressTransactionsCount.totalCount;
  completedCount = organization.completedTransactionsCount.totalCount;

  return {
    draftCount,
    inProgressCount,
    completedCount,
    hasTransactions,
    loadingState: { initialLoadComplete, loading },
  };
}

function DateFilter({
  loading,
  selectedDateConstraint,
  setSelectedDateConstraint,
}: {
  loading: boolean;
  selectedDateConstraint: DateConstraint;
  setSelectedDateConstraint: (date: DateConstraint) => void;
}) {
  const options = ["previous7days" as const, "thismonth" as const, null];
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FilterDropdown
      className={Styles.dateFilter}
      disabled={loading}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      // This dropdown will always have a selection with "Previous 7 days" as the default
      // "All time" corresponds with selectedDateConstraint === null, so unselectedLabel must be set to "All time"
      hasSelection
      unselectedLabel={DateConstraintLabels.alltime}
      label={DateConstraintLabels[selectedDateConstraint ?? "alltime"]}
      data-automation-id="get-started-date-filter"
    >
      {options.map((date) => {
        return (
          <FilterOption
            key={date}
            data-pendo-id={`get-started-filter-option-${date ?? "alltime"}`}
            data-automation-id={`get-started-filter-option-${date ?? "alltime"}`}
            selected={selectedDateConstraint === date}
            onChange={() => {
              if (selectedDateConstraint !== date) {
                setSelectedDateConstraint(date);
              }
              setIsOpen(false);
            }}
          >
            {DateConstraintLabels[date ?? "alltime"]}
          </FilterOption>
        );
      })}
    </FilterDropdown>
  );
}

function WelcomeHeading({
  hasTransactions,
  isTitle,
}: {
  hasTransactions: boolean;
  isTitle?: boolean;
}) {
  const isMobile = useMobileScreenClass();
  return (
    <Heading level="h1" textStyle={isMobile ? "headingFour" : "headingTwo"}>
      <FormattedMessage
        id="9aab3858-22bb-4acb-a77b-cd9926eecb90"
        defaultMessage="Welcome {hasTransactions, select, true {back} other {}} to {isMobile, select, true {} other {{br}}} <b>Proof for {isTitle, select, true {Title} other {Business}}</b>"
        values={{
          hasTransactions,
          b: (text) => <b>{text}</b>,
          br: <br />,
          isMobile,
          isTitle,
        }}
      />
    </Heading>
  );
}

function SeeAllTransactionsLink() {
  return (
    <Link
      data-pendo-id="get-started-see-all-transactions"
      to="/transaction/records"
      className={Styles.dashboardLink}
    >
      <FormattedMessage
        id="f7afb98c-d53c-43ad-ae13-f52652e2082a"
        defaultMessage="See all transactions"
      />
    </Link>
  );
}

function SendATransactionLink({ isTitle }: { isTitle?: boolean }) {
  const isMobile = useMobileScreenClass();
  return (
    <ButtonStyledLink
      data-pendo-id="get-started-send-transaction"
      fullwidth={isMobile}
      buttonColor="action"
      variant="secondary"
      to={`/transaction/${isTitle ? "setup/v2" : "new"}`}
    >
      <FormattedMessage
        id="f31a38c6-4d60-4f99-af74-50a852d54578"
        defaultMessage="Send a transaction"
      />
    </ButtonStyledLink>
  );
}

function TransactionSnapshot({
  loading,
  draftCount,
  inProgressCount,
  completedCount,
  selectedDateConstraint,
  setSelectedDateConstraint,
}: {
  loading: boolean;
  draftCount: number;
  inProgressCount: number;
  completedCount: number;
  selectedDateConstraint: DateConstraint;
  setSelectedDateConstraint: (date: DateConstraint) => void;
}) {
  return (
    <div className={Styles.snapshotWrapper}>
      <div className={Styles.filterWrapper}>
        <Substyle textStyle="allCapsLabel" size="small">
          <FormattedMessage
            id="706bad91-d01c-4496-8e2f-8cce9c515e23"
            defaultMessage="Transaction snapshot"
          />
        </Substyle>

        <DateFilter
          loading={loading}
          selectedDateConstraint={selectedDateConstraint}
          setSelectedDateConstraint={setSelectedDateConstraint}
        />
      </div>

      <div className={Styles.grid}>
        <Count
          loading={loading}
          count={draftCount}
          label={
            <FormattedMessage id="46909aa9-a096-4c21-8d97-f44a5a4e313a" defaultMessage="drafts" />
          }
        />
        <Count
          loading={loading}
          count={inProgressCount}
          label={
            <FormattedMessage
              id="83297d35-20f9-47c2-b956-6c9946653695"
              defaultMessage="in progress"
            />
          }
        />
        <Count
          loading={loading}
          count={completedCount}
          label={
            <FormattedMessage
              id="e173bad0-d9ef-4ef3-89ad-edcc599cbe08"
              defaultMessage="completed"
            />
          }
        />
      </div>
    </div>
  );
}

export function Header({ organizationId, isTitle }: { organizationId: string; isTitle?: boolean }) {
  const isMobile = useMobileScreenClass();

  const [selectedDateConstraint, setSelectedDateConstraint] =
    useState<DateConstraint>("previous7days");

  const { startDate, endDate } = useDateConstraint(selectedDateConstraint);

  const { loadingState, draftCount, inProgressCount, completedCount, hasTransactions } =
    useHeaderQuery(
      organizationId,
      {
        createdDateStart: startDate,
        createdDateEnd: endDate,
        sentDateStart: startDate,
        sentDateEnd: endDate,
        completedDateStart: startDate,
        completedDateEnd: endDate,
      },
      isTitle,
    );
  const { initialLoadComplete, loading } = loadingState;

  return (
    <div className={Styles.header}>
      <div className={classnames(Styles.inner, isMobile && Styles.mobileInner)}>
        {initialLoadComplete ? (
          <>
            <div className={Styles.headingWrapper}>
              <WelcomeHeading hasTransactions={hasTransactions} isTitle={isTitle} />
              {hasTransactions && <SeeAllTransactionsLink />}
            </div>
            {hasTransactions ? (
              <TransactionSnapshot
                loading={loading}
                draftCount={draftCount}
                inProgressCount={inProgressCount}
                completedCount={completedCount}
                selectedDateConstraint={selectedDateConstraint}
                setSelectedDateConstraint={setSelectedDateConstraint}
              />
            ) : (
              <SendATransactionLink isTitle={isTitle} />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}
