import { useState } from "react";

import SigningTimeRescheduler from "common/mortgage/transactions/signing_time_restrictions/signing_time_rescheduler";
import TransactionExpiry from "common/details/meeting/notary_details/items/transaction_expiry";
import TransactionActivation from "common/details/meeting/notary_details/items/transaction_activation";
import NotaryMeetingTime from "common/details/meeting/notary_details/items/notary_meeting_time";
import SigningScheduleType from "common/details/meeting/notary_details/items/signing_schedule_type";
import { useQuery } from "util/graphql";
import LoadingIndicator from "common/core/loading_indicator";

import Query from "./index.query.graphql";

function SigningTimeScheduleContainer({
  transactionId,
  isReschedulerEnabled,
}: {
  transactionId: string;
  isReschedulerEnabled: boolean;
}) {
  const [isReschedulerOpen, toggleRescheduler] = useState(false);
  const { data, loading } = useQuery(Query, {
    variables: {
      transactionId,
    },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const transaction = data!.organizationTransaction;
  const viewer = data!.viewer;
  const organization = viewer.user!.organization!;

  if (transaction?.__typename !== "OrganizationTransaction") {
    throw new Error(`Expected organization transaction, got ${transaction?.__typename}.`);
  }

  if (!transaction.activationTime && !transaction.expiry) {
    // currently don't support adding expiry/activation if it doesn't already exist (no reason, just haven't built it out)
    // if we want to support it, would need to make it so backend can take in null as signing_schedule
    return null;
  }

  const handleReschedulerOpen = isReschedulerEnabled ? () => toggleRescheduler(true) : undefined;
  const usersOrgCreatedTransaction =
    organization.id === transaction.organization.id ||
    Boolean(organization.subsidiaryOrganizations.find((o) => o.id === transaction.organization.id));
  return (
    <>
      {isReschedulerOpen ? (
        <SigningTimeRescheduler
          transaction={transaction}
          onReschedulerClose={() => toggleRescheduler(false)}
          viewer={viewer}
          usersOrgCreatedTransaction={usersOrgCreatedTransaction}
        />
      ) : (
        <>
          <SigningScheduleType
            transaction={transaction}
            onReschedulerOpen={handleReschedulerOpen}
          />
          <TransactionActivation transaction={transaction} />
          <TransactionExpiry transaction={transaction} />
          <NotaryMeetingTime transaction={transaction} />
        </>
      )}
    </>
  );
}

export default SigningTimeScheduleContainer;
