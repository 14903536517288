import { FormattedMessage } from "react-intl";

import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { DescriptionListItem } from "common/core/description_list";
import { DeprecatedDetailGridSection } from "common/details/grid/section";
import { DeprecatedDetailGridRow } from "common/details/grid/row";

export function ProofRequirements({
  ial2Proof,
  smsProof,
  kbaProof,
}: {
  ial2Proof: boolean | undefined;
  smsProof: boolean | undefined;
  kbaProof: boolean | undefined;
}) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const verificationMethods = [];
  if (ial2Proof) {
    verificationMethods.push(
      <div>
        <FormattedMessage
          id="24df835f-5fca-463c-8c2e-40dc2d28c60a"
          defaultMessage="IAL-2 compliant identity verification"
        />
      </div>,
    );
  }
  if (smsProof) {
    verificationMethods.push(
      <div>
        <FormattedMessage
          id="3ec75d63-fd4a-41f2-8a48-8dc53bb04039"
          defaultMessage="SMS authentication"
        />
      </div>,
    );
  }
  if (kbaProof) {
    verificationMethods.push(
      <div>
        <FormattedMessage
          id="2b0fddd3-6ea5-44d2-be82-38548c7a07ea"
          defaultMessage="Knowledge-based authentication (KBA)"
        />
      </div>,
    );
  }
  const requirementsLabel = (
    <FormattedMessage
      id="cf127065-db35-426b-816b-cda139400225"
      defaultMessage="Identity proofing"
    />
  );

  return isTxnDetailsRedesign ? (
    <DescriptionListItem term={requirementsLabel} definition={verificationMethods} />
  ) : (
    <DeprecatedDetailGridSection>
      <DeprecatedDetailGridRow title={requirementsLabel}>
        {verificationMethods}
      </DeprecatedDetailGridRow>
    </DeprecatedDetailGridSection>
  );
}
