import { type ReactNode, useState } from "react";
import { FormattedMessage } from "react-intl";

import { SEGMENT_EVENTS } from "constants/analytics";
import { segmentTrack } from "util/segment";
import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { useTxnDetailsRedesign } from "util/feature_detection";
import {
  OrganizationTransactionDetailedStatus,
  UserRole,
  UserAction,
  MortgageTransactionType,
  OrganizationTransactionCreationSource,
  OrganizationTypeEnum,
} from "graphql_globals";
import { userFullName } from "util/user";
import { useFeatureFlag } from "common/feature_gating";
import { ORGANIZATION_BRAND_NAME } from "constants/feature_gates";
import AlertMessage from "common/core/alert_message";
import Link from "common/core/link";
import { usePermissions } from "common/core/current_user_role";
import AddWetSignDocumentToOrganizationTransactionMutation from "common/details/summary/add_wet_sign_document_to_organization_transaction_mutation.graphql";
import { useMutation } from "util/graphql";
import ActionButton from "common/core/action_button";

import { type OrganizationTransactionDetailsHeader as OrganizationTransaction } from "./index_fragment.graphql";

function AwaitingPaymentAlert() {
  return (
    <AlertMessage banner kind="info" centerText>
      <FormattedMessage
        id="7b4fd21e-2e11-4e2c-af71-3763ee138782"
        defaultMessage="This transaction is awaiting payment. Documents can be accessed once the signer completes payment."
      />
    </AlertMessage>
  );
}

function PaymentFailedAlert() {
  return (
    <AlertMessage banner kind="warning" centerText>
      <FormattedMessage
        id="783c03dd-2fe8-40c5-8586-f9b487895c84"
        defaultMessage="Payment failed. Documents can be accessed once payment is complete. <link>Update payment details</link>"
        values={{
          link: (text: ReactNode) => (
            <Link href="/settings/billing/payment-settings" openInCurrentTab={false}>
              {text}
            </Link>
          ),
        }}
      />
    </AlertMessage>
  );
}

function ReadOnlyAlert({ brandName }: { brandName: string | null | undefined }) {
  return (
    <AlertMessage banner kind="info" centerText>
      <FormattedMessage
        id="70c7e7a3-87f7-4c93-a512-82d494691595"
        defaultMessage="This draft is read-only because {orgName} is still working on finalizing this closing package. You will receive a notification email when they are ready for the title documents."
        values={{ orgName: brandName }}
      />
    </AlertMessage>
  );
}

/** Return the creation source in a human readable format */
function getCreationSource(creationSource: OrganizationTransactionCreationSource) {
  switch (creationSource) {
    case OrganizationTransactionCreationSource.DOTLOOP:
      return "Dotloop";
    case OrganizationTransactionCreationSource.DROPBOX:
      return "Dropbox";
    case OrganizationTransactionCreationSource.ENCOMPASS:
      return "Encompass";
    case OrganizationTransactionCreationSource.RESWARE:
      return "ResWare";
    case OrganizationTransactionCreationSource.SOFTPRO:
      return "SoftPro";
    default:
      return "Notarize's API";
  }
}
function renderWetSignAlertBanner(
  transaction: NonNullable<OrganizationTransaction>,
  viewerOrganization: { organizationType: OrganizationTypeEnum } | undefined | null,
) {
  switch (viewerOrganization?.organizationType) {
    case OrganizationTypeEnum.LENDER:
      return (
        <AlertMessage kind="warning" banner centerText>
          <FormattedMessage
            id="140f551e-2b33-4f71-a2ac-006006fbdc06"
            defaultMessage="Return to {creationSource} if you'd like to make updates to this transaction."
            values={{ creationSource: getCreationSource(transaction.creationSource) }}
          />
        </AlertMessage>
      );
    case OrganizationTypeEnum.TITLE_AGENCY:
      return (
        <AlertMessage kind="warning" banner centerText>
          <FormattedMessage
            id="140f551e-2b33-4f71-a2ac-006006fbdc06"
            defaultMessage="{lender} at {organization} is making changes to this closing package. We'll send you an updated invitation as soon as the documents are ready again."
            values={{
              lender: userFullName(transaction.employee, transaction.employee.email || undefined),
              organization: transaction.publicOrganization.name,
            }}
          />
        </AlertMessage>
      );
  }
}

function MarkWetSignCompleteAlert({
  refetch,
  transaction,
}: {
  refetch: () => Promise<unknown>;
  transaction: OrganizationTransaction;
}) {
  const [loading, setLoading] = useState(false);

  const addWetSignDocumentToOrganizationTransactionMutateFn = useMutation(
    AddWetSignDocumentToOrganizationTransactionMutation,
  );
  const execRefetch = () => refetch();
  const handleConvertToWetSign = () => {
    setLoading(true);
    segmentTrack(SEGMENT_EVENTS.WET_SIGN_COMPLETE_BUTTON_CLICKED);
    addWetSignDocumentToOrganizationTransactionMutateFn({
      variables: { input: { organizationTransactionId: transaction.id } },
    })
      .then(execRefetch)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AlertMessage kind="info" banner centerText>
      <FormattedMessage
        id="e6467d88-5e01-442b-bb17-a975ffb33c2b"
        defaultMessage="Was the closing completed offline? <action>Mark as Wet Sign Complete</action>"
        values={{
          action: (text) => (
            <ActionButton
              onClick={handleConvertToWetSign}
              disabled={loading}
              automationId="mark-wet-sign-complete"
            >
              &nbsp;{text}
            </ActionButton>
          ),
        }}
      />
    </AlertMessage>
  );
}

export function TransactionDetailsAlertMessages({
  transaction,
  viewerOrganization,
  refetch,
}: {
  transaction: OrganizationTransaction;
  // TODO: BIZ-6333 Make optional props required
  viewerOrganization?: { organizationType: OrganizationTypeEnum } | undefined | null;
  refetch?: () => Promise<unknown>;
}) {
  const { detailedStatus } = transaction;
  const { currentUserRole, hasPermissionFor } = usePermissions();
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const brandNameEnabled = useFeatureFlag(ORGANIZATION_BRAND_NAME);
  const showAlertsToOrgMembers =
    currentUserRole === UserRole.ORGANIZATION_MEMBER &&
    hasPermissionFor("viewOrganizationTransactions");
  const isMortgageTransaction = transaction.isMortgage;
  const isWetSignTransaction = transaction.transactionType === MortgageTransactionType.wet_sign;
  const organizationName = transaction.publicOrganization.name;
  const brandName = brandNameEnabled
    ? transaction.publicOrganization.organizationBrand.name
    : organizationName;
  const canConvertHybridTransaction =
    transaction.transactionType &&
    isHybridTransactionType(transaction.transactionType) &&
    (transaction.detailedStatus === OrganizationTransactionDetailedStatus.ESIGN_COMPLETE ||
      transaction.detailedStatus === OrganizationTransactionDetailedStatus.CONVERTED_TO_WET_SIGN);
  const canConvertWetSignTransaction =
    transaction.transactionType === MortgageTransactionType.wet_sign &&
    transaction.detailedStatus === OrganizationTransactionDetailedStatus.SENT_TO_TITLE_AGENT;

  const showAwaitingPaymentAlert =
    showAlertsToOrgMembers &&
    detailedStatus === OrganizationTransactionDetailedStatus.AWAITING_PAYMENT;
  const showPaymentFailedAlert =
    showAlertsToOrgMembers &&
    detailedStatus === OrganizationTransactionDetailedStatus.PAYMENT_REQUIRED;
  const showReadOnlyAlert =
    !hasPermissionFor("seeNotarizationSummaryReadOnly") &&
    isMortgageTransaction &&
    transaction.userAction !== UserAction.EDIT &&
    transaction.detailedStatus === OrganizationTransactionDetailedStatus.DRAFT;
  const showMarkWetSignCompleteAlert =
    viewerOrganization?.organizationType === OrganizationTypeEnum.LENDER &&
    (canConvertWetSignTransaction || canConvertHybridTransaction);

  return (
    <>
      {showAwaitingPaymentAlert && <AwaitingPaymentAlert />}
      {showPaymentFailedAlert && <PaymentFailedAlert />}
      {isTxnDetailsRedesign && (
        <>
          {showReadOnlyAlert && <ReadOnlyAlert brandName={brandName} />}
          {isWetSignTransaction &&
            transaction.detailedStatus === OrganizationTransactionDetailedStatus.RECALLED &&
            renderWetSignAlertBanner(transaction, viewerOrganization)}
          {showMarkWetSignCompleteAlert && refetch && (
            <MarkWetSignCompleteAlert refetch={refetch} transaction={transaction} />
          )}
        </>
      )}
    </>
  );
}
