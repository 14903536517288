import { memo, useCallback, useState, useEffect, type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";
import type { Subject } from "rxjs";

import {
  DocumentBundleMembershipRole,
  AnnotationDesignationType,
  AnnotationGraphicTypes,
  AnnotationSubtype,
  CompletionStatuses,
  MeetingEndedState,
  type UpdateSignerIdentitySigningLocationAddressInput,
  type UpdateDocumentIndicatedDateInput,
  type NotarialActs,
  type PageTypes,
} from "graphql_globals";
import type { Interaction } from "common/meeting/notary";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import { useDesignationDeleteInteraction } from "common/meeting/notary/document/pdf/designation/delete";
import { getCurrentDocumentNode, getCurrentPenholderInSignerParties } from "common/meeting/util";
import {
  removeAnnotationFromCache,
  removeManyAnnotationsFromCache,
} from "common/meeting/pdf/annotation/util";
import { PDFWrapper, usePDFContext } from "common/pdf/pspdfkit";
import AlertMessage from "common/core/alert_message";
import DesignationSignerSelection from "common/meeting/notary/document/pdf/designation/signer_selection";
import { PDFDocumentContainer } from "common/pdf/pspdfkit/document";
import { Annotation } from "common/pdf/pspdfkit/annotation";
import {
  AnnotationDesignation,
  UNFULFILLED,
  type DesignationOnUpdate,
} from "common/pdf/pspdfkit/designation";
import { PDFSoftCover, PDFOverlayModal } from "common/pdf/pspdfkit/overlay";
import { useToolbar } from "common/meeting/context/toolbar";
import { useNotaryMeetingContext } from "common/meeting/notary/context";
import {
  useDesignationColor,
  useReassignmentInteraction,
} from "common/meeting/notary/document/pdf/designation";
import { ToolPreview } from "common/meeting/notary/toolbar/preview";
import { useGraphicCache, type GraphicCache } from "common/meeting/context/graphic_cache";
import UpdateAnnotationTextMutation from "common/meeting/pdf/annotation/update_annotation_text_mutation.graphql";
import UpdateAnnotationSizeMutation from "common/meeting/pdf/annotation/selection/update_annotation_size_mutation.graphql";
import UpdateAnnotationLocationMutation from "common/meeting/pdf/annotation/selection/update_annotation_location_mutation.graphql";
import RemoveManyMeetingAnnotationsMutation from "common/meeting/pdf/annotation/remove_many_meeting_annotations_mutation.graphql";
import RemoveAnnotationMutation from "common/meeting/pdf/annotation/remove_annotation_mutation.graphql";
import MeetingQuery, {
  type NotaryMeeting_viewer_user as NotaryUser,
  type NotaryMeeting_meeting_Meeting as MeetingNotaryRoot,
  type NotaryMeeting,
  type NotaryMeetingVariables,
} from "common/meeting/notary/meeting_query.graphql";
import { segmentTrack } from "util/segment";
import type Channel from "socket/channel";
import NotarialActItems, {
  QuickStampNotarialActItems,
  type AcknowledgementType,
} from "common/meeting/notary/document/pdf/notarial_act";
import SignerLocation from "common/meeting/notary/document/pdf/signer_location";
import IndicatedDocumentDate from "common/meeting/notary/document/pdf/indicated_document_date";

import { AnnotationUpdateWarningModal, useAnnotationUpdateWarning } from "./update_warning";
import UpdateAnnotationDesignationDimensionMutation from "./annotation/update_annotation_designation_dimension_mutation.graphql";
import UpdateAnnotationDesignationLocationMutation from "./annotation/update_annotation_designation_location_mutation.graphql";
import UpdateDocumentIndicatedDateMutation from "./update_document_indicated_date_mutation.graphql";
import UpdateSignerIdentitySigningLocationAddressMutation from "./update_signer_identity_signing_location_address_mutation.graphql";
import type {
  NotaryMeetingDocumentPsp as Meeting,
  NotaryMeetingDocumentPsp_documentBundle_documents_edges_node_annotations_edges_node as AnnotationBase,
  NotaryMeetingDocumentPsp_documentBundle_documents_edges_node_annotations_edges_node_ImageAnnotation as ImageAnnotation,
  NotaryMeetingDocumentPsp_documentBundle_documents_edges_node_annotationDesignations_edges_node as Designation,
  NotaryMeetingDocumentPsp_documentBundle_participants as DocuBundleParticipant,
  NotaryMeetingDocumentPsp_meetingParticipants_NotaryParticipant as NotaryParticipant,
} from "./index_fragment.graphql";
import Styles from "./index.module.scss";
import BundleNavigator from "./navigator";
import {
  addAnnotation,
  useMutationCallbacks,
  useSealMutationCallback,
  type Participant,
  type AnnotationLocationDescription,
} from "./annotation";
import { getNonPrePrintedStatementAnnotationDescriptions } from "./annotation/composite";
import type { useDocumentModalState } from "./modal";

type AnnotationBasicLocation = {
  id: string;
  size: null | { height: number; width: number };
  location: { point: { x: number; y: number } };
};

type NavProps = ComponentProps<typeof BundleNavigator>;
type UpdateAnnoCbParams = Parameters<
  Exclude<ComponentProps<typeof Annotation>["onUpdate"], undefined>
>;
type UpdateDesCbParams = Parameters<
  Exclude<ComponentProps<typeof AnnotationDesignation>["onUpdate"], undefined>
>;
type DocModalState = ReturnType<typeof useDocumentModalState>;
type PagePressConfig = {
  meetingId: string;
  documentId: string;
  notaryUser: NotaryUser;
  notaryUsStateName: string;
  activeSignerParticipant: Participant;
  meetingParticipants: Participant[];
  channel: Channel;
  cache: GraphicCache;
  interaction$: Subject<Interaction>;
  currentTool: ReturnType<typeof useToolbar>["currentTool"];
  currentToolArgs: ReturnType<typeof useToolbar>["currentToolArgs"];
  placeTool: ReturnType<typeof useToolbar>["placeTool"];
  documentModalState: DocModalState["documentModalState"];
  onStandaloneSealPlacement: DocModalState["handleStandaloneSealPlacement"];
  onDocumentModalStateCancel: DocModalState["handleCancel"];
  handleNotaryIndicatedDate: () => Promise<unknown>;
  onNonPreprintedQuickstampComplete: (annotationIds: string[]) => void;
};
type Props = {
  meeting: Meeting;
  channel: Channel;
  notaryUser: NotaryUser;
  interaction$: PagePressConfig["interaction$"];
  onSelectDocument: NavProps["onSelectDocument"];
  onSelectLooseLeaf: NavProps["onSelectLooseLeaf"];
  notarialActLooseLeafMapping: NavProps["notarialActLooseLeafMapping"];
  documentModalState: DocModalState["documentModalState"];
  onDocumentModalStateCancel: DocModalState["handleCancel"];
  onSubmitPrePrintedQuickstamp: DocModalState["handleSubmitPrePrintedQuickstamp"];
  onStandaloneSealPlacement: DocModalState["handleStandaloneSealPlacement"];
  onDesignantionReassignment: DocModalState["handleDesignationReassignment"];
  onIndicatedDateOpen: DocModalState["handleIndicatedDateOpen"];
};
type QuickStampArgs = {
  args: {
    meetingId: string;
    documentId: string;
    location: { pageType: PageTypes; pageIndex: number; point: { x: number; y: number } };
  };
  activeSignerParticipant: Participant;
  meetingParticipants: Participant[];
  notarialAct: NotarialActs;
  acknowledgementType: AcknowledgementType | undefined;
  principals: string[];
  notaryUser: NotaryUser;
  notaryUsStateName: string;
  cache: GraphicCache;
  setFocused: ((id: string) => void) | undefined;
  handleSealMutateFn: ReturnType<typeof useSealMutationCallback>;
  mutateCallbacks: ReturnType<typeof useMutationCallbacks>;
};

export function isPermissionAnnotationEditError(error: unknown): boolean {
  return (
    isGraphQLError(error) &&
    error.graphQLErrors.some((e) => e.specifics === "Current user cannot edit this annotation.")
  );
}

function isAssignedToNotary({ signerRole }: Designation) {
  return signerRole.role === DocumentBundleMembershipRole.NOTARY;
}

function computeNewPoint({ location, size }: AnnotationBasicLocation, distanceFactor?: number) {
  const { point } = location;
  return { x: point.x, y: point.y - size!.height - (distanceFactor || 1) * 10 };
}

function cacheAnnotationSize(options: {
  cache: GraphicCache;
  subtype: AnnotationSubtype;
  notaryUserId: string;
  annotationAuthorId: string;
  size: { height: number; width: number };
}) {
  if (options.annotationAuthorId !== options.notaryUserId) {
    return;
  }
  const { subtype } = options;
  switch (subtype) {
    case AnnotationSubtype.INITIALS:
    case AnnotationSubtype.SEAL:
    case AnnotationSubtype.NOTARY_SIGNATURE:
    case AnnotationSubtype.SIGNATURE:
      options.cache.set({
        userId: options.notaryUserId,
        graphicType:
          subtype === AnnotationSubtype.INITIALS
            ? AnnotationGraphicTypes.INITIALS
            : subtype === AnnotationSubtype.SEAL
              ? AnnotationGraphicTypes.SEAL
              : AnnotationGraphicTypes.SIGNATURE,
        value: options.size,
      });
  }
}

function useHandleInteractionTransaction<
  Args extends unknown[],
  R,
  Fn extends (...args: Args) => Promise<R>,
>(interaction$: PagePressConfig["interaction$"], handleFn: Fn): Fn {
  const wrapped: unknown = (...args: Args) => {
    interaction$.next({ locked: true });
    return handleFn(...args)
      .then((value) => {
        interaction$.next({ locked: false });
        return value;
      })
      .catch((error) => {
        interaction$.next({ locked: false, error });
        if (!isPermissionAnnotationEditError(error)) {
          throw error;
        }
      });
  };
  return useCallback(wrapped as Fn, [handleFn, interaction$]);
}

function useAnnotationUpdate({
  meetingId,
  notaryUser: { id: notaryUserId },
  cache,
  interaction$,
}: {
  meetingId: string;
  notaryUser: NotaryUser;
  cache: GraphicCache;
  interaction$: PagePressConfig["interaction$"];
}) {
  const updateAnnotationTextMutateFn = useMutation(UpdateAnnotationTextMutation);
  const updateAnnotationLocationMutateFn = useMutation(UpdateAnnotationLocationMutation);
  const updateAnnotationSizeMutateFn = useMutation(UpdateAnnotationSizeMutation);
  const handleAnnotationUpdate = useCallback(
    (annotation: AnnotationBase, evt: UpdateAnnoCbParams[1]) => {
      switch (evt.type) {
        case "move": {
          const point = { x: evt.newX, y: evt.newY };
          return updateAnnotationLocationMutateFn({
            variables: {
              input: {
                id: annotation.id,
                authorId: notaryUserId,
                meetingGid: meetingId,
                location: {
                  point,
                  pageType: annotation.location.pageType,
                  page: annotation.location.page,
                  coordinateSystem: annotation.location.coordinateSystem,
                },
              },
            },
            optimisticResponse: {
              updateAnnotationLocation: {
                errors: null,
                annotation: {
                  ...annotation,
                  location: {
                    ...annotation.location,
                    point: { ...annotation.location.point, ...point },
                    coordinateSystem: annotation.location.coordinateSystem,
                    __typename: "AnnotationLocation",
                  },
                },
                __typename: "UpdateAnnotationLocationPayload",
              },
            },
          }).then(({ data }) => data!.updateAnnotationLocation!.annotation!);
        }
        case "resize": {
          const size = { width: evt.newWidth, height: evt.newHeight };
          cacheAnnotationSize({
            cache,
            annotationAuthorId: annotation.authorId,
            subtype: annotation.subtype!,
            notaryUserId,
            size,
          });
          return updateAnnotationSizeMutateFn({
            variables: {
              input: {
                id: annotation.id,
                meetingGid: meetingId,
                authorId: notaryUserId,
                size,
              },
            },
            optimisticResponse: {
              updateAnnotationSize: {
                __typename: "UpdateAnnotationSizePayload",
                errors: null,
                annotation: {
                  ...annotation,
                  size: { ...size, __typename: "Size" },
                },
              },
            },
          }).then(({ data }) => data!.updateAnnotationSize!.annotation!);
        }
        case "edittext": {
          const hasTextContent = /\S/.test(evt.newText);
          return updateAnnotationTextMutateFn({
            variables: {
              input: {
                id: annotation.id,
                authorId: notaryUserId,
                text: evt.newText,
                width: evt.newWidth,
                meetingGid: meetingId,
              },
            },
            optimisticResponse: {
              updateAnnotationText: {
                errors: null,
                annotation: {
                  ...annotation,
                  text: evt.newText,
                  size: { ...annotation.size, width: evt.newWidth, __typename: "Size" },
                },
                annotationDesignation: annotation.annotationDesignationId
                  ? {
                      __typename: "AnnotationDesignation",
                      id: annotation.annotationDesignationId,
                      fulfilled: hasTextContent,
                      inProgress: !hasTextContent,
                    }
                  : null,
                __typename: "UpdateAnnotationTextPayload",
              },
            },
          }).then(({ data }) => data!.updateAnnotationText!.annotation!);
        }
      }
    },
    [
      updateAnnotationSizeMutateFn,
      updateAnnotationTextMutateFn,
      updateAnnotationLocationMutateFn,
      meetingId,
      notaryUserId,
    ],
  );
  return useHandleInteractionTransaction<
    Parameters<typeof handleAnnotationUpdate>,
    unknown,
    typeof handleAnnotationUpdate
  >(interaction$, handleAnnotationUpdate);
}

function useDesignationUpdate({
  meetingId,
  interaction$,
}: {
  meetingId: string;
  interaction$: PagePressConfig["interaction$"];
}): DesignationOnUpdate<Designation> {
  const updateAnnotationDesignationLocationMutateFn = useMutation(
    UpdateAnnotationDesignationLocationMutation,
  );
  const updateAnnotationDesignationDimensionMutateFn = useMutation(
    UpdateAnnotationDesignationDimensionMutation,
  );
  const handleDesignationUpdate = useCallback(
    (designation: Designation, evt: UpdateDesCbParams[1]) => {
      switch (evt.type) {
        case "move": {
          const point = [evt.newX, evt.newY];
          return updateAnnotationDesignationLocationMutateFn({
            variables: {
              input: {
                id: designation.id,
                meetingId,
                point,
                coordinateSystem: designation.location.coordinateSystem,
                page: designation.location.page,
              },
            },
            optimisticResponse: {
              updateAnnotationDesignationLocation: {
                __typename: "UpdateAnnotationDesignationLocationPayload",
                errors: null,
                annotationDesignation: {
                  ...designation,
                  location: {
                    ...designation.location,
                    point: {
                      ...designation.location.point,
                      x: point[0],
                      y: point[1],
                    },
                  },
                },
              },
            },
          });
        }
        case "resize": {
          const size = { width: evt.newWidth, height: evt.newHeight };
          return updateAnnotationDesignationDimensionMutateFn({
            variables: {
              input: {
                id: designation.id,
                meetingId,
                size,
              },
            },
            optimisticResponse: {
              updateAnnotationDesignationDimension: {
                __typename: "UpdateAnnotationDesignationDimensionPayload",
                errors: null,
                annotationDesignation: {
                  ...designation,
                  size: { ...size, __typename: "Size" },
                },
              },
            },
          });
        }
        default:
          return Promise.resolve();
      }
    },
    [
      updateAnnotationDesignationLocationMutateFn,
      updateAnnotationDesignationDimensionMutateFn,
      meetingId,
    ],
  );
  return useHandleInteractionTransaction<
    Parameters<typeof handleDesignationUpdate>,
    unknown,
    typeof handleDesignationUpdate
  >(interaction$, handleDesignationUpdate);
}

function useSignerIdentitySigningLocationAddressMutationCallback() {
  const updateSignerIdentitySigningLocationAddressMutateFn = useMutation(
    UpdateSignerIdentitySigningLocationAddressMutation,
  );
  return useCallback(
    (input: UpdateSignerIdentitySigningLocationAddressInput, meetingId: string) => {
      return updateSignerIdentitySigningLocationAddressMutateFn({
        variables: { input },
        update(cacheProxy) {
          // This mutation does not return a participant object so we have to manually update the cache.
          const data = cacheProxy.readQuery<NotaryMeeting, NotaryMeetingVariables>({
            query: MeetingQuery,
            variables: { meetingId },
          });
          const meeting = data!.meeting as MeetingNotaryRoot;
          const newMeeting = {
            ...meeting,
            meetingParticipants: meeting.meetingParticipants.map((part) => {
              return part.__typename === "SignerParticipant" &&
                part.signerIdentityId === input.signerIdentityId
                ? {
                    ...part,
                    signingLocationAddress: {
                      country: input.address.country!,
                      state: input.address.state!,
                      __typename: "Address",
                    },
                  }
                : part;
            }),
          };
          cacheProxy.writeQuery<NotaryMeeting, NotaryMeetingVariables>({
            data: { ...data!, meeting: newMeeting },
            query: MeetingQuery,
            variables: { meetingId },
          });
        },
      }).then(({ data }) => data!.updateSignerIdentitySigningLocationAddress);
    },
    [updateSignerIdentitySigningLocationAddressMutateFn],
  );
}

function useUpdateDocumentIndicatedDateMutationCallback() {
  const updateDocumentIndicatedDateMutateFn = useMutation(UpdateDocumentIndicatedDateMutation);
  return useCallback(
    (input: UpdateDocumentIndicatedDateInput) => {
      return updateDocumentIndicatedDateMutateFn({ variables: { input } });
    },
    [updateDocumentIndicatedDateMutateFn],
  );
}

export async function quickStampForNonPreprinted({
  args,
  activeSignerParticipant,
  meetingParticipants,
  notarialAct,
  acknowledgementType,
  principals,
  notaryUser,
  notaryUsStateName,
  cache,
  setFocused,
  handleSealMutateFn,
  mutateCallbacks,
}: QuickStampArgs) {
  const annotationDescriptons = getNonPrePrintedStatementAnnotationDescriptions({
    notaryUsStateName,
    notarialAct,
    notaryUser,
    acknowledgementType,
    principals: meetingParticipants.filter((mp) => principals.includes(mp.id)),
    notaryProfile: notaryUser.notaryProfile!,
    isAttorney: notaryUser.notaryProfile!.isAttorney,
  });
  const sealAnnotation = await handleSealMutateFn(args, notarialAct, principals, notaryUser, cache);

  let nextArgs = {
    ...args,
    location: {
      ...args.location,
      point: {
        ...args.location.point,
        x: args.location.point.x + sealAnnotation.size!.width + 15,
      },
    },
  };
  const ids = [sealAnnotation.id];
  /* eslint-disable no-await-in-loop */
  for (const description of annotationDescriptons) {
    const { subtype, distanceFactor } = description;
    const annotation = await addAnnotation(
      subtype,
      nextArgs,
      notaryUser,
      activeSignerParticipant,
      mutateCallbacks,
      cache,
      setFocused,
      false,
      subtype === AnnotationSubtype.FREE_TEXT || subtype === AnnotationSubtype.DISCLOSURE
        ? description.text
        : undefined,
    );
    ids.push(annotation.id);
    nextArgs = {
      ...args,
      location: { ...args.location, point: computeNewPoint(annotation, distanceFactor) },
    };
  }
  /* eslint-enable no-await-in-loop */
  return ids;
}

function usePagePress(
  {
    meetingId,
    channel,
    documentId,
    notaryUser,
    notaryUsStateName,
    activeSignerParticipant,
    meetingParticipants,
    cache,
    currentTool,
    currentToolArgs,
    placeTool,
    interaction$,
    documentModalState,
    onStandaloneSealPlacement,
    onDocumentModalStateCancel,
    handleNotaryIndicatedDate,
    onNonPreprintedQuickstampComplete,
  }: PagePressConfig,
  handleSealMutateFn: ReturnType<typeof useSealMutationCallback>,
) {
  const { analytics } = useNotaryMeetingContext();
  const mutateCallbacks = useMutationCallbacks();
  const { setFocused } = usePDFContext();
  const handlePagePress = useCallback<
    (
      ...args: Parameters<
        Exclude<ComponentProps<typeof PDFDocumentContainer>["onPagePress"], undefined>
      >
    ) => Promise<unknown>
  >(
    async ({ pageIndex, point, pageType, shiftKey }) => {
      placeTool(shiftKey);
      const args = {
        meetingId,
        documentId,
        location: {
          pageType,
          pageIndex,
          point,
        },
      };
      if (currentTool === "NOTARY_POINTER") {
        segmentTrack("Notary Selects Pointer", {
          document_id: documentId,
          signer_id: activeSignerParticipant.id,
          point_position: point,
        });
        channel.sendMessage("notary_pointer", {
          pageNum: pageIndex,
          pageType,
          documentId,
          activeParticipantId: activeSignerParticipant.id,
          point,
        });
        return null;
      }

      const isSeal = currentTool === AnnotationSubtype.SEAL;
      if (isSeal && documentModalState.type === "prePrintedWalkthrough") {
        const { principals, notarialAct } = documentModalState.quickStampInformation;
        await handleSealMutateFn(args, notarialAct, principals, notaryUser, cache);
        handleNotaryIndicatedDate();
      } else if (isSeal && currentToolArgs?.type === "nonPrePrintedQuickstamp") {
        analytics.onQuickStampPlacement({
          notaryUserId: notaryUser.id,
          documentId,
          isPrePrinted: false,
        });

        const { notarialAct, principals, acknowledgementType } = currentToolArgs;

        const ids = await quickStampForNonPreprinted({
          args,
          activeSignerParticipant,
          meetingParticipants,
          notarialAct,
          acknowledgementType,
          principals,
          notaryUser,
          notaryUsStateName,
          cache,
          setFocused,
          handleSealMutateFn,
          mutateCallbacks,
        });
        await handleNotaryIndicatedDate();
        onNonPreprintedQuickstampComplete(ids);
      } else if (isSeal) {
        onStandaloneSealPlacement(args);
      } else if (currentTool) {
        return addAnnotation(
          currentTool,
          args,
          notaryUser,
          activeSignerParticipant,
          mutateCallbacks,
          cache,
          setFocused,
          shiftKey,
        );
      }

      return null;
    },
    [
      placeTool,
      currentTool,
      currentToolArgs,
      documentId,
      setFocused,
      notaryUser,
      activeSignerParticipant,
      cache,
      documentModalState,
      handleSealMutateFn,
      handleNotaryIndicatedDate,
      onNonPreprintedQuickstampComplete,
      onStandaloneSealPlacement,
      analytics,
      notaryUsStateName,
      meetingParticipants,
    ],
  );
  const handleReassignment = useHandleInteractionTransaction<
    Parameters<ReturnType<typeof useReassignmentInteraction>>,
    unknown,
    ReturnType<typeof useReassignmentInteraction>
  >(interaction$, useReassignmentInteraction({ meetingId }));
  const handleDesignationFulfillRaw = useCallback(
    async (designation: Designation) => {
      const {
        location: {
          page,
          pageType,
          point: { x, y },
        },
      } = designation;
      const args = {
        meetingId,
        documentId,
        location: {
          pageType,
          pageIndex: page,
          point: { x, y },
        },
        annotationDesignationId: designation.id,
      };
      if (designation.type === AnnotationDesignationType.SEAL) {
        onStandaloneSealPlacement(args);
        return UNFULFILLED;
      }
      return addAnnotation(
        designation.type,
        args,
        notaryUser,
        activeSignerParticipant,
        mutateCallbacks,
        cache,
        setFocused,
        false,
      ) as Promise<AnnotationLocationDescription>;
    },
    [setFocused, documentId, notaryUser, onStandaloneSealPlacement, activeSignerParticipant, cache],
  );
  const handleDesignationFulfill = useHandleInteractionTransaction<
    Parameters<typeof handleDesignationFulfillRaw>,
    unknown,
    typeof handleDesignationFulfillRaw
  >(interaction$, handleDesignationFulfillRaw);

  return {
    handlePagePress: useHandleInteractionTransaction<
      Parameters<typeof handlePagePress>,
      unknown,
      typeof handlePagePress
    >(interaction$, handlePagePress),
    handleDesignationFulfillFactory: (designation: Designation) => {
      return isAssignedToNotary(designation) ? handleDesignationFulfill : undefined;
    },
    handleAssignDesignation: useCallback(
      (...args: Parameters<ReturnType<typeof useReassignmentInteraction>>) => {
        onDocumentModalStateCancel();
        return handleReassignment(...args);
      },
      [handleReassignment, onDocumentModalStateCancel],
    ),
  };
}

function useDeleteCallback({
  meetingId,
  documentId,
  notaryUser: { id: notaryUserId },
  interaction$,
}: {
  meetingId: string;
  documentId: string;
  notaryUser: NotaryUser;
  interaction$: PagePressConfig["interaction$"];
}) {
  const removeAnnotationMutateFn = useMutation(RemoveAnnotationMutation);
  const handleAnnotationDelete = useCallback(
    ({ id }: { id: string }) => {
      const input = {
        id,
        meetingGid: meetingId,
        authorId: notaryUserId,
      };
      return removeAnnotationMutateFn({
        variables: { input },
        optimisticResponse: {
          removeAnnotation: {
            errors: null,
            meeting: null, // Null because we do not want to update the lock button until mutation completes
            __typename: "RemoveAnnotationPayload",
          },
        },
        update(cacheProxy, { data }) {
          removeAnnotationFromCache(cacheProxy, {
            meetingId,
            documentId,
            annotationId: id,
            errors: data!.removeAnnotation!.errors,
          });
        },
      });
    },
    [removeAnnotationMutateFn, notaryUserId, meetingId, documentId],
  );
  return useHandleInteractionTransaction<
    Parameters<typeof handleAnnotationDelete>,
    unknown,
    typeof handleAnnotationDelete
  >(interaction$, handleAnnotationDelete);
}

function useQuickstampHelpState(currentDocument: {
  id: string;
  completionStatus: CompletionStatuses | null;
}) {
  const state = useState<ComponentProps<typeof BundleNavigator>["quickstampHelpState"]>(null);

  // automatically hide this help when document switches or notary changes the lock state.
  useEffect(() => {
    state[1](null);
  }, [currentDocument.id, currentDocument.completionStatus]);

  return state;
}

function NotaryMeetingDocumentBundle({
  meeting,
  channel,
  notaryUser,
  onSelectDocument,
  onSelectLooseLeaf,
  notarialActLooseLeafMapping,
  interaction$,
  documentModalState,
  onDocumentModalStateCancel,
  onSubmitPrePrintedQuickstamp,
  onStandaloneSealPlacement,
  onDesignantionReassignment,
  onIndicatedDateOpen,
}: Props) {
  const { currentTool, currentToolArgs, placeTool, selectTool } = useToolbar();
  const cache = useGraphicCache();
  const { getDesignationColor } = useDesignationColor();
  const document = getCurrentDocumentNode(meeting);
  const [quickstampHelpState, setQuickstampHelpState] = useQuickstampHelpState(document);
  const missingIndicatedDate = !document.indicatedDate;
  const isMeetingOver = meeting.endedState !== MeetingEndedState.NOT_COMPLETED;
  const isCompleteDoc = document.completionStatus === CompletionStatuses.COMPLETE;
  const isRejectedDoc = document.completionStatus === CompletionStatuses.REJECTED;
  const activeSignerParticipantFromMeeting = getCurrentPenholderInSignerParties(meeting);
  const activeSignerParticipant =
    currentToolArgs?.type === "nonActiveSignerName"
      ? meeting.meetingParticipants.find((mp) => mp.id === currentToolArgs.participantId) ||
        activeSignerParticipantFromMeeting
      : activeSignerParticipantFromMeeting;
  const documentBundleParticipants = meeting.documentBundle!
    .participants as DocuBundleParticipant[];
  const notaryParticipant = meeting.meetingParticipants.find(
    (p) => p.__typename === "NotaryParticipant",
  ) as NotaryParticipant;
  const notaryUsStateName = notaryParticipant.usStateName;
  const handleNotaryIndicatedDate = useCallback(() => {
    return notaryUsStateName === "Montana" && missingIndicatedDate
      ? onIndicatedDateOpen()
      : Promise.resolve();
  }, [onIndicatedDateOpen, notaryUsStateName, missingIndicatedDate]);
  const notaryRequiresSignerLocation = notaryUsStateName === "Ohio";
  const callbackOptions = {
    meetingId: meeting.id,
    notaryUser,
    notaryUsStateName,
    documentId: document.id,
    activeSignerParticipant,
    channel,
    cache,
    currentTool,
    currentToolArgs,
    placeTool,
    interaction$,
    documentModalState,
    onDocumentModalStateCancel,
    onStandaloneSealPlacement,
    handleNotaryIndicatedDate,
    onNonPreprintedQuickstampComplete: useCallback((annotationIds: string[]) => {
      setQuickstampHelpState({ type: "does-it-fit", annotationIds });
    }, []),
    meetingParticipants: meeting.meetingParticipants,
  };
  const handleSealMutateFn = useSealMutationCallback();
  const { handlePagePress, handleDesignationFulfillFactory, handleAssignDesignation } =
    usePagePress(callbackOptions, handleSealMutateFn);
  const annotationUpdateWarning = useAnnotationUpdateWarning(meeting.documentBundle!);
  const handleAnnotationUpdate = useAnnotationUpdate(callbackOptions);
  const handleDesignationUpdate = useDesignationUpdate(callbackOptions);
  const handleAnnotationDelete = useDeleteCallback(callbackOptions);
  const removeManyMeetingAnnotations = useMutation(RemoveManyMeetingAnnotationsMutation);
  const handleDesignationDeleteRaw = useDesignationDeleteInteraction(callbackOptions);
  const handleDesignationDelete = useHandleInteractionTransaction<
    Parameters<typeof handleDesignationDeleteRaw>,
    unknown,
    typeof handleDesignationDeleteRaw
  >(interaction$, handleDesignationDeleteRaw);
  const handleUpdateSignerIdentitySigningLocationAddressMutateFn =
    useSignerIdentitySigningLocationAddressMutationCallback();
  const handleUpdateDocumentIndicatedDateMutateFn =
    useUpdateDocumentIndicatedDateMutationCallback();
  const handleSetSignerLocationSubmitRaw = useCallback(
    ({
      signerLocations,
    }: {
      signerLocations:
        | null
        | {
            country: string;
            state: string;
            signerIdentityId: string;
          }[];
    }) => {
      if (!signerLocations?.length) {
        throw new Error(`Locations missing ${signerLocations?.length}`);
      }
      return Promise.all(
        signerLocations.map(({ country, state, signerIdentityId }) => {
          return handleUpdateSignerIdentitySigningLocationAddressMutateFn(
            {
              signerIdentityId,
              address: { country, state },
            },
            meeting.id,
          );
        }),
      );
    },
    [activeSignerParticipant],
  );

  const handleNotarialActSubmitRaw = useCallback(
    async ({ notarialAct, principals }: { notarialAct: NotarialActs; principals: string[] }) => {
      if (documentModalState.type !== "standaloneSealSelection") {
        throw new Error("Invalid seal selection state");
      }
      onDocumentModalStateCancel();
      await handleSealMutateFn(
        documentModalState.stampInformation,
        notarialAct,
        principals,
        notaryUser,
        cache,
      );
      handleNotaryIndicatedDate();
    },
    [handleNotaryIndicatedDate, documentModalState, onDocumentModalStateCancel, handleSealMutateFn],
  );
  const handleNotarialActSubmit = useHandleInteractionTransaction<
    Parameters<typeof handleNotarialActSubmitRaw>,
    unknown,
    typeof handleNotarialActSubmitRaw
  >(interaction$, handleNotarialActSubmitRaw);
  const handleSetSignerLocationSubmit = useHandleInteractionTransaction<
    Parameters<typeof handleSetSignerLocationSubmitRaw>,
    unknown,
    typeof handleSetSignerLocationSubmitRaw
  >(interaction$, handleSetSignerLocationSubmitRaw);
  const handleQuickStampChoiceSubmit = useCallback<
    ComponentProps<typeof QuickStampNotarialActItems>["onSubmit"]
  >(
    (params) => {
      if (params.isPrePrinted) {
        onSubmitPrePrintedQuickstamp(params);
      } else {
        selectTool(AnnotationSubtype.SEAL, {
          type: "nonPrePrintedQuickstamp",
          notarialAct: params.notarialAct,
          principals: params.principals,
          acknowledgementType: params.acknowledgementType,
        });
        onDocumentModalStateCancel();
        setQuickstampHelpState({ type: "pre-placement-help" });
      }
    },
    [onSubmitPrePrintedQuickstamp, onDocumentModalStateCancel],
  );
  const documentContainsSeal = document.annotations.edges.some(
    ({ node }) => node.subtype === "SEAL",
  );
  const needsSigningLocationAddress =
    activeSignerParticipant.__typename === "SignerParticipant" &&
    !Object.values(activeSignerParticipant.signingLocationAddress).some(Boolean);
  const showSignerLocationModal =
    documentContainsSeal && notaryRequiresSignerLocation && needsSigningLocationAddress;
  const signerParticipants = meeting.meetingParticipants.filter(
    (p) => p.__typename === "SignerParticipant",
  );

  const isRemoteWitnessPresent = meeting.meetingParticipants.some(
    (p) => p.__typename === "WitnessParticipant" && !p.parentId,
  );

  const documentsWithIndicatedDate = meeting.documentBundle!.documents.edges.filter(
    (e) => e.node.indicatedDate,
  );
  const lastIndicatedDate =
    documentsWithIndicatedDate[documentsWithIndicatedDate.length - 1]?.node.indicatedDate;
  return (
    <div className={Styles.documentBundle}>
      <BundleNavigator
        meeting={meeting}
        notaryUser={notaryUser}
        notaryUsStateName={notaryUsStateName}
        onSelectDocument={onSelectDocument}
        onSelectLooseLeaf={onSelectLooseLeaf}
        notarialActLooseLeafMapping={notarialActLooseLeafMapping}
        quickstampHelpState={quickstampHelpState}
        onQuickstampHelpExit={() => setQuickstampHelpState(null)}
        onQuickstampHelpMoveToLooseLeaf={(annotationIds) => {
          segmentTrack("Notary Moved Quickstamp to LLP");

          const annotationIdSet = new Set(annotationIds);
          const documentAnnotations: [string, Set<string>][] = [];
          let firstSealAnnotation: undefined | ImageAnnotation;
          // Check each doc and find the annotations _in_ that document.
          for (const docEdge of meeting.documentBundle!.documents.edges) {
            const thisDocsAnnotationRemovals = new Set<string>();
            for (const { node: annotation } of docEdge.node.annotations.edges) {
              if (annotationIdSet.has(annotation.id)) {
                thisDocsAnnotationRemovals.add(annotation.id);
                firstSealAnnotation ||=
                  annotation.__typename === "ImageAnnotation" ? annotation : undefined;
              }
            }
            if (thisDocsAnnotationRemovals.size) {
              documentAnnotations.push([docEdge.node.id, thisDocsAnnotationRemovals]);
            }
          }

          setQuickstampHelpState(null);
          removeManyMeetingAnnotations({
            variables: { input: { authorId: notaryUser.id, meetingId: meeting.id, annotationIds } },
            optimisticResponse: {
              removeManyMeetingAnnotations: {
                meeting,
                __typename: "RemoveManyMeetingAnnotationsPayload",
              },
            },
            update(cacheProxy) {
              removeManyAnnotationsFromCache(cacheProxy, {
                meetingId: meeting.id,
                documentAnnotations,
              });
            },
          });

          return firstSealAnnotation?.notarialActEnum;
        }}
      />
      {document.witnessRequired && isRemoteWitnessPresent && (
        <AlertMessage className={Styles.alertBanner} kind="info">
          <FormattedMessage
            id="987b5e86-dda9-407a-9df0-1e08071d1144"
            defaultMessage="Lock documents before the witness leaves to ensure signatures are not erased."
          />
        </AlertMessage>
      )}
      <PDFDocumentContainer
        document={document}
        onPagePress={isMeetingOver || isCompleteDoc || isRejectedDoc ? undefined : handlePagePress}
      >
        {isCompleteDoc ? (
          <PDFSoftCover>
            <div className={Styles.completedBanner}>
              <FormattedMessage
                id="0aa02cb2-77c5-480b-8b3d-dc9155be3130"
                defaultMessage="Document is completed"
              />
            </div>
          </PDFSoftCover>
        ) : isRejectedDoc ? (
          <PDFSoftCover>
            <div className={Styles.rejectedBanner}>
              <FormattedMessage
                id="3ca772ae-ca65-414e-98f4-9a23bb189721"
                defaultMessage="Document is rejected"
              />
            </div>
          </PDFSoftCover>
        ) : documentModalState.type === "designationAssignment" ? (
          <PDFOverlayModal onClickOutside={onDocumentModalStateCancel}>
            <DesignationSignerSelection
              designation={documentModalState.designation}
              onSignerClick={handleAssignDesignation}
              currentDocumentWitnessRequired={document.witnessRequired}
              documentBundleParticipants={documentBundleParticipants}
            />
          </PDFOverlayModal>
        ) : documentModalState.type === "quickStampSelection" ? (
          <PDFOverlayModal onClickOutside={onDocumentModalStateCancel}>
            <QuickStampNotarialActItems
              onClose={onDocumentModalStateCancel}
              onSubmit={handleQuickStampChoiceSubmit}
              meetingParticipants={meeting.meetingParticipants}
              currentDocumentClassification={document.classification?.category || null}
              bundleParticipants={meeting.documentBundle!.participants as DocuBundleParticipant[]}
              notaryStateName={notaryUsStateName}
            />
          </PDFOverlayModal>
        ) : documentModalState.type === "standaloneSealSelection" ? (
          <PDFOverlayModal onClickOutside={onDocumentModalStateCancel}>
            <NotarialActItems
              onClose={onDocumentModalStateCancel}
              onSubmit={handleNotarialActSubmit}
              meetingParticipants={meeting.meetingParticipants}
              currentDocumentClassification={document.classification?.category || null}
              bundleParticipants={meeting.documentBundle!.participants as DocuBundleParticipant[]}
              notaryStateName={notaryUsStateName}
            />
          </PDFOverlayModal>
        ) : showSignerLocationModal ? (
          <PDFOverlayModal onClickOutside={onDocumentModalStateCancel}>
            <SignerLocation
              onSubmit={handleSetSignerLocationSubmit}
              signerParticipants={signerParticipants}
            />
          </PDFOverlayModal>
        ) : documentModalState.type === "setIndicatedDocumentDate" ? (
          <PDFOverlayModal onClickOutside={onDocumentModalStateCancel}>
            <IndicatedDocumentDate
              onSubmit={handleUpdateDocumentIndicatedDateMutateFn}
              documentId={document.id}
              onClose={onDocumentModalStateCancel}
              lastIndicatedDate={lastIndicatedDate}
            />
          </PDFOverlayModal>
        ) : null}
      </PDFDocumentContainer>
      {!document.isEnote &&
        document.annotations.edges.map(({ node }) => {
          return (
            <Annotation
              key={node.id}
              annotation={node}
              onUpdate={
                node.__typename === "WhiteboxAnnotation"
                  ? undefined
                  : (annotation, evt) => {
                      return handleAnnotationUpdate(annotation, evt).then((updatedAnnotation) => {
                        return annotationUpdateWarning.updateHandler(updatedAnnotation, evt);
                      });
                    }
              }
              onDelete={handleAnnotationDelete}
            />
          );
        })}
      {!isMeetingOver && (
        <ToolPreview
          notaryUser={notaryUser}
          activeSignerParticipant={activeSignerParticipant}
          cache={cache}
        />
      )}
      {document.annotationDesignations.edges
        .filter(({ node }) => node.active)
        .map(({ node }) => {
          const handleFulfill = handleDesignationFulfillFactory(node);
          const baseProps = {
            key: node.id,
            designation: node,
            color: getDesignationColor(node, activeSignerParticipant as { colorHex?: string }),
          };
          return document.isEnote ? (
            <AnnotationDesignation {...baseProps} />
          ) : handleFulfill ? (
            <AnnotationDesignation
              {...baseProps}
              onFulfill={handleFulfill}
              onDelete={handleDesignationDelete}
            />
          ) : (
            <AnnotationDesignation
              {...baseProps}
              onReassign={
                document.witnessRequired || documentBundleParticipants.length > 1
                  ? onDesignantionReassignment
                  : undefined
              }
              onUpdate={handleDesignationUpdate}
              onDelete={handleDesignationDelete}
            />
          );
        })}
      {annotationUpdateWarning.modalMessageKind && (
        <AnnotationUpdateWarningModal
          meetingId={meeting.id}
          messageKind={annotationUpdateWarning.modalMessageKind}
          onClose={annotationUpdateWarning.handleCloseModal}
        />
      )}
    </div>
  );
}

export default memo((props: Props) => (
  <PDFWrapper>
    <NotaryMeetingDocumentBundle {...props} />
  </PDFWrapper>
));
