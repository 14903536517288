import { type ComponentProps } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";

import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";
import { MortgageTransactionType } from "graphql_globals";
import CompletionRequirementsText from "util/completion_requirements/completion_requirements_text";
import Tab from "common/core/tabs/tab";
import Tabs from "common/core/tabs";
import DocumentTabName from "common/details/bundle/document_tab_name";
import { usePermissions } from "common/core/current_user_role";
import { useTxnDetailsRedesign } from "util/feature_detection";
import { getParentPathByOffset } from "util/location";
import { useFeatureFlag } from "common/feature_gating";

import type {
  OrganizationTransactionDetailsTabs_bundle_meetings as BundleMeetings,
  OrganizationTransactionDetailsTabs as OrganizationTransaction,
} from "./index_fragment.graphql";

function renderSummaryTab(search: string, path: string) {
  return (
    <Tab to={`${path}/summary`} search={search} automationId="summary-tab">
      <FormattedMessage id="a95b5c40-cffe-453d-8bf9-1da194659860" defaultMessage="Summary" />
    </Tab>
  );
}

function renderPointsOfContactTab(
  search: string,
  path: string,
  transaction: OrganizationTransaction,
) {
  return transaction.isMortgage || transaction.contacts.length ? (
    <Tab to={`${path}/contacts`} search={search} automationId="contacts-tab">
      <FormattedMessage id="43b51916-53a7-430b-97d6-d205bc20bb12" defaultMessage="Contacts" />
    </Tab>
  ) : null;
}

function RecipientTab({
  search,
  path,
  transaction,
  isKeystone,
}: {
  search: string;
  path: string;
  transaction: OrganizationTransaction;
  isKeystone: boolean;
}) {
  const { hasPermissionFor } = usePermissions();

  return (transaction.isMortgage &&
    transaction.transactionType === MortgageTransactionType.wet_sign) ||
    (!hasPermissionFor("viewTransactionSigners") && !isKeystone) ? null : (
    <Tab to={`${path}/user`} search={search} automationId="signer-tab">
      <FormattedMessage id="48755734-ca75-4670-8cb1-d4a8329c12bd" defaultMessage="Recipients" />
    </Tab>
  );
}

function DeepfakeTab({
  search,
  path,
  isKeystone,
}: {
  search: string;
  path: string;
  isKeystone: boolean;
}) {
  const ftechEnabled = useFeatureFlag("enable-ftech-transaction-tab");

  if (!ftechEnabled || !isKeystone) {
    return null;
  }

  return (
    <Tab to={`${path}/deepfake`} search={search}>
      <FormattedMessage id="ab27e7ce-8476-466d-8663-c84a37317f4b" defaultMessage="Deepfake" />
    </Tab>
  );
}

function MeetingTab({
  search,
  path,
  transaction,
  isKeystone,
}: {
  search: string;
  path: string;
  transaction: OrganizationTransaction;
  isKeystone: boolean;
}) {
  const { finalizedMeetings, meetings } = transaction.bundle!;

  if ((!finalizedMeetings.totalCount && !isKeystone) || (meetings.totalCount === 0 && isKeystone)) {
    return null;
  }

  return (
    <Tab to={`${path}/meeting`} search={search} automationId="meeting-tab">
      <FormattedMessage id="10068c61-d0a4-4d7b-9264-56ba311cb11a" defaultMessage="Meeting" />
    </Tab>
  );
}

function IdentityDetailsTab(props: {
  search: string;
  path: string;
  transaction: OrganizationTransaction;
  isKeystone: boolean;
}) {
  const { hasPermissionFor } = usePermissions();
  const canViewIdentityTab =
    hasPermissionFor("userDetails") ||
    hasPermissionFor("viewTransactionSignersIdentity") ||
    props.isKeystone;

  if (
    !canViewIdentityTab ||
    (props.transaction.isMortgage &&
      props.transaction.transactionType === MortgageTransactionType.wet_sign)
  ) {
    return null;
  }

  return (
    <Tab to={`${props.path}/identity`} search={props.search} automationId="identity-tab">
      <FormattedMessage id="b33c3761-be3f-482a-8f9c-2ff9039f7194" defaultMessage="Identity" />
    </Tab>
  );
}

function renderDocumentTab(
  search: string,
  path: string,
  bundle: ComponentProps<typeof DocumentTabName>["bundle"],
) {
  return (
    <Tab to={`${path}/document`} search={search} automationId="documents-tab">
      <DocumentTabName bundle={bundle} />
    </Tab>
  );
}

function renderHistoryTab(search: string, path: string) {
  return (
    <Tab to={`${path}/history`} search={search} automationId="history-tab">
      <FormattedMessage id="96540c10-3ecb-4e5d-b439-b71619b105b8" defaultMessage="History" />
    </Tab>
  );
}

function renderNotaryNotesTab(search: string, path: string, withNotes: boolean | undefined) {
  return withNotes ? (
    <Tab to={`${path}/notes`} search={search} automationId="notes-tab">
      <FormattedMessage id="f445be29-0b62-462c-8a0d-550848b10e38" defaultMessage="Notes" />
    </Tab>
  ) : null;
}

function renderWitnessTab(search: string, path: string, meetings: BundleMeetings) {
  const someMeetingHasWitness = meetings.edges.some((edge) =>
    edge.node.meetingParticipants.some((p) => p.__typename === "WitnessParticipant"),
  );
  return someMeetingHasWitness ? (
    <Tab to={`${path}/witness`} search={search} automationId="witness-tab">
      <FormattedMessage id="3309221f-7159-47ca-8bfb-69c17314e389" defaultMessage="Witness" />
    </Tab>
  ) : null;
}

function DeprecatedMeetingTabs({
  search,
  path,
  transaction,
  showFullRecord,
  isKeystone,
}: {
  search: string;
  path: string;
  transaction: OrganizationTransaction;
  showFullRecord: boolean;
  isKeystone: boolean;
}) {
  const { completionRequirements, finalizedMeetings, meetings } = transaction.bundle!;
  const { hasPermissionFor } = usePermissions();

  if ((!finalizedMeetings.totalCount && !isKeystone) || (meetings.totalCount === 0 && isKeystone)) {
    return null;
  }

  return (
    <>
      <Tab to={`${path}/notary`} search={search} automationId="notary-tab">
        <CompletionRequirementsText
          stringId="notary"
          completionRequirements={completionRequirements}
        />
      </Tab>
      {((showFullRecord && hasPermissionFor("viewTransactionPii")) || isKeystone) && (
        <Tab to={`${path}/video`} search={search} automationId="video-tab">
          <FormattedMessage id="82cd34bf-b16e-4f8e-b2ce-70795b1255ee" defaultMessage="Video" />
        </Tab>
      )}
    </>
  );
}

function renderActivityLogsTab(search: string, path: string, withActivityLog: boolean | undefined) {
  return withActivityLog ? (
    <Tab to={`${path}/activity`} search={search} automationId="activity-tab">
      <FormattedMessage id="61f70164-1ff8-422f-a6dc-565a446771c3" defaultMessage="Activity Log" />
    </Tab>
  ) : null;
}

function InternalNotesTab({
  search,
  path,
  isKeystone,
}: {
  search: string;
  path: string;
  isKeystone: boolean;
}) {
  return isKeystone ? (
    <Tab to={`${path}/internal_notes`} search={search}>
      <FormattedMessage id="2ddce7c0-11e3-4291-9599-0f1c64315150" defaultMessage="Internal Notes" />
    </Tab>
  ) : null;
}

type TransactionDetailsTabsProps = {
  transaction: OrganizationTransaction;
  bundle: NonNullable<OrganizationTransaction["bundle"]>;
  showFullRecord: boolean;
  withNotes?: boolean;
  withActivityLog?: boolean;
  withDocuments?: boolean;
};

export function TransactionDetailsTabs({
  transaction,
  bundle,
  showFullRecord,
  withNotes,
  withActivityLog,
  withDocuments,
}: TransactionDetailsTabsProps) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const { search, pathname } = useLocation();
  const path = getParentPathByOffset(pathname, 1);
  const isKeystone = CURRENT_PORTAL === Apps.ADMIN;

  const showHistoryTab = showFullRecord && bundle.auditTrails.length > 0;

  return (
    <Tabs modalView={!isTxnDetailsRedesign} settingsView={isTxnDetailsRedesign}>
      {isTxnDetailsRedesign ? (
        <>
          {renderSummaryTab(search, path)}
          <RecipientTab
            search={search}
            path={path}
            transaction={transaction}
            isKeystone={isKeystone}
          />
          {withDocuments && renderDocumentTab(search, path, bundle)}
          <IdentityDetailsTab
            search={search}
            path={path}
            transaction={transaction}
            isKeystone={isKeystone}
          />
          <MeetingTab
            search={search}
            path={path}
            transaction={transaction}
            isKeystone={isKeystone}
          />
          {renderNotaryNotesTab(search, path, withNotes)}
          {showHistoryTab && renderHistoryTab(search, path)}
          {renderActivityLogsTab(search, path, withActivityLog)}
          <InternalNotesTab search={search} path={path} isKeystone={isKeystone} />
          <DeepfakeTab search={search} path={path} isKeystone={isKeystone} />
        </>
      ) : (
        <>
          {renderSummaryTab(search, path)}
          {renderPointsOfContactTab(search, path, transaction)}
          <RecipientTab
            search={search}
            path={path}
            transaction={transaction}
            isKeystone={isKeystone}
          />
          <DeepfakeTab search={search} path={path} isKeystone={isKeystone} />
          <IdentityDetailsTab
            search={search}
            path={path}
            transaction={transaction}
            isKeystone={isKeystone}
          />
          {renderWitnessTab(search, path, bundle.meetings)}
          <DeprecatedMeetingTabs
            search={search}
            path={path}
            transaction={transaction}
            showFullRecord={showFullRecord}
            isKeystone={isKeystone}
          />
          {withDocuments && renderDocumentTab(search, path, bundle)}
          {showHistoryTab && renderHistoryTab(search, path)}
          {renderNotaryNotesTab(search, path, withNotes)}
          {renderActivityLogsTab(search, path, withActivityLog)}
          <InternalNotesTab search={search} path={path} isKeystone={isKeystone} />
        </>
      )}
    </Tabs>
  );
}
