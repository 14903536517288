import { type ReactNode, useState } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";

import { usePermissions } from "common/core/current_user_role";
import { IconButton } from "common/core/button/icon_button";
import LoadingIndicator from "common/core/loading_indicator";
import modalScrollContent from "common/core/modal_scroll_content";
import { useMutation, useQuery } from "util/graphql";
import { FraudStatusEnum, FraudStatusInputEnum } from "graphql_globals";
import { Column } from "common/core/responsive";
import { captureException } from "util/exception";
import { pushNotification } from "common/core/notification_center/actions";
import { type ChildRenderProps } from "common/transactions/details";
import { useProofDefendUpsell, useProofDefend } from "util/feature_detection";

import IdentityDetailsQuery, {
  type IdentityDetails_documentBundle_DocumentBundle as DocumentBundle,
} from "./index.query.graphql";
import UpdateOrganizationTransactionCustomerFraudStatus from "./update_fraud_status.mutation.graphql";
import RiskAlertMessage from "./risk_alert_message";
import { ConfirmationModal } from "./fraud";
import { ProofDefendUpsellBanner } from "./marketing/defend";
import Styles from "./index.module.scss";
import { EmptyState } from "./empty_state";
import { Signer } from "./signer";
import { RiskModal } from "./risk_modal";
import { useRiskModalState } from "./risk_modal/state";

type TransactionDetailsBundle = Pick<ChildRenderProps["bundle"], "id">;
export type TransactionDetailsOrganization = Pick<
  ChildRenderProps["organization"],
  "id" | "proofDefend"
>;

type InnerProps = {
  documentBundle: DocumentBundle;
  organization: TransactionDetailsOrganization;
};

type OuterProps = {
  bundle: TransactionDetailsBundle;
  organization: TransactionDetailsOrganization;
};

export function Col({ children }: { children: ReactNode }) {
  return (
    <Column className={Styles.identityAttributeItem} xs={6} sm={4} lg={3}>
      {children}
    </Column>
  );
}

const Messages = defineMessages({
  showValue: {
    id: "37e8fcf3-d1cd-44b3-8097-fd206d295250",
    defaultMessage: "Show obfuscated value",
  },
  hideValue: {
    id: "d162195f-ad31-49b4-af05-901dbde6c3f2",
    defaultMessage: "Hide value",
  },
});

export function SensitiveValue({
  value,
  obfuscatedValue,
  automationId,
}: {
  obfuscatedValue: ReactNode;
  value: ReactNode;
  automationId?: string;
}) {
  const intl = useIntl();
  const [reveal, setReveal] = useState(false);

  return (
    <>
      {reveal ? value : obfuscatedValue}
      <IconButton
        onClick={() => setReveal(!reveal)}
        name={reveal ? "visibility-off" : "witness-filled"}
        label={intl.formatMessage(reveal ? Messages.showValue : Messages.hideValue)}
        automationId={automationId}
        buttonSize="condensed"
        variant="tertiary"
        buttonColor="dark"
      />
    </>
  );
}

type FraudActionState = {
  loading: boolean;
  action: FraudStatusInputEnum;
  selectedCustomerId: string;
} | null;

function IdentityDetails(props: InnerProps) {
  const { documentBundle, organization } = props;

  const { hasPermissionFor } = usePermissions();
  const [fraudActionState, setFraudActionState] = useState<FraudActionState>(null);
  const riskModalState = useRiskModalState();
  const updateCustomerFraudStatus = useMutation(UpdateOrganizationTransactionCustomerFraudStatus);
  const proofDefendUpsell = useProofDefendUpsell(organization);
  const proofDefend = useProofDefend(organization);

  const customerSigners = documentBundle.organizationTransaction.customerSigners;
  const signerIdentitiesTotal = customerSigners.reduce((a, b) => {
    return a + (b.signerIdentities?.length ?? 0);
  }, 0);

  const handleConfirmUpdateFraudStatus = ({ reason: fraudStatusReason }: { reason: string }) => {
    if (!fraudActionState?.selectedCustomerId || fraudActionState.loading) {
      return null;
    }

    setFraudActionState({
      ...fraudActionState,
      loading: true,
    });

    updateCustomerFraudStatus({
      variables: {
        input: {
          fraudStatus: fraudActionState.action,
          fraudStatusReason,
          transactionCustomerId: fraudActionState.selectedCustomerId,
        },
      },
    })
      .catch((error) => {
        captureException(error);
        pushNotification({
          title: (
            <FormattedMessage
              id="7ffb179b-584a-47b6-9182-ea1158bd0a50"
              defaultMessage="Failed to update signer's fraud status."
            />
          ),
          message: error.message,
        });
      })
      .finally(() => {
        setFraudActionState(null);
      });
  };

  return (
    <>
      {riskModalState.status === "open" && (
        <RiskModal
          customerSigners={customerSigners}
          customerSignerId={riskModalState.customerSignerId}
          signerIdentityId={riskModalState.signerIdentityId}
          onChangeAttempt={riskModalState.changeAttempt}
          onClose={riskModalState.closeModal}
        />
      )}
      {fraudActionState?.selectedCustomerId && (
        <ConfirmationModal
          onClose={() => {
            setFraudActionState(null);
          }}
          onContinue={handleConfirmUpdateFraudStatus}
          action={fraudActionState.action}
        />
      )}
      <div className={Styles.container}>
        {proofDefendUpsell && <ProofDefendUpsellBanner className={Styles.proofDefendBanner} />}
        <RiskAlertMessage
          customerSigners={customerSigners}
          proofDefend={proofDefend}
          openRiskModal={riskModalState.openModal}
        />
        {signerIdentitiesTotal === 0 ? (
          <EmptyState />
        ) : (
          customerSigners.map((customer, i) => {
            if (!customer.signerIdentities) {
              return null;
            }
            return (
              <Signer
                key={customer.id}
                customer={customer}
                organization={organization}
                openRiskModal={riskModalState.openModal}
                collapsible={customerSigners.length > 1}
                markAsFraud={
                  hasPermissionFor("markCustomerAsFraudulent")
                    ? () => {
                        setFraudActionState({
                          loading: false,
                          selectedCustomerId: customer.id,
                          action:
                            customer.fraudStatus?.status === FraudStatusEnum.FRAUD
                              ? FraudStatusInputEnum.REMOVE_FRAUD
                              : FraudStatusInputEnum.FRAUD,
                        });
                      }
                    : undefined
                }
                updatingFraudStatus={fraudActionState?.loading ?? false}
                index={i}
                organizationTransactionId={documentBundle.organizationTransaction.id}
              />
            );
          })
        )}
      </div>
    </>
  );
}

function IdentityDetailsWrapper({ bundle, organization }: OuterProps) {
  const { data, loading } = useQuery(IdentityDetailsQuery, {
    variables: { documentBundleId: bundle.id },
  });

  if (loading || !data) {
    return <LoadingIndicator />;
  }

  if (data.documentBundle?.__typename !== "DocumentBundle") {
    throw new Error(`Expected DocumentBundle, got ${data.documentBundle?.__typename}`);
  }

  return <IdentityDetails documentBundle={data.documentBundle} organization={organization} />;
}

export default modalScrollContent(IdentityDetailsWrapper);
