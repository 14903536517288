import type { ComponentProps } from "react";

import SignerValidation from "common/signer/items/signer_validation";
import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { DetailsDescriptionList } from "common/transactions/details/common";

import { SignerDetailsHeader } from "./signer_details_header";

type SignerValidationProps = ComponentProps<typeof SignerValidation>;
type Props = {
  headerText: ComponentProps<typeof SignerDetailsHeader>["children"];
  signerIdentity: SignerValidationProps["signerIdentity"];
  notaryState: SignerValidationProps["notaryState"];
  completionRequirements: SignerValidationProps["completionRequirements"];
  organizationFeatures: SignerValidationProps["organizationFeatures"];
  validatedByCredibleWitness: SignerValidationProps["validatedByCredibleWitness"];
};

function MeetingSignerDetails(props: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  return (
    <div>
      <SignerDetailsHeader>{props.headerText}</SignerDetailsHeader>
      <DetailsDescriptionList isTxnDetailsRedesign={isTxnDetailsRedesign}>
        <SignerValidation
          signerIdentity={props.signerIdentity}
          notaryState={props.notaryState}
          completionRequirements={props.completionRequirements}
          organizationFeatures={props.organizationFeatures}
          validatedByCredibleWitness={props.validatedByCredibleWitness}
        />
      </DetailsDescriptionList>
    </div>
  );
}

export default MeetingSignerDetails;
