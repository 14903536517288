import { FormattedMessage } from "react-intl";

import { PhotoIdVerificationStatus } from "graphql_globals";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { isCredentialAnalysisRequired } from "util/meeting";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { DescriptionListItem } from "common/core/description_list";
import { useTxnDetailsRedesign } from "util/feature_detection";
import { PassFailStatus } from "common/transactions/details/common";

type Props = {
  notaryState?: string;
  signerIdentity: null | {
    photoId: null | {
      documentClassification: string | null;
      documentIssuer: string | null;
      statusV2: PhotoIdVerificationStatus;
    };
  };
  titleClassName?: string;
};

export function CredentialStatus(props: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const photoId = props.signerIdentity?.photoId;
  if (!photoId) {
    return null;
  }

  const { documentIssuer, documentClassification, statusV2 } = photoId;
  const isRequired = Boolean(props.notaryState && isCredentialAnalysisRequired(props.notaryState));
  const isFailureStatus = statusV2 === PhotoIdVerificationStatus.FAILURE;

  // If credential analysis is not required and returns 'unknown', 'action required', or 'pending', we do not show value
  if (!isRequired && !isFailureStatus && statusV2 !== PhotoIdVerificationStatus.SUCCESS) {
    return null;
  }

  const credentialStatusLabel = (
    <div className={props.titleClassName}>
      <FormattedMessage
        id="072c10a0-cf79-4b1b-bd05-78be7cf6c8d4"
        defaultMessage="Credential Analysis"
      />
    </div>
  );

  const credentialStatusContent = (
    <PassFailStatus
      status={isFailureStatus ? "fail" : "pass"}
      data-automation-id="credential-analysis-status"
      customStatusText={
        <FormattedMessage
          id="0dc13795-ab83-4e19-b5e3-45552590f8d4"
          defaultMessage="{statusSelect, select, failed{Failed} required{{documentIssuer} {documentClassification}} other{Passed}}"
          values={{
            // If credential analysis is required, we show the values
            statusSelect: isFailureStatus ? "failed" : isRequired ? "required" : "other",
            documentIssuer,
            documentClassification,
          }}
        />
      }
    />
  );

  return isTxnDetailsRedesign ? (
    <DescriptionListItem term={credentialStatusLabel} definition={credentialStatusContent} />
  ) : (
    <DeprecatedDetailGridRow title={credentialStatusLabel}>
      {credentialStatusContent}
    </DeprecatedDetailGridRow>
  );
}
