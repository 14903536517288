import { type ReactNode, type ReactElement, type ComponentProps, useState } from "react";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import AlertMessage from "common/core/alert_message";

import { scrollToSection } from "../util";
import { TITLE_UNDERWRITER_FIELD_ID } from "../../sections/transaction_details";
import { DOCUMENT_SECTION_ID } from "../../sections/documents";
import { RECIPIENT_DETAILS_SECTION_ID } from "../../sections/recipient_details";
import { type SendTransactionOptions } from "../../util";

const NAME_MISMATCH_ERROR_CODE = 412;
export const ON_SEND_MODAL_ERRORS = ["transaction_not_serviceable_by_any_notary"];
export const NO_SIGNATURE_FLAG_ERROR = "No signature fields detected";
export const ON_SEND_MODAL_ERROR_CODES = [NAME_MISMATCH_ERROR_CODE];

export function useOnSendErrorHandler({
  sendTransaction,
}: {
  sendTransaction: (options: SendTransactionOptions) => Promise<void>;
}) {
  const [submitErrorModal, setSubmitErrorModal] = useState<ReactNode>(null);

  function clearErrorModal() {
    setSubmitErrorModal(null);
  }

  function onAcknowledgeError(elementId: string) {
    scrollToSection(elementId);
    setSubmitErrorModal(null);
  }

  const onSendErrorHandler = (
    errorMessage: string,
    errorCode?: number,
    sendTransactionOptions?: SendTransactionOptions,
  ): void => {
    if (errorMessage === "transaction_not_serviceable_by_any_notary") {
      setSubmitErrorModal(
        <ErrorModal
          title={
            <FormattedMessage
              id="584eafb6-d532-49e5-9e97-ec0a8b48d3f4"
              defaultMessage="Select a different title underwriter"
            />
          }
          buttons={[
            <Button
              key="acknowledge"
              variant="primary"
              buttonColor="action"
              onClick={() => {
                onAcknowledgeError(TITLE_UNDERWRITER_FIELD_ID);
              }}
            >
              <FormattedMessage
                id="da8e45a7-e0c4-4645-811e-d8ff354d9cb6"
                defaultMessage="Continue"
              />
            </Button>,
          ]}
          onCancel={clearErrorModal}
          errorMessage={
            <FormattedMessage
              id="1830cd91-9890-4a97-aabc-9b9cbad78ac0"
              defaultMessage="There is no notary that can meet the transaction requirements set by the title underwriter. Please select a different title underwriter."
            />
          }
        />,
      );
    } else if (errorMessage.includes(NO_SIGNATURE_FLAG_ERROR)) {
      setSubmitErrorModal(
        <ErrorModal
          title={
            <FormattedMessage
              id="7f95697e-07b2-4b1d-ac38-7d2e172e17fd"
              defaultMessage="No signature fields detected"
            />
          }
          buttons={[
            <Button
              key="acknowledge"
              variant="primary"
              buttonColor="action"
              onClick={() => {
                onAcknowledgeError(DOCUMENT_SECTION_ID);
              }}
            >
              <FormattedMessage
                id="43f475b6-b162-4792-a51f-1ab4f9074dae"
                defaultMessage="Go to documents"
              />
            </Button>,
          ]}
          onCancel={clearErrorModal}
          errorMessage={errorMessage}
        />,
      );
    } else if (errorCode === 412) {
      setSubmitErrorModal(
        <ErrorModal
          title={
            <FormattedMessage
              id="5dbe2b07-202b-40b7-9ef9-f79f9d4a2a06"
              defaultMessage="Signer account name mismatch"
            />
          }
          buttons={[
            <Button
              key="acknowledge"
              variant="primary"
              buttonColor="action"
              onClick={() => {
                onAcknowledgeError(RECIPIENT_DETAILS_SECTION_ID);
              }}
            >
              <FormattedMessage
                id="4791ff8e-ff14-46e5-835a-2e18a434c5f5"
                defaultMessage="Edit name"
              />
            </Button>,
            <Button
              key="override"
              variant="tertiary"
              buttonColor="action"
              onClick={async () => {
                await sendTransaction({ ...sendTransactionOptions, closingOpsOverride: true });
                clearErrorModal();
              }}
            >
              <FormattedMessage
                id="41040c74-29a6-42ff-bf1c-6ec5c71fa391"
                defaultMessage="Send transaction"
              />
            </Button>,
          ]}
          onCancel={clearErrorModal}
          errorMessage={errorMessage}
          kind="warning"
        />,
      );
    }
  };

  return {
    onSendErrorHandler,
    onsendErrorModal: submitErrorModal,
  };
}

function ErrorModal({
  onCancel,
  buttons,
  errorMessage,
  title,
  kind = "danger",
}: {
  onCancel: () => void;
  errorMessage: ReactNode;
  title: ReactNode;
  buttons: ReactElement<ComponentProps<typeof Button>>[];
  kind?: "danger" | "warning";
}) {
  return (
    <WorkflowModal
      title={title}
      closeBehavior={{ tag: "with-button", onClose: onCancel }}
      buttons={buttons}
      automationId="on-send-error-modal"
    >
      <AlertMessage kind={kind}>{errorMessage}</AlertMessage>
    </WorkflowModal>
  );
}
