import { differenceInSeconds } from "date-fns";

import {
  MeetingEndedState,
  DocumentBundleParticipantStatus,
  DocumentBundleParticipantActivity,
  NotaryToolbarRequirements,
  NotaryCertificateProvider,
  Payer,
  CertificateMigrationStatus,
  SigningRequirementEnum,
  type SandboxMeetingName,
} from "graphql_globals";
import { DEFAULT_FONT_OPTIONS } from "util/signature_options";
import { userFullName } from "util/user";

import { mismatchedSignatures, getAnnotations } from "./annotation";
import {
  makeDocuments,
  allDocumentsComplete,
  getDocumentUnfulfilledRequirements,
  getCurrentDocumentId,
} from "./document";
import { getNotaryUser, USER_TYPE_RESOLVERS, verifySI } from "./user";
import type { OverrideLookup } from ".";
import { FAKE_SIGNER } from "./identity";

type CompleteCallback = (stats: { duration: number; annotationCount: number }) => Promise<unknown>;

let START_TIME: Date;
let REQ_CRED_ANALYSIS: boolean;
let ENDED_STATE: MeetingEndedState;
let onComplete: CompleteCallback;
let meetingId: string;
let meetingName: SandboxMeetingName;

function add(accum: number, additive: number) {
  return accum + additive;
}

function meetingCanComplete() {
  return !REQ_CRED_ANALYSIS && allDocumentsComplete(meetingName);
}

function makeMeeting() {
  const notaryUser = getNotaryUser();
  const unfulfilledRequirementsDocs = getDocumentUnfulfilledRequirements(meetingName);
  const org = {
    id: "orozd74nr",
    featureFlags: [],
    remoteOdnWitnessEnabled: true,
    featureList: [],
    signatureOptions: {
      allowFonts: DEFAULT_FONT_OPTIONS,
      allowHandwritten: true,
      allowTextbased: true,
      default: true,
      enabled: false,
    },
  };
  const transaction = {
    id: "ot509c2d72-d40e-464f-83ab-9bbcd992a685",
    payer: Payer.CUSTOMER,
    isMortgage: false,
    employee: {
      id: "us123employee",
    },
    contacts: [],
    transactionType: "unknown",
    foreignIdAccepted: false,
    publicOrganization: org,
    organization: org,
    organizationTransactionWitnesses: [],
    titleAgency: null,
    streetAddress: null,
    isRetail: true,
    expiry: null,
    customerSigners: [
      {
        __typename: "TransactionCustomer",
        id: "ocn9r5bp9",
        userId: "usnkxmxpd",
        firstName: FAKE_SIGNER.firstName,
        middleName: null,
        lastName: FAKE_SIGNER.lastName,
      },
    ],
  };
  return {
    id: meetingId,
    queuedWitnessRequestCount: 0,
    pollingId: meetingId,
    __typename: "Meeting",
    currentDocumentId: getCurrentDocumentId(),
    conversationSid: "",
    notary: {
      id: "us123",
      notary_profile: {
        id: "npwhateever",
        certificateProvider: NotaryCertificateProvider.IDENTRUST,
        certificateMigrationStatus: CertificateMigrationStatus.LEGACY_CERTIFICATE,
        isAttorney: notaryUser.notaryProfile!.isAttorney,
      },
    },
    meetingParticipants: [
      {
        __typename: "SignerParticipant",
        id: "8ab068bf-16b4-4f04-922a-79bdee00e009",
        role: "SIGNER",
        chatAccessToken: null,
        fullName: FAKE_SIGNER.fullName,
        firstName: FAKE_SIGNER.firstName,
        pronouns: "She/her",
        middleName: "",
        lastName: FAKE_SIGNER.lastName,
        dob: "1990-10-10",
        label: null,
        platform: "WEB",
        address: null,
        organizationName: null,
        userId: "usnkxmxpd",
        parentId: null,
        isConnected: true,
        requiresBiometrics: false,
        videoConferenceIdentity: "8ab068bf-16b4-4f04-922a-79bdee00e009",
        videoConferenceIdentityOld: "usnkxmxpd",
        activeCall: null,
        personallyKnownToNotary: false,
        notaryConfirmedPersonallyKnownToNotary: false,
        signingAssets: {
          font: null,
          signatureAsset: null,
          initialsAsset: null,
        },
        signingLocationAddress: {
          country: "US",
          state: "MA",
        },
        availableNotarialActs: [
          "ACKNOWLEDGEMENT",
          "COPY_CERTIFICATION",
          "JURAT",
          "OATH_AFFIRMATION",
          "VERIFICATION_OF_FACT_PS1583",
        ],
        signerIdentityId: "sid5m4g5j",
        isCurrentPenHolder: true,
        requiresCredentialAnalysis: REQ_CRED_ANALYSIS,
        photoId: null,
        secondaryId: {
          url: "awsKey",
          __typename: "SecureUrl",
        },
        photoIdVerified: false,
        colorHex: "#003FA3",
        signerRole: {
          index: "usnkxmxpd",
          role: "SIGNER",
          __typename: "SignerRole",
        },
      },
      {
        __typename: "NotaryParticipant",
        id: "bb453cbe-db27-4dbc-8f6d-124f2fd49366",
        role: "NOTARY",
        chatAccessToken: null,
        fullName: userFullName(notaryUser),
        firstName: notaryUser.firstName,
        middleName: notaryUser.middleName,
        lastName: notaryUser.lastName,
        pronouns: notaryUser.pronouns,
        label: null,
        organizationName: null,
        userId: notaryUser.id,
        notaryId: notaryUser.notaryProfile!.id,
        parentId: null,
        isConnected: true,
        background: null,
        videoConferenceIdentity: "bb453cbe-db27-4dbc-8f6d-124f2fd49366",
        videoConferenceIdentityOld: "usd7j6jzn",
        activeCall: null,
        isCurrentPenHolder: true,
        allowedRemoteWitness: false,
        usStateName: notaryUser.notaryProfile!.usState.name,
        notaryToolbarRequirements: [
          NotaryToolbarRequirements.NOTARY_STATE,
          NotaryToolbarRequirements.NOTARY_COUNTY,
          NotaryToolbarRequirements.DATE_OF_NOTARIAL_ACT,
          NotaryToolbarRequirements.NOTARY_NAME,
          NotaryToolbarRequirements.PRINCIPAL_NAME,
          NotaryToolbarRequirements.NOTARY_SIGNATURE,
          NotaryToolbarRequirements.DISCLAIMER,
          NotaryToolbarRequirements.COMMISSION_EXPIRATION,
          NotaryToolbarRequirements.DISCLOSURE,
          NotaryToolbarRequirements.NOTARY_SEAL,
          NotaryToolbarRequirements.COMMISSION_NUMBER,
        ],
        notaryDisclaimerText: "Electronic Notary Public",
      },
    ],
    platform: "WEB",
    sequentiallySigning: false,
    organizationTransaction: transaction,
    documentBundle: {
      id: "dbe18298b3-ce4f-4350-b79e-f6a489ccddec",
      completionRequirements: ["NOTARIZATION"],
      requiredFeatures: [],
      organizationTransaction: transaction,
      batchSigning: true,
      instructions: [],
      __typename: "DocumentBundle",
      payer: "CUSTOMER",
      tier: {
        id: "tier",
        prices: {
          remoteOdnWitness: 1000,
        },
      },
      organization: null,
      isMortgage: false,
      documents: makeDocuments(meetingName),
      concurrentSigning: false,
      participants: [
        {
          id: "879f4e45-a456-4440-9667-1c3d09895983",
          userId: "usnkxmxpd",
          signingStatus: DocumentBundleParticipantStatus.INCOMPLETE,
          signingActivity: DocumentBundleParticipantActivity.SIGNING_IN_MEETING,
          __typename: "DocumentBundleParticipant",
          firstName: FAKE_SIGNER.firstName,
          middleName: "",
          lastName: FAKE_SIGNER.lastName,
          signerRole: {
            index: "usnkxmxpd",
            role: "SIGNER",
            __typename: "SignerRole",
          },
          colorHex: "#003FA3",
          representation: null,
          signingRequirement: SigningRequirementEnum.NOTARIZATION,
        },
      ],
      canUpdate: false,
    },
    unfulfilledRequirements: {
      missingParticipantRoles: [],
      __typename: "MeetingUnfulfilledRequirementsType",
      numOfUnfulfilledDesignations: 0,
      numOfUnfulfilledEnoteDesignations: 0,
      numOfUnfulfilledSeals: unfulfilledRequirementsDocs
        .map((req) => req.num_of_unfulfilled_seals)
        .reduce(add, 0),
      numOfSealsWithoutSignatures: mismatchedSignatures(),
      numOfUnfulfilledSignatures: unfulfilledRequirementsDocs
        .map((req) => req.num_of_unfulfilled_signatures)
        .reduce(add, 0),
      numOfUnverifiedSignerIdentities: REQ_CRED_ANALYSIS ? 1 : 0,
      numOfMissingOnlineDisclosures: 0,
      unfulfilledRequirements: unfulfilledRequirementsDocs,
      numOfMissingRequiredSealsForTransaction: 0,
    },
    endedState: ENDED_STATE,
    organizationName: null,
    timeFrame: {
      startedAt: START_TIME.toISOString(),
      __typename: "TimeInterval",
    },
    videoConference: { __typename: "TwilioVideoConference" },
    video: {
      id: "RMa90edb9e76de972e61278c9714858275",
      token: "",
      __typename: "VideoConferenceSession",
    },
    canComplete: meetingCanComplete(),
    signerCapabilities: ["request_in_meeting_document_upload"],
    publicNotaryDetails: {
      notaryId: notaryUser.id,
      commissionExpiration: "2023-01-10",
      __typename: "PublicNotaryDetails",
    },
  };
}

function logStats() {
  return onComplete({
    duration: differenceInSeconds(new Date(), START_TIME),
    annotationCount: getAnnotations().length,
  });
}

export function setupMeeting(
  generatedMeetingId: string,
  setupMeetingName: SandboxMeetingName,
  onCompleteCb: CompleteCallback,
) {
  meetingId = generatedMeetingId;
  meetingName = setupMeetingName;
  onComplete = onCompleteCb;
  START_TIME = new Date();
  REQ_CRED_ANALYSIS = true;
  ENDED_STATE = MeetingEndedState.NOT_COMPLETED;
}

export const MEETING_TYPE_RESOLVERS: OverrideLookup = Object.freeze({
  SendAVFailureNotificationPayload: () => {
    throw new Error("Not allowed in simulator");
  },
  UpdatePhotoIdentificationPayload: () => {
    REQ_CRED_ANALYSIS = false;
    verifySI();
    return {
      signerIdentity: USER_TYPE_RESOLVERS.SignerIdentity(),
    };
  },
  TriggerSignerCallbackPayload: () => {
    throw new Error("missing_callback_number");
  },
  CompleteMeetingPayload: async (root, args) => {
    const { success } = args.input;
    if (success) {
      await logStats();
      ENDED_STATE = MeetingEndedState.COMPLETED;
    } else {
      ENDED_STATE = MeetingEndedState.NOTARY_CANCELLED_NO_CHARGE;
    }
    return {
      errors: null,
      meeting: makeMeeting(),
    };
  },
  Meeting: makeMeeting,
});
