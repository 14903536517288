import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "common/core/button";
import { useOnboardingV2 } from "util/feature_detection";

import Styles from "./index.module.scss";

type Props = {
  onBack?: () => void;
  onSubmit: (formValues?: undefined) => Promise<unknown> | { finally: undefined };
  workToCommit?: boolean;
  disabled?: boolean;
  lastStep?: boolean;
};

function Footer({ onBack, onSubmit, disabled, workToCommit, lastStep }: Props) {
  const [loading, setLoading] = useState(false);
  const onboardingV2 = useOnboardingV2();

  return (
    <footer className={Styles.footer}>
      {onboardingV2 && onBack && (
        <Button
          className={Styles.backButton}
          buttonSize="large"
          variant="tertiary"
          buttonColor="dark"
          onClick={() => onBack()}
        >
          <FormattedMessage id="08de969d-2b3a-425e-bf45-3a766babe6ee" defaultMessage="Back" />
        </Button>
      )}
      <Button
        className={onboardingV2 ? Styles.nextButton : ""}
        buttonSize="large"
        buttonColor="action"
        variant="primary"
        automationId="onboarding-next"
        isLoading={loading}
        disabled={disabled}
        onClick={() => {
          const potentialPromise = onSubmit();
          if (potentialPromise.finally) {
            setLoading(true);
            // Assume we only unlock on failure...
            potentialPromise.finally(() => setLoading(false));
          }
        }}
      >
        {onboardingV2 && lastStep ? (
          <FormattedMessage
            id="236016f7-1556-4b97-a133-05f055ba1a75"
            defaultMessage="Submit Profile"
          />
        ) : workToCommit ? (
          <FormattedMessage id="a9e500c8-a044-49ee-a61b-694e6c87f123" defaultMessage="Next" />
        ) : (
          <FormattedMessage
            id="c75cc01e-21e2-4ca4-a079-21601d4f2593"
            defaultMessage="Let's get started"
          />
        )}
      </Button>
    </footer>
  );
}

export default memo(Footer);
