import type { NotaryProfileProofAcademy_notaryProfile as NotaryProfile } from "./index_fragment.graphql";

type ProofAcademySectionType = {
  id: "ProofAcademy";
  completed: boolean;
};

export function proofAcademySection(notaryProfile: NotaryProfile): ProofAcademySectionType {
  return {
    id: "ProofAcademy",
    completed:
      notaryProfile.trainings.length > 0 &&
      notaryProfile.trainings.every((training) => training.completedAt !== null),
  };
}
