import { useEffect, type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { reduxForm, type InjectedFormProps, SubmissionError } from "redux-form";
import { isPast } from "date-fns";
import { parsePhoneNumberWithError as parsePhoneNumber } from "libphonenumber-js";

import {
  NotaryDocumentTypes,
  ValidationRequirements,
  NotaryProfileInvalidFields as InvalidFields,
} from "graphql_globals";
import { b } from "util/html";
import { composeValidators } from "util/form";
import { normalizeToNumber } from "util/normalize";
import { splitDate } from "util/date";
import {
  validatePresence,
  validateFutureDay,
  validateIf,
  validatePhoneNumberLength,
  validatePhoneNumberStartsWithValidAreacode,
} from "validators/form";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import FormRow from "common/form/elements/row";
import FormGroup from "common/form/group";
import FormGroupErrors from "common/form/group_errors";
import MultipartColumn from "common/form/inputs/multipart/column";
import MultipartRow from "common/form/inputs/multipart/row";
import TextField from "common/form/fields/text";
import MonthField from "common/form/fields/month";
import NotaryProfileWizardExandableHelp from "common/notary/profile_wizard/expandable_help";
import Icon from "common/core/icon";
import { NotaryProfileWizardAssetUploader } from "common/notary/profile_wizard/upload";
import { openSupportChat } from "common/support/chat";
import type { FormError } from "errors/util";
import { customMessage } from "errors/form";
import { useMutation } from "util/graphql";
import { isGraphQLError } from "util/graphql/query";
import {
  NNASection,
  hasNNASection,
  useRefreshSubmit,
  NNA_MISSING_EXAM_OR_BACKGROUND_CHECK,
} from "common/notary/profile_wizard/section/commission_and_ron_info/nna";

import UpdateNnaInformationMutation from "./update_nna_information_mutation.graphql";
import {
  findDocumentLabel,
  initializeNotaryDocumentField,
  useChangeFileCallback,
  getNnaErrorMessage,
  getCommissionErrorMessage,
  isWisconsinAttorney,
  type FileInfo,
  type SubmitType,
} from "../section_utils";
import type {
  NotaryProfileWizardCommissionDetails as User,
  NotaryProfileWizardCommissionDetails_notaryProfile as NotaryProfile,
} from "./index_fragment.graphql";

type FormValues = {
  county: string | null;
  notaryId: string | null;
  commissionExpiryDay: string;
  commissionExpiryMonth: string;
  commissionExpiryYear: string;
  nnaTrainingExpirationDay: string;
  nnaTrainingExpirationMonth: string;
  nnaTrainingExpirationYear: string;
  commissionKey: FileInfo;
  nnaId: string | null;
  phone: string | null;
};

type Input = {
  county: string | null;
  notaryId: string | null;
  licenseExpiry: string | null;
  notaryDocuments: { keys: string[]; name: null | string; documentType: NotaryDocumentTypes }[];
  phone: string | null;
};
type Props = {
  user: User;
  onNext: (input: Input | null, skipConfig?: "DontNavigate") => Promise<unknown>;
  countyRequired: boolean;
  notaryIdRequired: boolean;
  renderFooter: (handleSubmit: () => SubmitType) => ReactNode;
};
type InnerProps = InjectedFormProps<FormValues, Props> & Props;
type FormSubmitOptions = {
  onNext: Props["onNext"];
  notaryProfileId: string;
  countyRequired: boolean;
  notaryIdRequired: boolean;
  isWisconsinAttorney: boolean;
  change: InnerProps["change"];
};
type CommissionDetailsType =
  | {
      id: "CommissionDetails";
      countyRequired: boolean;
      notaryIdRequired: boolean;
      completed: boolean;
    }
  | false;

const TRADITIONAL_COMMISSION_LABEL = (
  <FormattedMessage
    id="9b81f65d-c5a1-47f4-a44f-a7dde360699f"
    defaultMessage="Traditional Commission Document"
  />
);

export function commissionDetailsSection(
  lookup: Set<ValidationRequirements>,
  notaryProfile: NotaryProfile,
): CommissionDetailsType {
  const fields = [
    InvalidFields.MISSING_NOTARY_ID,
    InvalidFields.MISSING_COUNTY,
    InvalidFields.INVALID_LICENSE_EXPIRY,
    InvalidFields.INVALID_PAPER_COMMISSION_DOCUMENT,
  ];
  const completed = !fields.some((field) => notaryProfile.validation.invalidFields.includes(field));
  const sectionRequired =
    lookup.has(ValidationRequirements.COMMISSION_EXPIRATION) ||
    lookup.has(ValidationRequirements.PAPER_COMMISSION);
  return (
    sectionRequired && {
      id: "CommissionDetails",
      countyRequired: lookup.has(ValidationRequirements.COMMISSION_COUNTY),
      notaryIdRequired: lookup.has(ValidationRequirements.COMMISSION_NUMBER),
      completed,
    }
  );
}

function useFormSubmit({
  onNext,
  notaryProfileId,
  countyRequired,
  notaryIdRequired,
  isWisconsinAttorney,
  change,
}: FormSubmitOptions) {
  const updateNnaInformationMutation = useMutation(UpdateNnaInformationMutation);
  return async function submitCommissionDetails(fv: FormValues) {
    await onNext(
      {
        county: countyRequired ? fv.county : null,
        notaryId: notaryIdRequired ? fv.notaryId : null,
        licenseExpiry: isWisconsinAttorney
          ? null
          : `${fv.commissionExpiryYear}-${fv.commissionExpiryMonth}-${fv.commissionExpiryDay}`,
        notaryDocuments:
          typeof fv.commissionKey?.key === "string"
            ? [
                {
                  keys: [fv.commissionKey.key],
                  name: fv.commissionKey.file?.name || null,
                  documentType: NotaryDocumentTypes.PAPER_COMMISSION,
                },
              ]
            : [],
        phone: fv.phone?.length ? fv.phone : null,
      },
      "DontNavigate",
    );
    if (fv.nnaId) {
      const { data } = await updateNnaInformationMutation({
        variables: {
          input: {
            notaryProfileId,
            nnaId: String(fv.nnaId),
            refresh: true,
          },
        },
      });
      const { backgroundCheckExpiry, nnaTrainingExpiry } =
        data!.updateNotaryNnaInformation!.notaryProfile;
      if (!backgroundCheckExpiry || !nnaTrainingExpiry) {
        throw new SubmissionError<FormValues, FormError>({
          nnaId: customMessage({
            message: NNA_MISSING_EXAM_OR_BACKGROUND_CHECK,
          }),
        });
      } else if (isPast(new Date(backgroundCheckExpiry)) || isPast(new Date(nnaTrainingExpiry))) {
        throw new SubmissionError<FormValues, FormError>({
          nnaId: customMessage({
            message: (
              <FormattedMessage
                id="78cefc6f-89d5-4056-a1db-73199f5fbdd3"
                defaultMessage="Sorry, either your course certification or background check has expired. Please confirm your information on your NNA profile"
              />
            ),
          }),
        });
      }

      if (nnaTrainingExpiry) {
        const [nnaTrainingExpirationYear, nnaTrainingExpirationMonth, nnaTrainingExpirationDay] =
          splitDate(nnaTrainingExpiry);

        change("nnaTrainingExpirationYear", nnaTrainingExpirationYear);
        change("nnaTrainingExpirationMonth", nnaTrainingExpirationMonth);
        change("nnaTrainingExpirationDay", nnaTrainingExpirationDay);
      }
    }

    return onNext(null);
  };
}

function CommissionDetails({
  user,
  countyRequired,
  notaryIdRequired,
  initialize,
  change,
  handleSubmit,
  onNext,
  renderFooter,
}: InnerProps) {
  const notaryProfile = user.notaryProfile!;
  const nnaAvailable = hasNNASection(notaryProfile);
  const { phoneNumber } = notaryProfile;
  useEffect(() => {
    const {
      county,
      notaryId,
      traditionalCommissionExpiryDate,
      nnaTrainingExpiry,
      notaryDocuments,
      nnaId,
    } = notaryProfile;
    const [commissionExpiryYear, commissionExpiryMonth, commissionExpiryDay] = splitDate(
      traditionalCommissionExpiryDate,
    );
    const [nnaTrainingExpirationYear, nnaTrainingExpirationMonth, nnaTrainingExpirationDay] =
      splitDate(nnaTrainingExpiry);

    initialize({
      county: county || "",
      notaryId: notaryId || "",
      commissionKey: initializeNotaryDocumentField(
        notaryDocuments,
        NotaryDocumentTypes.PAPER_COMMISSION,
      ),
      nnaId: nnaId || "",
      phone: phoneNumber ? parsePhoneNumber(phoneNumber).formatNational() : "",
      commissionExpiryDay,
      commissionExpiryYear,
      commissionExpiryMonth,
      nnaTrainingExpirationDay,
      nnaTrainingExpirationYear,
      nnaTrainingExpirationMonth,
    });
  }, []);

  const handleFormSubmit = useFormSubmit({
    onNext,
    countyRequired,
    notaryIdRequired,
    notaryProfileId: notaryProfile.id,
    isWisconsinAttorney: isWisconsinAttorney(notaryProfile),
    change,
  });
  const serializeForm = (fv: FormValues) =>
    handleFormSubmit(fv).catch((error) => {
      const graphErrorMessage = isGraphQLError(error) && error.message;
      if (graphErrorMessage && getNnaErrorMessage(graphErrorMessage)) {
        throw new SubmissionError<FormValues, FormError>({
          nnaId: customMessage({ message: getNnaErrorMessage(graphErrorMessage) }),
        });
      } else if (graphErrorMessage && getCommissionErrorMessage(graphErrorMessage)) {
        throw new SubmissionError<FormValues, FormError>({
          notaryId: customMessage({ message: getCommissionErrorMessage(graphErrorMessage) }),
        });
      }
      throw error;
    });

  const handleCommissionDocChange = useChangeFileCallback("commissionKey", change);

  const refreshNnaData = useRefreshSubmit({
    change,
    formName: "notaryOnboarding",
    notaryProfileId: notaryProfile.id,
    notaryBackgroundCheckExpiry: notaryProfile.backgroundCheckExpiry,
    notaryNnaTrainingExpiration: notaryProfile.nnaTrainingExpiry,
  });

  const onCheatClick = () => {
    change("nnaId", "000000000");
  };

  return (
    <>
      <div>
        <FormattedMessage
          id="c5aa229e-96b9-4e4b-bd96-4c9693162fc3"
          defaultMessage="Commission Details"
          tagName="h3"
        />
        <FormattedMessage
          id="865a5e52-e938-4a0b-87dc-8a44c4796c7a"
          defaultMessage="Enter your commission details and upload a copy of your traditional commission."
          tagName="p"
        />

        <SubForm>
          <FormattedMessage
            id="7f839081-9425-494a-aee1-77f1f4830e7c"
            defaultMessage="Commissioned State: <b>{state}</b>"
            values={{ state: notaryProfile.usState.name, b }}
            tagName="p"
          />
          <span className="subtext">
            <FormattedMessage
              id="19d32914-6458-4cba-90f6-9b1ae5dcd865"
              defaultMessage="(If this information is incorrect, please <a>reach out to support</a> to have it updated)"
              values={{
                a: (msg: ReactNode[]) => <a onClick={() => openSupportChat()}>{msg}</a>,
              }}
            />
          </span>
          {countyRequired && (
            <SubFormSection fullWidth>
              <FormRow>
                <FormattedMessage
                  id="ca7dfc6a-4623-4406-ae3e-91bf7691f7f6"
                  defaultMessage="Commissioned County"
                  tagName="label"
                />
                <TextField id="county" name="county" automationId="county" useStyledInput />
                <FormGroupErrors fields={["county"]} />
              </FormRow>
            </SubFormSection>
          )}

          {notaryIdRequired && (
            <SubFormSection fullWidth>
              <FormRow>
                <FormattedMessage
                  id="6b7ddab5-a812-4769-97ce-973f665be7d1"
                  defaultMessage="Commission Number"
                  tagName="label"
                />
                <TextField name="notaryId" automationId="notaryId" useStyledInput />
                <FormGroupErrors fields={["notaryId"]} />
              </FormRow>
            </SubFormSection>
          )}
          {!isWisconsinAttorney(notaryProfile) && (
            <>
              <SubFormSection fullWidth>
                <FormGroup
                  disableFormRowStyle
                  fields={["commissionExpiryYear", "commissionExpiryMonth", "commissionExpiryDay"]}
                >
                  <FormattedMessage
                    id="f26e1926-51e3-492b-a95c-e5c16a3b9f39"
                    defaultMessage="Commission Expiration Date"
                    tagName="label"
                  />
                  <MultipartRow>
                    <MultipartColumn width={6}>
                      <MonthField
                        name="commissionExpiryMonth"
                        useStyledInput
                        searchable={false}
                        clearable={false}
                        automationId="expiryMonth"
                      />
                    </MultipartColumn>
                    <MultipartColumn width={2}>
                      <TextField
                        name="commissionExpiryDay"
                        placeholder="DD"
                        normalize={normalizeToNumber}
                        maxLength="2"
                        useStyledInput
                        automationId="expiryDay"
                      />
                    </MultipartColumn>
                    <MultipartColumn width={4}>
                      <TextField
                        name="commissionExpiryYear"
                        placeholder="YYYY"
                        normalize={normalizeToNumber}
                        maxLength="4"
                        useStyledInput
                        automationId="expiryYear"
                      />
                    </MultipartColumn>
                  </MultipartRow>

                  <FormGroupErrors
                    fields={[
                      "commissionExpiryDay",
                      "commissionExpiryMonth",
                      "commissionExpiryYear",
                    ]}
                  />
                </FormGroup>
              </SubFormSection>
              <NotaryProfileWizardExandableHelp
                expansion={
                  <FormattedMessage
                    id="dbe97ea4-6a21-4bea-b007-291410438b27"
                    defaultMessage="If your commission expires within the next 30 days, we recommend you start the renewal process as soon as possible. You will not be able to use our platform once your commission expires. You can always resubmit updated information once you have it."
                  />
                }
              >
                <FormattedMessage
                  id="70351557-160e-4464-9d1c-0f9406e51d64"
                  defaultMessage="Does your commission expire within the next 30 days? {downCaret}"
                  values={{ downCaret: <Icon name="caret-down" /> }}
                />
              </NotaryProfileWizardExandableHelp>
            </>
          )}

          <SubFormSection fullWidth>
            <FormattedMessage
              id="8ca11d58-e977-4a54-a8c3-26955a064737"
              defaultMessage="Upload a copy of your traditional commission"
              tagName="p"
            />
            <NotaryProfileWizardAssetUploader
              persistedValue={findDocumentLabel(
                notaryProfile.notaryDocuments,
                NotaryDocumentTypes.PAPER_COMMISSION,
                TRADITIONAL_COMMISSION_LABEL,
              )}
              onChange={handleCommissionDocChange}
            />
            <FormGroupErrors fields={["commissionKey"]} />
          </SubFormSection>
          {nnaAvailable && (
            <NNASection
              notaryProfile={notaryProfile}
              refreshNnaData={notaryProfile.nnaId ? handleSubmit(refreshNnaData) : undefined}
              onCheatClick={onCheatClick}
            />
          )}
        </SubForm>
      </div>
      {renderFooter(handleSubmit(serializeForm))}
    </>
  );
}

export default reduxForm<FormValues, Props>({
  form: "notaryOnboarding",
  validate: (values, props) =>
    composeValidators(
      validateIf({
        field: "county",
        condition: () => props.countyRequired,
        validation: validatePresence({ field: "county", label: "County" }),
      }),
      validateIf({
        field: "notaryId",
        condition: () => props.notaryIdRequired,
        validation: validatePresence({ field: "notaryId", label: "Commission number" }),
      }),
      validateIf({
        field: "phone",
        condition: () => Boolean(values.phone?.length),
        validation: validatePhoneNumberStartsWithValidAreacode({ field: "phone" }),
      }),
      validateIf({
        field: "phone",
        condition: () => Boolean(values.phone?.length),
        validation: validatePhoneNumberLength({
          isInternational: false,
          field: "phone",
          label: "NNA Phone Number",
        }),
      }),
      validatePresence({
        field: "commissionKey",
        label: "Uploaded copy of traditional commission",
      }),
      validateIf({
        field: "commissionExpiryDay",
        condition: () => !isWisconsinAttorney(props.user.notaryProfile!),
        validation: validatePresence({
          field: "commissionExpiryDay",
          label: "Commission expiration day",
        }),
      }),
      validateIf({
        field: "commissionExpiryMonth",
        condition: () => !isWisconsinAttorney(props.user.notaryProfile!),
        validation: validatePresence({
          field: "commissionExpiryMonth",
          label: "Commission expiration month",
        }),
      }),
      validateIf({
        field: "commissionExpiryYear",
        condition: () => !isWisconsinAttorney(props.user.notaryProfile!),
        validation: validatePresence({
          field: "commissionExpiryYear",
          label: "Commission expiration year",
        }),
      }),
      validateIf({
        field: "commissionExpiryYear",
        condition: () => !isWisconsinAttorney(props.user.notaryProfile!),
        validation: validateFutureDay({
          field: "commissionExpiryYear",
          label: "Commission expiration",
          monthField: "commissionExpiryMonth",
          dayField: "commissionExpiryDay",
          yearField: "commissionExpiryYear",
        }),
      }),
    )(values),
})(CommissionDetails);
