import { useEffect, type ReactNode } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import classnames from "classnames";

import { useMatchScreenClass } from "common/core/responsive";
import Link from "common/core/link";
import {
  BUSINESS_PRICING_URL,
  MARKETING_HOST,
  NOTARIZE_NOTARY_URL,
  TITLE_PRICING_URL,
} from "constants/marketing";
import AppSubdomains from "constants/app_subdomains";
import { FullLogo } from "common/core/logo";
import { redirectUrl } from "util/application_redirect";
import { pendoInitialize } from "util/pendo";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import { Heading, Paragraph } from "common/core/typography";
import { AccountBlurb } from "common/proof_frame/global_nav/proof_id_widget";
import UnauthenticatedAppFrame from "common/app_frame/unauthenticated";
import { useProMobileOnboarding } from "util/feature_detection";

import Styles from "./index.module.scss";

type Props = {
  children: ReactNode;
};

const MESSAGES = defineMessages({
  logoAlt: {
    id: "8577fe60-d63c-4c9d-af40-ea71093c751d",
    defaultMessage: "Proof.com home page",
  },
});

function GraphicContainer({ children }: Props) {
  const intl = useIntl();
  const isExtraSmall = useMatchScreenClass("xs");
  const proMobileOnboardingEnabled = useProMobileOnboarding();

  useEffect(() => {
    pendoInitialize();
  }, []);

  if (isExtraSmall) {
    return (
      <UnauthenticatedAppFrame isMobileSignUp={proMobileOnboardingEnabled}>
        {children}
      </UnauthenticatedAppFrame>
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <ButtonStyledLink href={MARKETING_HOST} automationId="home-link">
          <FullLogo whiteLogo className={Styles.logo} alt={intl.formatMessage(MESSAGES.logoAlt)} />
        </ButtonStyledLink>
        <div className={Styles.rightContent}>
          <FormattedMessage
            id="80d78e79-ec8e-456d-ab8d-46eecc9498fd"
            defaultMessage="Already have an account? <link>Log in</link>"
            values={{
              link: (text) => (
                <Link white href={redirectUrl(AppSubdomains.customer, "/login")}>
                  {text}
                </Link>
              ),
            }}
          />
        </div>
      </div>

      <div className={Styles.body}>
        <div data-automation-id="account-container">{children}</div>
      </div>
    </div>
  );
}

function GraphicContainerContentHeader({ title }: { title: ReactNode }) {
  return (
    <>
      <Heading level="h1" textStyle="subtitle" textAlign="left" className={Styles.contentHeader}>
        {title}
      </Heading>
      <AccountBlurb />
    </>
  );
}

function GraphicContainerContentFooter({ isNotary = false }: { isNotary?: boolean }) {
  const isExtraSmall = useMatchScreenClass("xs");
  return (
    <>
      <Heading
        level="h3"
        textStyle="subtitleSmall"
        className={classnames(Styles.footerSection, !isExtraSmall && Styles.footerHeader)}
      >
        <FormattedMessage
          id="71ca4842-5203-42b2-a12c-0c340a015b1c"
          defaultMessage="Looking for something else?"
        />
      </Heading>
      <Paragraph className={Styles.footerSection}>
        {isNotary ? (
          <FormattedMessage
            id="828668a5-36ca-4b14-9c01-2f04e27b64fb"
            defaultMessage="Explore our <businessLink>business</businessLink> and <realLink>real estate</realLink> offerings, including plans for teams with in-house notaries."
            values={{
              businessLink: (text) => <Link href={BUSINESS_PRICING_URL}>{text}</Link>,
              realLink: (text) => <Link href={TITLE_PRICING_URL}>{text}</Link>,
            }}
          />
        ) : (
          <FormattedMessage
            id="828668a5-36ca-4b14-9c01-2f04e27b64fb"
            defaultMessage="Explore our <businessLink>business</businessLink>, <realLink>real estate</realLink> and <notaryLink>notary</notaryLink> offerings."
            values={{
              businessLink: (text) => (
                <Link href={BUSINESS_PRICING_URL} openInCurrentTab>
                  {text}
                </Link>
              ),
              realLink: (text) => (
                <Link href={TITLE_PRICING_URL} automationId="click-title" openInCurrentTab>
                  {text}
                </Link>
              ),
              notaryLink: (text) => (
                <Link href={NOTARIZE_NOTARY_URL} automationId="click-notary" openInCurrentTab>
                  {text}
                </Link>
              ),
            }}
          />
        )}
      </Paragraph>

      <Paragraph className={Styles.footerSection}>
        <FormattedMessage
          id="dcaf22b5-6981-4071-91b7-9c963a35d5f0"
          defaultMessage="<link>Notarize a document</link> in 15 minutes. Anywhere, anytime."
          values={{
            link: (txt) => (
              <Link href={redirectUrl(AppSubdomains.customer, "/signup/upload")}>{txt}</Link>
            ),
          }}
        />
      </Paragraph>
    </>
  );
}

export default GraphicContainer;

export { GraphicContainerContentHeader, GraphicContainerContentFooter };
