import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import CollapsibleList from "common/core/collapsible_list";
import CollapsibleListItem from "common/core/collapsible_list/item";
import { Card, CardHeading } from "common/core/card";
import { Substyle } from "common/core/typography";

import WitnessInfo from "./witness_info";
import type {
  MeetingForWitnessDetail as Meeting,
  MeetingForWitnessDetail_meetingParticipants as MeetingParticipant,
  MeetingForWitnessDetail_meetingParticipants_WitnessParticipant as WitnessParticipant,
} from "./witness_detail_fragment.graphql";

type Props = {
  meeting: Meeting;
};

function isWitness(participant: MeetingParticipant): participant is WitnessParticipant {
  return participant.__typename === "WitnessParticipant";
}

function CollapsibleListWrapper({
  children,
  isTxnDetailsRedesign,
}: {
  children: ReactNode;
  isTxnDetailsRedesign: boolean;
}) {
  return isTxnDetailsRedesign ? (
    <Card
      fullWidth
      noMargin
      header={
        <CardHeading>
          <FormattedMessage id="d709ad85-7800-4877-aa0a-95114d1a23f0" defaultMessage="Witness" />
        </CardHeading>
      }
    >
      {children}
    </Card>
  ) : (
    <div className="NotarizationDetails-witness">
      <CollapsibleList>{children}</CollapsibleList>
    </div>
  );
}

function WitnessDetail({ meeting }: Props) {
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);
  const witnesses = meeting.meetingParticipants.filter(isWitness);
  const onlyOneWitness = witnesses.length === 1;

  if (witnesses.length === 0) {
    return null;
  }

  return (
    <CollapsibleListWrapper isTxnDetailsRedesign={isTxnDetailsRedesign}>
      {witnesses.map((witness, index) =>
        isTxnDetailsRedesign ? (
          <div key={witness.id}>
            {witnesses.length > 1 && (
              <Substyle textStyle="allCapsLabelSmall">
                <FormattedMessage
                  id="ba6541aa-8075-4c32-8d98-e11740321455"
                  defaultMessage="Witness {witnessNumber, number}"
                  values={{ witnessNumber: index + 1 }}
                />
              </Substyle>
            )}
            <WitnessInfo witness={witness} />
          </div>
        ) : (
          <CollapsibleListItem
            key={witness.id}
            header={witness.fullName}
            subheader={
              <FormattedMessage
                id="ba6541aa-8075-4c32-8d98-e11740321455"
                defaultMessage="Witness {witnessNumber, number}"
                values={{ witnessNumber: index + 1 }}
              />
            }
            hideToggle={onlyOneWitness}
          >
            <WitnessInfo witness={witness} />
          </CollapsibleListItem>
        ),
      )}
    </CollapsibleListWrapper>
  );
}

export default WitnessDetail;
