import { useEffect, type ComponentProps, Fragment } from "react";
import { defineMessages, useIntl } from "react-intl";
import classnames from "classnames";

import { durationString } from "util/meeting";
import { dateComparator } from "util/date";
import { ModalScrollContent } from "common/core/modal_scroll_content";
import CollapsibleList from "common/core/collapsible_list";
import CollapsibleListItem from "common/core/collapsible_list/item";
import EmptyMeeting from "assets/images/no-meeting-state.svg";
import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import PriceBreakdownWithRefund from "common/transactions/price_breakdown/with_refund";

import { DeprecatedNotaryDetail } from "./deprecated_notary_detail";
import type { DocumentBundleForTransactionDetailsFullNotaryDetail } from "../index_fragment.graphql";
import Styles from "./deprecated_index.module.scss";

type Props = {
  refetch: () => Promise<unknown>;
  bundle: DocumentBundleForTransactionDetailsFullNotaryDetail;
  showResponsiveView?: boolean;
};

const MESSAGES = defineMessages({
  meetingHeader: {
    id: "f36b7bd1-fe28-4fd7-80ff-3cf9f758ac22",
    defaultMessage: "Meeting {index, number} - {meetingId}",
  },
  emptyContent: {
    id: "1945149f-7d87-4335-b4ad-52c6530f8426",
    defaultMessage:
      "This transaction has no meetings or is still processing, please come back later",
  },
});

function renderPriceBreakdown(refetch: () => Promise<unknown>) {
  return (charge: ComponentProps<typeof PriceBreakdownWithRefund>["charge"]) => {
    return <PriceBreakdownWithRefund charge={charge} refetch={refetch} />;
  };
}

/** @deprecated */
/** Once we enable the transactionDetailsRedesign flags, this component is only
 * used in the signer portal's transaction details modal
 * */
export function DeprecatedNotaryDetailsContainer({ bundle, refetch, showResponsiveView }: Props) {
  const meetingEdges = bundle.meetings.edges;
  const meetingsLength = meetingEdges.length;

  const intl = useIntl();
  useA11y().useDocumentEntitler({
    priority: "modal",
    title: intl.formatMessage(useDocumentTitles().documentDetailsNotary),
  });

  useEffect(() => {
    // This allows user to click on the tab again to refresh the content since sometimes this fragment isn't loaded
    // after meeting is completed
    refetch();
  }, []);

  const Wrapper = showResponsiveView ? Fragment : ModalScrollContent;
  const wrapperCx = showResponsiveView
    ? classnames(
        "NotarizationDetails-notarization NotarizationDetails-responsive",
        Styles.responsiveDetailWrapper,
      )
    : Styles.wrapper;

  switch (meetingsLength) {
    case 0:
      return (
        <Wrapper>
          <div className={Styles.emptyContainer}>
            <div className={Styles.emptyContent}>
              <img className={Styles.emptyImage} alt="" aria-hidden="true" src={EmptyMeeting} />
              <div className={Styles.emptyText}>{intl.formatMessage(MESSAGES.emptyContent)}</div>
            </div>
          </div>
        </Wrapper>
      );
    case 1:
      // don't confuse users with no multiple meetings by even suggesting multiple meetings are possible
      return (
        <Wrapper>
          <div className={wrapperCx}>
            <DeprecatedNotaryDetail
              renderPriceBreakdown={renderPriceBreakdown(refetch)}
              onReclaim={refetch}
              meeting={meetingEdges[0].node}
              useWrapperDiv={!showResponsiveView}
              showResponsiveView={showResponsiveView}
            />
          </div>
        </Wrapper>
      );
    default:
      return (
        <Wrapper>
          <div className={wrapperCx}>
            <CollapsibleList isResponsive={showResponsiveView}>
              {meetingEdges
                .slice()
                .sort((a, b) =>
                  dateComparator(b.node.timeFrame!.startedAt, a.node.timeFrame!.startedAt),
                )
                .map(({ node: meeting }, index) => (
                  <CollapsibleListItem
                    key={meeting.id}
                    isResponsive={showResponsiveView}
                    header={intl.formatMessage(MESSAGES.meetingHeader, {
                      index: meetingsLength - index,
                      meetingId: meeting.id,
                    })}
                    {...(!showResponsiveView && { subheader: durationString(meeting) })}
                  >
                    <DeprecatedNotaryDetail
                      renderPriceBreakdown={renderPriceBreakdown(refetch)}
                      onReclaim={refetch}
                      meeting={meeting}
                      showResponsiveView={showResponsiveView}
                    />
                  </CollapsibleListItem>
                ))}
            </CollapsibleList>
          </div>
        </Wrapper>
      );
  }
}
