import "./listing.scss";

import classnames from "classnames";
import { FormattedMessage } from "react-intl";

import { format } from "common/core/format/date";
import type { NotaryMeeting_meeting_Meeting_documentBundle_instructions as Instructions } from "common/meeting/notary/meeting_query.graphql";
import Checkbox from "common/form/inputs/checkbox";
import { Badge } from "common/core/badge";
import { IconButton } from "common/core/button/icon_button";

type InstructionProps = {
  isChecked: boolean;
  toggleInstructionConfirmation: (id: string) => void;
  instruction: Instructions;
  userId: string;
};

function isInstructionRecentlyUpdated(instruction: Instructions, userId: string) {
  const instructionStorageKey = `${instruction.templateUpdatedAt}-${userId}-${instruction.organizationInstructionTemplateId}`;
  try {
    const notaryHasSeenInstructionAfterUpdate =
      localStorage.getItem(instructionStorageKey) === "true";
    const noteWasRecentlyUpdated = instruction.recentlyUpdated;
    return noteWasRecentlyUpdated && !notaryHasSeenInstructionAfterUpdate;
  } catch {
    return false;
  }
}

function handleAcknowledgement(
  instructionStorageKey: string,
  id: string,
  toggleInstructionConfirmation: (id: string) => void,
) {
  toggleInstructionConfirmation(id);
  localStorage.setItem(instructionStorageKey, "true");
}

function Instruction({
  isChecked,
  toggleInstructionConfirmation,
  instruction,
  userId,
}: InstructionProps) {
  const { id, creator, text, templateUpdatedAt, organizationInstructionTemplateId } = instruction;
  const instructionStorageKey = `${templateUpdatedAt}-${userId}-${organizationInstructionTemplateId}`;
  const updated = isInstructionRecentlyUpdated(instruction, userId);

  const cx = classnames("Meeting--InstructionsListing--Instruction", {
    "Meeting--InstructionsListing--Instruction__read": isChecked,
    "Meeting--InstructionsListing--Instruction__updated": updated,
  });

  return (
    <div className={cx}>
      <header>{creator}</header>
      <div className="Meeting--InstructionsListing--Instruction--TextWithCheckbox">
        <div className="Meeting--InstructionsListing--Instruction--Checkbox">
          <Checkbox
            automationId="mark-as-read-checkbox"
            checked={isChecked}
            onChange={() =>
              handleAcknowledgement(instructionStorageKey, id, toggleInstructionConfirmation)
            }
          />
        </div>
        <div
          className="Meeting--InstructionsListing--Instruction--Text"
          data-automation-id="note-text"
        >
          {text}
        </div>
      </div>
      {updated ? (
        <div className="Meeting--InstructionsListing--Instruction--UpdatedText">
          <FormattedMessage
            id="e90c0227-7f8a-4050-b897-5c5da38fb9f9"
            defaultMessage={`Updated {templateUpdatedAt}`}
            values={{
              templateUpdatedAt: format({ value: templateUpdatedAt, formatStyle: "MMM d, yyyy" }),
            }}
          ></FormattedMessage>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

type InstructionListingProps = {
  instructions: Instructions[];
  confirmedInstructions: Readonly<Record<string, boolean | undefined>>;
  toggleInstructionConfirmation: (id: string) => void;
  toggleInstructionsOpen: () => void;
  userId: string;
};

function InstructionsListing({
  instructions,
  confirmedInstructions,
  toggleInstructionConfirmation,
  toggleInstructionsOpen,
  userId,
}: InstructionListingProps) {
  const updated = instructions.find((instruction) =>
    isInstructionRecentlyUpdated(instruction, userId),
  );
  return (
    <div className="Meeting--InstructionsListing">
      <header data-automation-id="notes-header">
        <div className="Meeting--InstructionsListing--NotesHeaderContainer">
          <FormattedMessage id="9139d033-f9e0-491b-8b1d-92a9db9f1a5d" defaultMessage="Notes">
            {(txt) => <h3 className="Meeting--InstructionsListing--NotesHeader">{txt}</h3>}
          </FormattedMessage>
          {updated && (
            <>
              <Badge kind="new" className="Meeting--InstructionsListing--Badge">
                <FormattedMessage
                  id="d7376078-f223-4a60-a46f-7f5d2e3560ec"
                  defaultMessage="Updated"
                ></FormattedMessage>
              </Badge>
            </>
          )}
        </div>
        <IconButton
          name="x"
          label="Close"
          className="Meeting--InstructionsListing--Close"
          buttonColor="dark"
          buttonSize="condensed"
          variant="tertiary"
          onClick={toggleInstructionsOpen}
        ></IconButton>
      </header>
      <div className="Meeting--InstructionsListing--Instructions">
        {instructions.map((instruction) => (
          <Instruction
            key={instruction.id}
            userId={userId}
            isChecked={Boolean(confirmedInstructions[instruction.id])}
            instruction={instruction}
            toggleInstructionConfirmation={toggleInstructionConfirmation}
          />
        ))}
      </div>
    </div>
  );
}

export default InstructionsListing;
