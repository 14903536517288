import type { ReactNode } from "react";

import { useMatchScreenClass } from "common/core/responsive";

import Styles from "./index.module.scss";

type Props = {
  body: ReactNode;
  footer?: ReactNode;
  header?: ReactNode;
};

const ProofCard = ({ body, footer, header }: Props) => {
  const isExtraSmall = useMatchScreenClass("xs");

  const content = (
    <>
      {header && <div className={Styles.header}>{header}</div>}
      <div className={Styles.body}>{body}</div>
      {footer && <div className={Styles.footer}>{footer}</div>}
    </>
  );

  return isExtraSmall ? content : <div className={Styles.card}>{content}</div>;
};

export default ProofCard;
