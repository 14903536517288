import { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { durationString } from "util/meeting";
import { dateComparator } from "util/date";
import modalScrollContent from "common/core/modal_scroll_content";
import CollapsibleList from "common/core/collapsible_list";
import CollapsibleListItem from "common/core/collapsible_list/item";
import EmptyMeeting from "assets/images/no-meeting-state.svg";

import WitnessDetail from "./witness_detail";
import type {
  DocumentBundleForTransactionDetailsWitnessDetails as DocumentBundle,
  DocumentBundleForTransactionDetailsWitnessDetails_meetings_edges as MeetingEdge,
} from "./index_fragment.graphql";

type Props = {
  bundle: DocumentBundle;
  refetch: () => Promise<unknown>;
};

const NO_MEETINGS = (
  <div className="NotarizationDetails--emptyContainer">
    <div className="NotarizationDetails--emptyContent">
      <img
        className="NotarizationDetails--emptyContent--image"
        alt=""
        aria-hidden="true"
        src={EmptyMeeting}
      />
      <div className="NotarizationDetails--emptyContent--text">
        <FormattedMessage
          id="3bb61002-4fd8-4f24-ac89-a59aea55088b"
          defaultMessage="This transaction has no meetings or is still processing, please come back later"
        />
      </div>
    </div>
  </div>
);

function byStartedAtTime(a: MeetingEdge, b: MeetingEdge): number {
  return dateComparator(b.node.timeFrame!.startedAt, a.node.timeFrame!.startedAt);
}

/** @deprecated */
/** Once we enable the transactionDetailsRedesign flags, this component is only
 * used in the signer portal's transaction details modal
 * */
function DeprecatedWitnessDetails({ bundle, refetch }: Props) {
  const meetingEdges = bundle.meetings.edges;
  const numberOfMeetings = meetingEdges.length;

  useEffect(() => {
    // This allows user to click on the tab again to refresh the content since sometimes this fragment isn't loaded
    // after meeting is completed
    refetch();
  }, []);

  switch (numberOfMeetings) {
    case 0:
      return NO_MEETINGS;
    case 1:
      // Don't confuse users with no multiple meetings by even suggesting multiple meetings are possible
      return <WitnessDetail meeting={meetingEdges[0].node} />;
    default:
      return (
        <div className="NotarizationDetails-witness">
          <CollapsibleList>
            {meetingEdges
              .slice()
              .sort(byStartedAtTime)
              .map(({ node: meeting }, index) => (
                <CollapsibleListItem
                  key={meeting.id}
                  header={
                    <FormattedMessage
                      id="d214939f-9e0e-423d-8b80-93546b460585"
                      defaultMessage="Meeting {index, number}"
                      values={{ index: numberOfMeetings - index }}
                    />
                  }
                  subheader={durationString(meeting)}
                >
                  <WitnessDetail meeting={meeting} />
                </CollapsibleListItem>
              ))}
          </CollapsibleList>
        </div>
      );
  }
}

/** @deprecated */
export const DeprecatedWitness = modalScrollContent(DeprecatedWitnessDetails);
