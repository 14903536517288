import type { ComponentProps } from "react";
import { useIntl } from "react-intl";

import { useA11y } from "common/accessibility";
import { useDocumentTitles } from "util/document_title";
import PriceBreakdownWithRefund from "common/transactions/price_breakdown/with_refund";

import NotaryDetail from "./notary_detail";
import type { DocumentBundleForTransactionDetailsFullNotaryDetail_meetings_edges_node as Meeting } from "./index_fragment.graphql";

type Props = {
  refetch: () => Promise<unknown>;
  meeting: Meeting;
};

function renderPriceBreakdown(refetch: () => Promise<unknown>) {
  return (charge: ComponentProps<typeof PriceBreakdownWithRefund>["charge"]) => {
    return <PriceBreakdownWithRefund charge={charge} refetch={refetch} />;
  };
}

export function NotaryDetailsContainer({ refetch, meeting }: Props) {
  const intl = useIntl();
  useA11y().useDocumentEntitler({
    priority: "modal",
    title: intl.formatMessage(useDocumentTitles().documentDetailsNotary),
  });

  return (
    <NotaryDetail
      renderPriceBreakdown={renderPriceBreakdown(refetch)}
      onReclaim={refetch}
      meeting={meeting}
      notary={meeting.notary}
    />
  );
}
