import "./index.scss";

import type { ComponentProps } from "react";

import { DeprecatedDetailGridSection } from "common/details/grid/section";
import NotaryMeetingTime from "common/details/meeting/notary_details/items/notary_meeting_time";
import SigningScheduleType from "common/details/meeting/notary_details/items/signing_schedule_type";

import TransactionExpiryForm from "./transaction_expiry_form";
import TransactionActivationForm from "./transaction_activation_form";
import type { TransactionForTransactionDetailsAdminDateTime } from "./index_fragment.graphql";

type Props = {
  transaction: TransactionForTransactionDetailsAdminDateTime;
  onSubmitActivationForm: ComponentProps<typeof TransactionActivationForm>["onSubmitForm"];
  onSubmitExpiryForm: ComponentProps<typeof TransactionExpiryForm>["onSubmitForm"];
};

function AdminTransactionDetailsDateTimes(props: Props) {
  const { transaction } = props;
  return (
    <div className="AdminDateTimesDetails">
      <DeprecatedDetailGridSection>
        <SigningScheduleType transaction={transaction} />
        {transaction.isMortgage && (
          <TransactionActivationForm
            transaction={transaction}
            onSubmitForm={props.onSubmitActivationForm}
          />
        )}
        <TransactionExpiryForm transaction={transaction} onSubmitForm={props.onSubmitExpiryForm} />
        <NotaryMeetingTime transaction={transaction} />
      </DeprecatedDetailGridSection>
    </div>
  );
}

export default AdminTransactionDetailsDateTimes;
